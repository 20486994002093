import axios from 'axios';
import { fetchDecodedData } from '../../../tools/Auth';

const diploPeople = [
    {
        "firstName": "Anja",
        "lastName": "Đajić",
        "email": "anjadj@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/Anja-Djajic-250x250-1.jpg"
    },
    {
        "firstName": "Jovan",
        "lastName": "Njegić",
        "email": "jovannj@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/jnj.jpg"
    },
    {
        "firstName": "Nikola",
        "lastName": "Darinkovic",
        "email": "nikolad@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2021/11/IMG_20210223_153327_917.jpg"
    },
    {
        "firstName": "Milos",
        "lastName": "Vujic",
        "email": "milosv@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/Milos-Vujic-250x250-1.jpg"
    },
    {
        "firstName": "Misa",
        "lastName": "Stankovic",
        "email": "misas@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/07/Misa-Stankovic.jpeg"
    },
    {
        "firstName": "Mina",
        "lastName": "Mudric",
        "email": "minam@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/MBP_7571.jpg"
    },
    {
        "firstName": "Milica",
        "lastName": "Virijević Konstantinović",
        "email": "milicak@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/milica.jpg"
    },
    {
        "firstName": "Katarina",
        "lastName": "Bojović",
        "email": "katarinaa@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/katarina-andjelkovic.jpg"
    },
    {
        "firstName": "Jovan",
        "lastName": "Kurbalija",
        "email": "jovank@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2021/06/Jovan-good.jpg"
    },
    {
        "firstName": "Dragana",
        "lastName": "Markovski",
        "email": "draganam@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/dragana_0.jpg"
    },
    {
        "firstName": "Dylan",
        "lastName": "Farrell",
        "email": "dylanf@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/farrell.jpg"
    },
    {
        "firstName": "Bojana",
        "lastName": "Kovač",
        "email": "bojanak@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2023/03/Bojana-Kovac-Diplo.jpg"
    },
    {
        "firstName": "Boris",
        "lastName": "Begović",
        "email": "borisb@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2021/10/Boris-Begovic.jpg"
    },
    {
        "firstName": "Andrej",
        "lastName": "Škrinjarić",
        "email": "andrejs@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/02/diplo-profile-3.jpg"
    },
    {
        "firstName": "Aleksandar",
        "lastName": "Stanković",
        "email": "aleksadnars@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2021/10/aleksandar_stankovic.jpg"
    },
    {
        "firstName": "Andrijana",
        "lastName": "Gavrilović",
        "email": "andrijanag@diplomacy.edu",
        "image": "https://www.diplomacy.edu/wp-content/uploads/2022/08/Andrijana20picture.jpg"
    }
].sort((a, b) => {
    // return a.firstName - b.firstName;
    return a.firstName.localeCompare(b.firstName)
});

class DiploWebsitesService {

    getAvailableWebsites() {
        return ["diplomacy.edu", "dig.watch"];
    }

    getPostTypes() {
        return [
            {
                "value": "update",
                "display": "Update"
            },
            {
                "value": "resource",
                "display": "Resource"
            },
            {
                "value": "event",
                "display": "Event"
            },
        ]
    }

    getAvailablePostStatuses() {
        return [
            // {
            //     "value": "publish",
            //     "display": "Publish"
            // },
            {
                "value": "draft",
                "display": "Draft"
            },
            {
                "value": "pending",
                "display": "Pending"
            },
        ]
    }

    getTopics(website) {
        switch (website) {
            case "dig.watch":
                return axios.post(process.env.REACT_APP_NIMANI_GET_DIG_WATCH_TOPICS);
            case "diplomacy.edu":
                return axios.post(process.env.REACT_APP_NIMANI_GET_DIPLOMACY_EDU_TOPICS);
            default:
                return false;
        }
    }

    sendPostToWebsite(website, postType, data) {

        let url = null;

        switch (postType) {
            case "update":
                if (website == "dig.watch") {
                    url = process.env.REACT_APP_DIG_WATCH_CREATE_UPDATE;
                }

                if (website == "diplomacy.edu") {
                    url = process.env.REACT_APP_DIPLOMACY_EDU_CREATE_UPDATE;
                }
                break;

            case "resource":
                if (website == "dig.watch") {
                    url = process.env.REACT_APP_DIG_WATCH_CREATE_RESOURCE;
                }

                if (website == "diplomacy.edu") {
                    url = process.env.REACT_APP_DIPLOMACY_EDU_CREATE_RESOURCE;
                }
                break;

            case "event":
                if (website == "dig.watch") {
                    url = process.env.REACT_APP_DIG_WATCH_CREATE_EVENT;
                }

                if (website == "diplomacy.edu") {
                    url = process.env.REACT_APP_DIPLOMACY_EDU_CREATE_EVENT;
                }
                break;
        }

        return axios.post(url,
            {
                "author": fetchDecodedData().email,
                "title": data.title,
                "content": data.summary,
                "excerpt": data.excerpt,
                "topics": data.topics,
                "post-status": data.postStatus,
                "featured-image": data.featuredImage,
                "post-relations": data.postRelations,
            }
        );

    }

    getDiploPeople() {
        return diploPeople
    }

    getDiploUser(list,email) {
        return list.find(el => el.email === email);
    }

    getAvailableCustomPostTypes(website, postType) {
        switch (website) {
            case "dig.watch":
                switch (postType) {
                    case "update":
                        return ['actor', 'countries', 'trends', 'values', 'processes', 'technologies'];
                    case "event":
                        return ['actor', 'trends', 'values', 'processes', 'technologies'];
                    case "resource":
                        return ['actor', 'trends', 'values', 'processes', 'technologies'];
                    default:
                        return false;
                }
            case "diplomacy.edu":
                switch (postType) {
                    case "update":
                        return ['actor', 'people', 'country-territory'];
                    case "event":
                        return ['actor', 'people', 'country-territory'];
                    case "resource":
                        return ['actor', 'people', 'country-territory'];
                    default:
                        return false;
                }
            default:
                return false;
        }
    }

    getAvailableCustomPostTypeData(website, postType) {
        let url = null;

        switch (website) {
            case "dig.watch":
                url = process.env.REACT_APP_NIMANI_GET_DIG_WATCH_CUSTOM_POST_TYPES;
                break;
            case "diplomacy.edu":
                url = process.env.REACT_APP_NIMANI_GET_DIPLOMACY_CUSTOM_POST_TYPES;
                break;
            default:
                return false;
        }

        return axios.post(url,
            {
                "post_type": postType
            }
        );
    }

    saveOutputs(sendToWebsiteData, wordpressData, originalAIGeneratedData) {
        return axios.post(process.env.REACT_APP_NIMANI_SAVE_OUTPUTS,
            {
                "user_id": fetchDecodedData().email,
                "outputs": {
                    "wordpress": wordpressData,
                    "ai-generated": originalAIGeneratedData,
                    "human-input": sendToWebsiteData
                }
            },
        );
    }
    
    getCustomPostTypeRecommend(website, text) {
        let url = null;

        switch (website) {
            case "dig.watch":
                url = process.env.REACT_APP_NIMANI_GET_DIG_WATCH_CUSTOM_POST_TYPES_RECOMMEND;
                break;
            case "diplomacy.edu":
                url = process.env.REACT_APP_NIMANI_GET_DIPLOMACY_CUSTOM_POST_TYPES_RECOMMEND;
                break;
            default:
                return false;
        }

        return axios.post(url,
            {
                "user_id": fetchDecodedData().email,
                "text":  text
            }
        );
    }
}

export default new DiploWebsitesService;