import React, { Component } from 'react';
import NewsService from '../services/NewsService';
import News from './News';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import { Button, CardActionArea, CardActions, InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import LayersIcon from '@mui/icons-material/Layers';
import DiploWebsitesService from '../services/DiploWebsitesService';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import '../assets/css/briefing-page.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import NewsPreviewModal from './NewsPreviewModal';

class NewsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newsList: null,
            selectedNews: [],
            page: 1,
            totalPages: 0,
            setSelectedNews: props.setSelectedNews,
            diploPeople: null,
            searchField: React.createRef(),
            daysFilter: '',
            newsPreviewModalOpen: false,
            openedNewsData: null,
        };
    }

    componentDidMount() {
        this.getNews(1, this.state.searchField.current.value, this.state.daysFilter);

        //get USERS from sql for the assign_to list
        axios.get(process.env.REACT_APP_BACKEND_URL + '/users').then(data => {

            this.setState({
                diploPeople: data.data
            })

        })

        // this.setState({
        //     diploPeople: DiploWebsitesService.getDiploPeople()
        // })
    }

    getNews = (page, search, daysFilter) => {

        this.setState({
            newsList: null,
        });

        NewsService.getNews(page, search, daysFilter)
            .then((res) => {
                console.log(res.data.items);
                this.setState({
                    newsList: res.data.items,
                    page: page,
                    totalPages: res.data.pages
                });
            })
            .catch((error) => {
                console.error(error);
                alert("Error getting articles.");
            });
    }

    addNewsToSelected = (news) => {

        let remove = false;

        for (let i = 0; i < this.state.selectedNews.length; i++) {
            if (this.state.selectedNews[i].link == news.link) {
                this.state.selectedNews.splice(i, 1);
                remove = true;
            }
        }

        if (!remove) {
            this.state.selectedNews.push(news);
        }


        this.state.setSelectedNews(this.state.selectedNews);
    }

    // startSummarization = () => {

    //     if (this.state.selectedNews.length == 0) {
    //         alert("Please select some news");
    //         return;
    //     }

    //     let queryParams = "";

    //     for (let i = 0; i < this.state.selectedNews.length; i++) {
    //         queryParams += (i == 0 ? '?' : '&') + `article${i + 1}=${this.state.selectedNews[i].link}`;
    //     }

    //     window.open("/ai-writer/summarizer" + queryParams);

    // }

    handlePageChange = (event, page) => {
        this.getNews(page, this.state.searchField.current.value, this.state.daysFilter);
    }

    handleSearch = () => {
        this.getNews(1, this.state.searchField.current.value, this.state.daysFilter);
        this.setState({
            page: 1
        })
    }

    clearSearch = () => {
        this.state.searchField.current.value = "";
    }

    searchOnKeyDown = (event) => {
        if (event.keyCode == 13) {
            this.handleSearch();
        }
    }

    handleDaysFilterChange = (event) => {
        this.setState({
            daysFilter: event.target.value,
        })
        this.getNews(1, this.state.searchField.current.value, event.target.value);
        this.setState({
            page: 1
        })
    }

    openNewsPreviewModal = (openNewsLink) => {

        NewsService.getNewsSingleNoEmail(openNewsLink)
            .then((res) => {
                this.setState({
                    newsPreviewModalOpen: true,
                    openedNewsData: res.data
                })
            })
            .catch((error) => {
                console.error(error);
                alert("Error getting news content.");
            });
    }

    closeNewsPreviewModal = () => {
        this.setState({
            newsPreviewModalOpen: false,
            openedNewsData: null
        })
    }

    render() {

        return (
            <>

                <div className="ai-recommended-news-search-bar">
                    <div className="ai-news-search">
                        {/* <div
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                        > */}
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search news"
                            inputProps={{ 'aria-label': 'search news' }}
                            inputRef={this.state.searchField}
                            onKeyDown={this.searchOnKeyDown}
                        />
                        <IconButton
                            sx={{ p: '10px' }}
                            aria-label="directions"
                            onClick={this.clearSearch}
                        >
                            <ClearIcon />
                        </IconButton>
                        <Divider sx={{ height: 34, m: 0.5 }} orientation="vertical" />
                        <IconButton
                            type="button"
                            sx={{ p: '10px' }}
                            aria-label="search"
                            onClick={this.handleSearch}
                            style={{ color: '#00879a' }}
                        >
                            <SearchIcon />
                        </IconButton>
                        {/* </div> */}
                        {/* <Paper
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search news"
                                inputProps={{ 'aria-label': 'search news' }}
                                inputRef={this.state.searchField}
                                onKeyDown={this.searchOnKeyDown}
                            />
                            <IconButton
                                sx={{ p: '10px' }}
                                aria-label="directions"
                                onClick={this.clearSearch}
                                >
                                <ClearIcon />
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton
                                type="button"
                                sx={{ p: '10px' }}
                                aria-label="search"
                                onClick={this.handleSearch}
                                style={{ color: '#00879a' }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Paper> */}
                    </div>
                    <div className="ai-days-filter">
                        <FormControl className="ai-days-filter-select" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="days-filter-select">Days Filter</InputLabel>
                            <Select
                                labelId="days-filter-select"
                                value={this.state.daysFilter}
                                label="Days Filter"
                                onChange={this.handleDaysFilterChange}
                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={1}>1 day ago</MenuItem>
                                <MenuItem value={2}>2 days ago</MenuItem>
                                <MenuItem value={3}>3 days ago</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="ai-recommended-news-container">
                    <div className="ai-recommended-news-header">
                        <h2>
                            Picked for you
                        </h2>
                        {/* <div className="buttons">
                            <Button
                                variant="contained"
                                endIcon={<LayersIcon />}
                                style={{ backgroundColor: "#00879a", marginRight: "20px" }}
                                onClick={() => window.open("/ai-writer/summarizer")}
                            >
                                Blank workspace
                            </Button>
                            <Button
                                variant="contained"
                                endIcon={<SummarizeRoundedIcon />}
                                style={{ backgroundColor: "#00879a" }}
                                onClick={this.startSummarization}
                            >
                                Summarizer
                            </Button>
                        </div> */}
                    </div>
                    <hr className='news-devider' />
                    <div className='ai-recommended-news-body'>
                        {
                            this.state.newsList == null
                                ? <>
                                    Loading data...
                                </>
                                : (this.state.diploPeople == null
                                    ? <>
                                        Loading data...
                                    </> :
                                    <>
                                        {
                                            this.state.newsList.map(
                                                element => <News
                                                    news={element}
                                                    addNewsToSelected={this.addNewsToSelected}
                                                    diploPeople={this.state.diploPeople}
                                                    openNewsPreviewModal={this.openNewsPreviewModal}
                                                />
                                            )
                                        }
                                    </>)
                        }
                    </div>
                </div>

                <NewsPreviewModal
                    openedNewsData={this.state.openedNewsData}
                    newsPreviewModalOpen={this.state.newsPreviewModalOpen}
                    closeNewsPreviewModal={this.closeNewsPreviewModal}
                />

                <Stack spacing={2}>
                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.handlePageChange} />
                </Stack>
            </>
        );
    }
}

NewsList.propTypes = {

};

export default NewsList;