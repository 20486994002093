import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

class SuggestionsSidebar extends Component {

    constructor(props) {
        super(props);
    }

    renderGroupName = (group) => {
        switch (group) {
            case "very hard":
                return "Very hard to read";
            case "hard":
                return "Hard to read";
            case "passive_voice":
                return "Passive voice";
            case "adverb":
                return "Adverb";
        }
    }

    renderSuggestions = (item) => {
        switch (item.type) {
            case "very hard":
                return <>
                    <p style={{ opacity: "0.6" }}>
                        {item.part}
                    </p>
                    {
                        <p style={{ fontWeight: "600" }}>
                            {item.recommendation}
                        </p>
                    }
                </>
            case "hard":
                return <>
                    <p style={{ opacity: "0.6" }}>
                        {item.part}
                    </p>
                    {
                        <p style={{ fontWeight: "600" }}>
                            {item.recommendation}
                        </p>
                    }
                </>
            case "passive_voice":
                return <>
                    <p>
                        {item.part}
                    </p>
                    {
                        <p style={{ fontWeight: "600" }}>
                            {item.description}
                        </p>
                    }
                </>
            case "adverb":
                return <>
                    <p>
                        {item.part}
                    </p>
                    {
                        <p style={{ fontWeight: "600" }}>
                            {item.description}
                        </p>
                    }
                </>
        }
    }

    render() {
        return (
            <>
                <div className="card-container">
                    <div className="sidebar-editor-header">
                        <h3>
                            All suggestions:
                        </h3>
                    </div>

                    <hr />

                    <div>
                        {/* 
                        <button>Accept all</button>
                    <button>Dismiss</button> 
                    */}
                    </div>

                    <div className="group-suggestions">
                        <div className="group-suggestion-item">
                            {/* <h3>
                                {
                                    this.props.hemingwayAnalysisData["data"].veryHardSentences == 1
                                        ? "There is " + this.props.hemingwayAnalysisData["data"].veryHardSentences + " setnece that is very hard to read"
                                        : "There are " + this.props.hemingwayAnalysisData["data"].veryHardSentences + " setneces that are very hard to read"
                                }
                            </h3> */}
                            <h3 onClick={() => this.props.setSelectedSuggestionGroup("very hard")} style={{ cursor: "pointer" }}>
                                Very hard to read:
                                <span style={{ opacity: 0.6, fontStyle: "italic", marginLeft: "10px" }}>
                                    {
                                        this.props.hemingwayAnalysisData["data"].veryHardSentences
                                    }
                                </span>
                                {/* {
                                    this.props.hemingwayAnalysisData["data"].veryHardSentences == 1
                                        ? "Very hard to read " + this.props.hemingwayAnalysisData["data"].veryHardSentences
                                        : "Very hard to read " + this.props.hemingwayAnalysisData["data"].veryHardSentences
                                } */}
                            </h3>

                        </div>

                        <div className="group-suggestion-item">
                            <h3 onClick={() => this.props.setSelectedSuggestionGroup("hard")} style={{ cursor: "pointer" }}>
                                Hard to read:
                                <span style={{ opacity: 0.6, fontStyle: "italic", marginLeft: "10px" }}>
                                    {
                                        this.props.hemingwayAnalysisData["data"].hardSentences
                                    }
                                </span>
                            </h3>

                        </div>


                        <div className="group-suggestion-item">
                            <h3 onClick={() => this.props.setSelectedSuggestionGroup("adverb")} style={{ cursor: "pointer" }}>
                                Adverbs:
                                <span style={{ opacity: 0.6, fontStyle: "italic", marginLeft: "10px" }}>
                                    {
                                        this.props.hemingwayAnalysisData["data"].adverbs
                                    }
                                </span>
                            </h3>

                        </div>

                        <div className="group-suggestion-item">
                            <h3 onClick={() => this.props.setSelectedSuggestionGroup("passive_voice")} style={{ cursor: "pointer" }}>
                                Passive Voice:
                                <span style={{ opacity: 0.6, fontStyle: "italic", marginLeft: "10px" }}>
                                    {
                                        this.props.hemingwayAnalysisData["data"].passiveVoice
                                    }
                                </span>
                            </h3>

                        </div>

                        <div className="group-suggestion-item">
                            <h3 onClick={() => this.props.setSelectedSuggestionGroup("complex")} style={{ cursor: "pointer" }}>
                                Complex words:
                                <span style={{ opacity: 0.6, fontStyle: "italic", marginLeft: "10px" }}>
                                    {
                                        this.props.hemingwayAnalysisData["data"].complex
                                    }
                                </span>
                            </h3>

                        </div>


                    </div>

                </div>

                {
                    this.props.inFocusElement != null &&
<>
                    <h3 className="suggestion-group-title">Selected:</h3>
                       
                    <div className="suggestion card-container">

                        {this.renderSuggestions(this.props.hemingwayAnalysisData["output_data"].find(item => item.id == this.props.inFocusElement))}

                        <div className="suggestion-buttons">
                            <IconButton style={{ color: "#00ff00" }}>
                                <CheckCircleIcon style={{ width: "35px", height: "35px" }} />
                            </IconButton>
                            <IconButton style={{ color: "#ff0000" }}>
                                <CancelIcon style={{ width: "35px", height: "35px" }} />
                            </IconButton>
                        </div>
                    </div>
                    </>
                }

                {
                    this.props.selectedSuggestionGroup != null &&
                    <>
                        <h3 className="suggestion-group-title">{this.renderGroupName(this.props.selectedSuggestionGroup)}:</h3>
                        {
                            this.props.hemingwayAnalysisData["output_data"].map((item, index) => {
                                if (item.type == this.props.selectedSuggestionGroup)
                                    return (
                                        <div className="suggestion card-container" key={item.id}>

                                            {this.renderSuggestions(item)}

                                            <div className="suggestion-buttons">
                                                <IconButton style={{ color: "#00ff00" }}>
                                                    <CheckCircleIcon style={{ width: "35px", height: "35px" }} />
                                                </IconButton>
                                                <IconButton style={{ color: "#ff0000" }}>
                                                    <CancelIcon style={{ width: "35px", height: "35px" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )
                            })
                        }
                    </>
                }
            </>
        );
    }
}

export default SuggestionsSidebar;