import React, { Component } from 'react';

class TextAnalysisSidebar extends Component {
    render() {
        return (
            <div className="card-container">

                <div className="sidebar-editor-header">
                    <h3>
                        Text analysis:
                    </h3>
                </div>

                <hr />

                <div className='score'>
                    <span><b>Readability:</b></span>
                    <span>Grade 5</span>
                </div>
                <div className='score'>
                    <span><b>Writing level:</b></span>
                    <span>Grade 5</span>
                </div>
                <div className='score'>
                    <span><b>Editing Level:</b></span>
                    <span>Grade 5</span>
                </div>

                <hr />

                <div className='score'>
                    <span><b>Paragraphs:</b></span> 
                    <span>{this.props.hemingwayAnalysisData.data.paragraphs}</span>
                </div>
                <div className='score'>
                    <span><b>Sentences:</b></span> 
                    <span>{this.props.hemingwayAnalysisData.data.sentences}</span>
                </div>
                <div className='score'>
                    <span><b>Words:</b></span> 
                    <span>{this.props.hemingwayAnalysisData.data.words}</span>
                </div>

                <hr />

                <div className='advice'>
                    <span className="advice-num very-hard-to-read">{this.props.hemingwayAnalysisData.data.veryHardSentences}</span>
                    <span>{this.props.hemingwayAnalysisData.data.veryHardSentences} of {this.props.hemingwayAnalysisData.data.sentences} sentences are very hard to read</span>
                </div>

                <hr />

                <div className='advice'>
                    <span className="advice-num hard-to-read">{this.props.hemingwayAnalysisData.data.hardSentences}</span>
                    <span>{this.props.hemingwayAnalysisData.data.hardSentences} of {this.props.hemingwayAnalysisData.data.sentences} sentences are hard to read</span>
                </div>

                <hr />

                <div className='advice'>
                    <span className="advice-num adverb">{this.props.hemingwayAnalysisData.data.adverbs}</span>
                    <span>{this.props.hemingwayAnalysisData.data.adverbs} adverbs, meeting the goal of 3 or fewer.</span>
                </div>

                <hr />

                <div className='advice'>
                    <span className="advice-num passive">{this.props.hemingwayAnalysisData.data.passiveVoice}</span>
                    <span>{this.props.hemingwayAnalysisData.data.passiveVoice} uses of passive voice, meeting the goal of 2 or fewer.</span>
                </div>

                <hr />

                <div className='advice'>
                    <span className="advice-num complex">{this.props.hemingwayAnalysisData.data.complex}</span>
                    <span>{this.props.hemingwayAnalysisData.data.complex} phrases have simpler alternatives.</span>
                </div>


            </div>
        );
    }
}

export default TextAnalysisSidebar;