import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import DiploWebsitesService from '../../../services/DiploWebsitesService';
import { Alert, AlertTitle, CircularProgress, Collapse } from '@mui/material';

const title = "The Changing Requirements of Telecom Networks";
const content = "The Changing Requirements of Telecom Networks is a discussion around the increasing demands placed on networks from subscribers. As more people join telecom networks, the requirements of these networks have had to change in order to meet the demands of subscribers. Rising numbers of subscribers and the increasing complexity of services have led to the need for networks to scale to provide greater capacity and reach. Compute power must also be increased in the edge to perform more intensive processing close to users as well as be able to handle new workloads such as machine learning. Lastly, latency must be minimized in order to provide better user experiences. All of these factors must be taken into account in order to create efficient and effective telecom networks.";
const excerpt = "The UK National Cyber Security Centre (NCSC) warned telecom network operators about the risks posed by the use of compromised routers and network equipment. The warning came in the context of the global campaign conducted by the UK and the US to disrupt the activities of the so-called ‘Legion’ group. The group is believed to have used compromised routers and switches to carry out their attacks.";

const selectedPostStatus = "draft";
const selectedPostType = "resource";
const selectedWebsite = "dig.watch";

const tags = ["tag1", "tag2", "tag3"]
const seo = ["seo1", "seo2", "seo3"]

function PostPreview(props) {

    const [sendingInProgress, setSendingInProgress] = React.useState(false);
    const [postSent, setPostSent] = React.useState(false);

    const [newPostId, setNewPostId] = React.useState("");
    const [newPostLink, setNewPostLink] = React.useState("");

    const sendToWebsite = () => {

        setSendingInProgress(true);

        let website = props.websiteData.selectedWebsite;
        let postType = props.websiteData.selectedPostType;

        let data = {
            "title": props.getGeneratedTitle(),
            "summary": props.getGeneratedSummary(),
            "excerpt": props.getGeneratedExcerpt(),
            "topics": props.postData["topics"],
            "postStatus": props.websiteData.selectedPostStatus,
            "featuredImage": props.imageData.featuredImage,
            "postRelations": props.postData,
        }

        DiploWebsitesService.sendPostToWebsite(website, postType, data)
            .then((res) => {

                console.log(data);
                console.log(res.data);
                console.log(props.originalAIGeneratedData);
                
                setPostSent(true);
                setSendingInProgress(false);

                setNewPostId(res.data.new_post_id);
                setNewPostLink(res.data.url);

                window.open(res.data.url, '_blank');

                // Save data to nimani for statistics
                DiploWebsitesService.saveOutputs(data, res.data, props.originalAIGeneratedData)
                .then((res1) => {
                    console.log(res1)
                })
                .catch((error) => {
                    console.error(error);
                    alert("Post succesfuly sent to website, but error occurred saving data to nimani!");
                });
            })
            .catch((error) => {
                setSendingInProgress(false);
                console.error(error);
                alert("Error sending to website!");
            });
    }

    const viewPost = () => {
        window.open(newPostLink, "_blank");
    }

    const renderCustomPostData = (customPostType) => {

        if (props.postData[customPostType].length == 0) return;

        return <CustomPostDataPriview
            title={customPostType}
            data={props.postData[customPostType]}
        />
    }

    return (
        <>
            <Collapse in={postSent} style={{ marginTop: "20px" }}>
                <Alert
                    action={
                        <Button color="inherit" size="small" onClick={viewPost}>
                            View
                        </Button>
                    }
                >
                    Post successfully sent to website!
                </Alert>
            </Collapse>

            <Alert severity="info" style={{ marginTop: "20px" }}>
                <AlertTitle>Application is not sending <strong>SEO Keywords</strong> to the website.</AlertTitle>
                If you want to add them please add them manualy.
            </Alert>

            <Box sx={{ display: 'flex' }} className="sending-to-website-progress" style={sendingInProgress ? {} : { display: "none" }}>
                <CircularProgress size={80} />
            </Box>

            <div className="send-to-website-container post-preview">
                <div className="send-to-website-header">
                    <h2>
                        Preview:
                    </h2>
                </div>
                <hr className="send-to-website-subheading" />
                <div className="send-to-website-body">

                    <div className="post-preview-excerpt">

                        <div className="post-preview-image">
                            {
                                props.imageData.featuredImage != null
                                    ? <img src={props.imageData.featuredImage.url} alt={props.imageData.featuredImage.altText} />
                                    : <img src={require('../../../assets/img/update-image.webp')} />
                            }
                        </div>

                        <div className="post-preview-excerpt-content">
                            <h2>
                                {
                                    props.getGeneratedTitle()
                                }
                            </h2>

                            <div>
                                {
                                    props.getGeneratedExcerpt()
                                }
                            </div>
                        </div>

                    </div>

                    <hr style={{ width: "100%" }} />

                    <div className="post-preview-summary">

                        <div className="post-preview-title">
                            <h2>
                                {
                                    props.getGeneratedTitle()
                                }
                            </h2>
                        </div>

                        <div className="post-preview-image">
                            {
                                props.imageData.featuredImage != null
                                    ? <img src={props.imageData.featuredImage.url} alt={props.imageData.featuredImage.altText} />
                                    : <img src={require('../../../assets/img/update-image.webp')} style={{ maxWidth: "350px" }} />
                            }
                        </div>

                        <div className="post-preview-website-data">
                            <h4>
                                {
                                    props.websiteData.selectedWebsite
                                }
                            </h4>
                            <h4>
                                {
                                    props.websiteData.selectedPostStatus + " - " + props.websiteData.selectedPostType
                                }
                            </h4>
                        </div>

                        <div className="post-preview-content">
                            {
                                props.getGeneratedSummary()
                            }
                        </div>


                    </div>

                    <hr style={{ width: "100%" }} />

                    <div className="post-preview-metadata">

                        {
                            props.postData["topics"].length > 0 &&
                            <div className="topics">
                                <div>
                                    Topics:
                                </div>
                                <div>
                                    {
                                        props.postData["topics"].map(customPostType => {
                                            return <span className='topic' key={customPostType.id}>{customPostType.name}</span>
                                        })
                                    }
                                </div>
                            </div>
                        }

                        {/* <div className="keywords">
                            <div>
                                SEO Keywords:
                            </div>
                            <div>
                                {
                                    seo.map(keyword =>
                                        <span className='keyword'>{keyword}</span>
                                    )
                                }
                            </div>
                        </div> */}

                        {
                            DiploWebsitesService.getAvailableCustomPostTypes(props.websiteData.selectedWebsite, props.websiteData.selectedPostType)
                                .map(customPostType =>
                                    renderCustomPostData(customPostType)
                                )
                        }

                    </div>

                </div>
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                <Button onClick={props.handleBack}>
                    Back
                </Button>

                <Button variant="contained" onClick={sendToWebsite} >
                    Send to website
                </Button>

            </Box>

        </>
    );
}

export default PostPreview;

function CustomPostDataPriview(props) {
    return (
        <>
            {/* <div className="custom-posts-data"> */}
            <div className="custom-posts">
                <div>
                    {props.title}:
                </div>
                <div>
                    {
                        props.data.map(el =>
                            // <span className="custom-post-data" key={el.id}>{el.name}</span>
                            <span className="custom-post" key={el.id}>{el.name}</span>
                        )
                    }
                </div>
            </div>
        </>
    );
}