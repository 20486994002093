import React,{useEffect,useRef} from 'react'

function ErrorHandler({status,text,type}) {

  const moda=useRef()
  useEffect(() => {
    setIsVisible(true);

    setTimeout(() => {
      setIsVisible(false);
      
    }, 3000);
  
    return 
  }, [])
  
  const setIsVisible=(set)=>{
    if(set){
      moda.current.style.display='block';
    }else{
      moda.current.style.display='none';

    }
  }

  return (
    <div id="myModal" class="errorModal" ref={moda}>

      <div class="error-modal-content">
        <p>({status}) {text}</p>
      </div>

    </div>
  )
}

export default ErrorHandler