import React,{useState,useEffect} from 'react'
import Btn from './Btn'
import {setLastcliked} from '../tools/AppDashSetter'
function BtnGrid({file,Title,no_data}) {
    const [btn,setBtn]=useState('')


    useEffect(()=>{
        if (file!=null){
            var layout=[]
            for(var i=0;i<file.length;i++){

                layout.push(<Btn Name={file[i]['name']} icon={file[i]['icon']} link={file[i]['path']} click={setLastcliked} id={file[i]['id']}/>)
            }
            setBtn(layout)
        }
    },[file])
    return (
        <div className='btn-grid-wrapper'>
            <h2>{Title}</h2>
            <hr />
            {btn.length>0?
            <div className="btn-grid-body">
                
                {btn}
                
            </div>
            
            :
            
            <h3>{no_data}</h3>}
            
        </div>
    )
}

export default BtnGrid