import { useNavigate } from "react-router";
import { fetchToken,setToken,fetchDecodedData } from "../tools/Auth";
import {useState,useRef,useEffect} from "react";
import axios from 'axios';
import Diplo from '../../images/diplo-logo.png';
import arrow from '../../images/arrow.svg'
import cloud from '../../images/cloud.svg';
import ErrorHandler from "../tools/ErrorHandler";

export default function Login(props) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dev, setDev] = useState(false);
  

  
  useEffect(() => {
    if (process.env.REACT_APP_URL.includes("dev-ai")){
      setDev(true)
    }
  },)
  const sendSSOreq = () => {
    var route='/sso_login'
    if(dev){
      route='/sso_login_dev'
      axios
        .post(process.env.REACT_APP_BACKEND_URL+route,{'username':username,'password':password})
        .then((response) =>{
          window.location.href=response.data
        }).catch((err)=>{
          props.setEr({'error':'Forbiden','code':'403'})
        })
        return false
    }
      axios
        .get(process.env.REACT_APP_BACKEND_URL+route)
        .then((response) =>{
          window.location.href=response.data
        }).catch((err)=>{
          console.log(err);
          props.setEr({'error':err?.data,'code':err?.status_code})
        })
    return false
  };
  return (
    <>
        {props.error?<ErrorHandler text={props.error.error} status={props.error.code} type={0}/>:''}
        <div className='content-fluid-wrap'>
          <div className="content-fluid">

            <div className='login-box'>
              <div className="form-header">
                  <img src={Diplo} alt="" srcSet="" />
                  {/* <span>Sign in</span> */}
              </div>
              <div className="main-form">
              {dev?
              <>
                <div className="input-wrap">

                  <label style={{ marginRight: 10 }}>Username:</label>
                  <input
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="input-wrap">

                  <label style={{ marginRight: 10 }}>Password:</label>
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    />
                </div></>
                :
                <></>
                }
              <div className="sso-form">

                <button type="button" className='btn btn-login btn-sso btn-icons' onClick={()=>sendSSOreq()}><span className="btn_text"> SINGLE SIGN ON </span><span className="icon icon-cloud"></span></button>
              </div>
              </div>

            
            </div>
          </div>
        
        </div>
      
    </>
  );
}
