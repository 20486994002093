import React, { memo,useEffect,useState } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import json from '../pages/features.json'
import axios from 'axios'


const Map = ({ setTooltipContent,setNone,mapType,handleOpen,setlistOfStud }) => {

  const [data,setData]=useState({})

  var colorScale = scaleLinear()
  .domain([0, 1000])
  .range(["#ffedea", "#ff5233"]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_STUDENT_API+'/students/map?type='+mapType).then(map =>{
      

      setData(map.data.map_data)
      const d = map.data.map_data.find((s) => s.nationality === 'None');
      setNone(d.size)
    })
  },[mapType])

  const clicked =(code3)=>{
    axios.get(process.env.REACT_APP_STUDENT_API+'/students/map/single?type='+mapType+'&code3='+code3).then(map =>{
      setlistOfStud(map.data)
    }).then(()=>{
      handleOpen()

    })
  }

  return (
    <>
    {Object.keys(data).length>0?
      <>
      
      <ComposableMap
      style={{
        height:'700px'
      }}
      >
        <ZoomableGroup>
          <Geographies geography={json}>
            {({ geographies }) =>
              geographies.map((geo) =>{ 
                const d = data.find((s) => s.nationality === geo.id);
                
                return(
                  <Geography
                  className="country"
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d["size"]) : "#ffedea"}
                  onMouseEnter={() => {
                    setTooltipContent(<><strong>{geo.properties.name} </strong>
                     <br/>Students: {d ?d["size"]:''}</>);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  onClick={() => {
                    clicked(geo.id)
                  }}
                  style={{
                    hover: {
                      fill: "#F53",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none"
                    }
                  }}
                  />
                )})
              }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
    
    :''}
    </>
  );
};

export default memo(Map);
