import React, { useState,useEffect } from 'react'
import {data} from "./data"
import Map from "../components/Map"
import { Tooltip as ReactTooltip } from "react-tooltip"
import Radios from "../components/Radios";
import MapModal from '../components/MapModal';

function Maps() {
  const [content, setContent] = useState("");
  const [mapType, setmapType] = useState("0")
  const [none, setNone] = useState("");

  const [open,setOpen]=useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [listOfStud,setlistOfStud]=useState({})
  return (
  <div className='Map-main'>
    
    <div className='Map-body'>
      <div className="map-title">
        <a href='/student-statistics'>Back</a>
        <div>
          <h1>Student Map Statistics View</h1>
        </div>
      </div>
      <div className="box map-wrap">
        <div className="flex">
        <MapModal open={open} handleClose={handleClose} list={listOfStud} country={content}/>
        <Radios setmapType={setmapType} mapType={mapType}></Radios>
        {none?<span className="text-none-stud">N/A for <span className="student-number-count">{none}</span> students</span>:""}
        </div>
        <Map setTooltipContent={setContent} setNone={setNone} mapType={mapType} handleOpen={handleOpen} setlistOfStud={setlistOfStud}/>
        <ReactTooltip anchorSelect='.country'>{content}</ReactTooltip>
      </div>
    </div>
  </div>
  )
}

export default Maps