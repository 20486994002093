import * as React from 'react';

// Import styles
import '../../../../assets/css/main-apps.css';
import '../assets/css/image-searcher-main.css';

import ImageSearcher from '../components/ImageSearcher';



function ImageSearcherPage(props) {

    // Selected Images
    const [selectedImages, setSelectedImages] = React.useState([]);

    const addSelectedImage = (image) => {
        let selectedImagesPom = [...selectedImages];
        selectedImagesPom.push(image);
        setSelectedImages(selectedImagesPom);
    };

    const removeSelectedImage = (image) => {
        setSelectedImages(selectedImages.filter((item) => item !== image));
    };

    return (
        <div id="image-search-page" className="page-container">
            <ImageSearcher
                selectedImages={selectedImages}
                addSelectedImage={addSelectedImage}
                removeSelectedImage={removeSelectedImage}
            />
        </div>
    );
}

export default ImageSearcherPage;