import React,{useState,useEffect} from 'react'

function NotiSingle({title,text,time}) {

  const [textEdit,setTextEdit]=useState([])
  
  useEffect(function () {
    var edit=text.split(/\[\[(.*?)\]\]/g)
    edit[1]=<a href={edit[1]}>link</a>
    setTextEdit(edit)
  },[])

  return (
    <div className='notiSingle'>
        <strong>{title}</strong>
        <p>{textEdit.map(e=>{return e})}</p>
        <span>{time}</span>
    </div>
  )
}

export default NotiSingle