import React, { Component } from 'react';
import NewsService from '../services/NewsService';
import News from './News';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import { Button, CardActionArea, CardActions, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SideNews from './SideNews';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

class SideNewsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newsList: null,
            selectedNews: [],
            page: 1,
            totalPages: 0,
            elementTitle: props.elementTitle
        };
    }

    componentDidMount() {
        this.getNews(1);
    }

    getNews = (page) => {
        this.setState({
            newsList: null,
        });

        NewsService.getNews(page).then((res) => {
            console.log(res);
            this.setState({
                newsList: res.data.items.slice(0, 5),
                page: page,
                totalPages: res.data.pages
            });
        });
    }

    addNewsToSelected = (news) => {
        this.state.selectedNews.push(news)
    }

    startSummarization = () => {

        if (this.state.selectedNews.length == 0) {
            alert("Please select some news");
            return;
        }

        let queryParams = "";

        for (let i = 0; i < this.state.selectedNews.length; i++) {
            queryParams += (i == 0 ? '?' : '&') + `article${i + 1}=${this.state.selectedNews[i].link}`;
        }

        window.open(process.env.REACT_APP_URL + "/ai-writer/summarizer" + queryParams);

    }

    handlePageChange = () => {
        this.setState({page: this.state.page})
        this.getNews(this.state.page);
    };

    render() {
        return (
            <>
                <div className="card-container">
                    <div className="card-container-header">
                        <h2>
                            {this.state.elementTitle}
                        </h2>
                        <div className="pagination">
                            <IconButton>
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <IconButton onClick={this.handlePageChange}>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </div>
                    </div>
                    <hr />
                    <div className='ai-recommended-news-body'>
                        {
                            this.state.newsList == null
                                ? <>
                                    Loading data...
                                </>
                                : <>
                                    {
                                        this.state.newsList.map(
                                            element => <SideNews news={element} addNewsToSelected={this.addNewsToSelected} />
                                        )
                                    }
                                </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

SideNewsList.propTypes = {

};

export default SideNewsList;