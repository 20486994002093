import React from 'react'
import BtnGrid from './BtnGrid'
import data from '../../apps.json'
import {getLastcliked} from '../tools/AppDashSetter.js'
function UserDash() {
  return (

      <div className='UserDash-body'>
        <BtnGrid Title='Recently used' file={getLastcliked()} no_data="No recently used"/>
        <BtnGrid Title='Favorite apps' file={data['Fav']} no_data="No Favorite used. Please go to options of apps to set as favorite."/>
      </div>
  
  )
}

export default UserDash