import React, { useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Alert, AlertTitle } from '@mui/material';
import DiploWebsitesService from '../../../services/DiploWebsitesService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const testData = {
    "topic": [
        {
            "ID": 257,
            "post_title": "Freedom of the press"
        },
        {
            "ID": 260,
            "post_title": "Development"
        },
    ],
    "values": [
        {
            "ID": 28833,
            "post_title": "US elections and digital policy"
        },
        {
            "ID": 28832,
            "post_title": "US elections and digital policy"
        },
        {
            "ID": 28830,
            "post_title": "Digital policy on the agenda of the UN General Assembly's 73rd session"
        }
    ],
    "technologies": [
        {
            "ID": 110886,
            "post_title": "Freedom of the press"
        },
        {
            "ID": 110924,
            "post_title": "Development"
        },
        {
            "ID": 110871,
            "post_title": "Development"
        },
    ],
    "trends": [
        {
            "ID": 28804,
            "post_title": ""
        },
        {
            "ID": 85,
            "post_title": ""
        }
    ],
    "processes": [
        {
            "ID": 124,
            "post_title": "Development"
        },
        {
            "ID": 126,
            "post_title": "Development"
        },
    ],
    "countries": [
        "Russia"
    ],
    "actor": [
        {
            "ID": 111546,
            "post_title": "3rd Generation Partnership Project"
        },
        {
            "ID": 111549,
            "post_title": "Access Now"
        },
        {
            "ID": 111547,
            "post_title": "Access Now"
        }
    ]
};

const loadingFinishedStatus = 1;

function AddPostData(props) {

    const [displayHelpData, setDisplayHelpData] = React.useState("");

    const [availableTopics, setAvailableTopics] = React.useState([]);
    const [selectedTopics, setSelectedTopics] = React.useState([]);

    // WordPress Custom Post Types

    /**
     * gettingDataStatus da li su ucitani svi podaci
     * 
     * statusi:
     * 0 - nije pocelo ucitavanje
     * 1 - ucitavanje krenulo vise ne pokreci
     */
    const [gettingDataStatus, setGettingDataStatus] = React.useState(0);
    const [gettingAIRecommendations, setGettingAIRecommendations] = React.useState(false);
    const [disableForms, setDisableForms] = React.useState(true);

    const [availableCustomPostTypes, setAvailableCustomPostTypes] = React.useState([]);

    const [availableActors, setAvailableActors] = React.useState([]);
    const [selectedActors, setSelectedActors] = React.useState([]);

    const [availableCountries, setAvailableCountries] = React.useState([]);
    const [selectedCountries, setSelectedCountries] = React.useState([]);

    const [availableExperts, setAvailableExperts] = React.useState([]);
    const [selectedExperts, setSelectedExperts] = React.useState([]);

    const [availableTrends, setAvailableTrends] = React.useState([]);
    const [selectedTrends, setSelectedTrends] = React.useState([]);

    const [availableProcesses, setAvailableProcesses] = React.useState([]);
    const [selectedProcesses, setSelectedProcesses] = React.useState([]);

    const [availableTechnologies, setAvailableTechnologies] = React.useState([]);
    const [selectedTechnologies, setSelectedTechnologies] = React.useState([]);

    const [availableValues, setAvailableValues] = React.useState([]);
    const [selectedValues, setSelectedValues] = React.useState([]);

    const [availablePeople, setAvailablePeople] = React.useState([]);
    const [selectedPeople, setSelectedPeople] = React.useState([]);

    const [availableCountryTerritory, setAvailableCountryTerritory] = React.useState([]);
    const [selectedCountryTerritory, setSelectedCountryTerritory] = React.useState([]);

    useEffect(() => {

        if (props.websiteData.selectedWebsite == undefined) return;

        // Dovuci koji su svi dozvoljeni custom post tipovi za ovaj sajt i post tip
        getAvailableCustomPostTypes();

        if (gettingDataStatus == 0 && availableCustomPostTypes.length != 0) {
            setGettingDataStatus(1);
            getAllCustomPostTypesData();
            getAvailableTopics();
            getAIRecommendations();
        }

    });

    const handleNext = () => {

        let topics = [];
        selectedTopics.forEach(element => {
            topics.push({
                "id": element,
                "name": getTopicNameById(element),
            });
        });

        let actors = [];
        selectedActors.forEach(element => {
            actors.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("actor", element),
            });
        });

        let countries = [];
        selectedCountries.forEach(element => {
            countries.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("countries", element),
            });
        });

        let experts = [];
        selectedExperts.forEach(element => {
            experts.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("expert", element),
            });
        });

        let trends = [];
        selectedTrends.forEach(element => {
            trends.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("trends", element),
            });
        });

        let processes = [];
        selectedProcesses.forEach(element => {
            processes.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("processes", element),
            });
        });

        let technologies = [];
        selectedTechnologies.forEach(element => {
            technologies.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("technologies", element),
            });
        });

        let values = [];
        selectedValues.forEach(element => {
            values.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("values", element),
            });
        });

        let people = [];
        selectedPeople.forEach(element => {
            people.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("people", element),
            });
        });

        let countryTerritory = [];
        selectedCountryTerritory.forEach(element => {
            countryTerritory.push({
                "id": element,
                "name": getSpecificCustomPostTypeTitleByID("country-territory", element),
            });
        });

        props.setPostData({
            topics: topics,
            actor: actors,
            countries: countries,
            expert: experts,
            trends: trends,
            processes: processes,
            technologies: technologies,
            values: values,
            people: people,
            "country-territory": countryTerritory,
        })
        props.handleNext();
    };

    // Topics

    const getAvailableTopics = () => {

        DiploWebsitesService.getTopics(props.websiteData.selectedWebsite)
            .then((res) => {
                if (res.status != 200) {
                    alert("Error getting website's topics");
                }

                let topics = JSON.parse(res.data);

                setAvailableTopics(topics);
            })
            .catch((error) => {
                console.error(error);
                alert("Error getting topics from : " + props.websiteData.selectedWebsite);
            });
    };

    const getTopicNameById = (id) => {
        return availableTopics.find(item => item.term_id == id).name;
    }

    const handleTopicSelect = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedTopics(
            typeof value === 'string' ? value.split(',') : value,
        )
    };

    const handleTopicDelete = (topicToDelete) => {
        setSelectedTopics((selectedTopics) => selectedTopics.filter((topic) => topic !== topicToDelete));
    };

    // Custom Post Types

    // For all custom post types

    // Ne vrsi nikakav upit samo vidi za selektovani post tip koji su mu moguci custom post tipovi
    const getAvailableCustomPostTypes = () => {
        setAvailableCustomPostTypes(DiploWebsitesService.getAvailableCustomPostTypes(props.websiteData.selectedWebsite, props.websiteData.selectedPostType));
    };

    // Ucitava vrednosti za svaki custom post tip i setuje ih
    const getAllCustomPostTypesData = () => {

        for (let i = 0; i < availableCustomPostTypes.length; i++) {

            DiploWebsitesService.getAvailableCustomPostTypeData(props.websiteData.selectedWebsite, availableCustomPostTypes[i])
                .then((res) => {
                    if (res.status != 200) {
                        alert("Error getting website's custom post type data");
                    }

                    let customPostTypeData = JSON.parse(res.data);

                    setAllCustomPostTypesData(customPostTypeData, availableCustomPostTypes[i]);
                })
                .catch((error) => {
                    console.error(error);
                    alert("Error getting " + availableCustomPostTypes[i] + " data from : " + props.websiteData.selectedWebsite);
                });
        }
    };

    const setAllCustomPostTypesData = (data, customPostType) => {
        switch (customPostType) {
            case "actor":
                setAvailableActors(data);
                return;
            case "countries":
                setAvailableCountries(data);
                return;
            case "expert":
                setAvailableExperts(data);
                return;
            case "trends":
                setAvailableTrends(data);
                return;
            case "processes":
                setAvailableProcesses(data);
                return;
            case "technologies":
                setAvailableTechnologies(data);
                return;
            case "values":
                setAvailableValues(data);
                return;
            case "people":
                setAvailablePeople(data);
                return;
            case "country-territory":
                setAvailableCountryTerritory(data);
                return;
        }
    }

    // For specific custom post type

    const getSelectedCustomPostTypeData = (customPostType) => {
        switch (customPostType) {
            case "actor":
                return selectedActors;
            case "countries":
                return selectedCountries;
            case "expert":
                return selectedExperts;
            case "trends":
                return selectedTrends;
            case "processes":
                return selectedProcesses;
            case "technologies":
                return selectedTechnologies;
            case "values":
                return selectedValues;
            case "people":
                return selectedPeople;
            case "country-territory":
                return selectedCountryTerritory;
        }
    };

    const getSpecificCustomPostTypeData = (customPostType) => {
        switch (customPostType) {
            case "actor":
                return availableActors;
            case "countries":
                return availableCountries;
            case "expert":
                return availableExperts;
            case "trends":
                return availableTrends;
            case "processes":
                return availableProcesses;
            case "technologies":
                return availableTechnologies;
            case "values":
                return availableValues;
            case "people":
                return availablePeople;
            case "country-territory":
                return availableCountryTerritory;
        }
    };

    const getSpecificCustomPostTypeTitleByID = (customPostType, id) => {
        console.log(customPostType);
        console.log(id);
        console.log(getSpecificCustomPostTypeData(customPostType).find(item => item.ID == id));
        return getSpecificCustomPostTypeData(customPostType).find(item => item.ID == id).post_title;
    }

    const handleCustomPostTypeSelect = (value, customPostType) => {

        // const {
        //     target: { value },
        // } = event;

        console.log("dodavanje");
        console.log(value);
        console.log(typeof value === 'string' ? value.split(',') : value,);

        switch (customPostType) {
            case "topic":
                // let selectedTopics = [];
                // let recomendedTopics = props.getRecomendedTopics();

                // topics.forEach(topic => {
                //     if (recomendedTopics.includes(topic.name)) {
                //         selectedTopics.push(topic.term_id);
                //     }
                // });

                // setSelectedTopics(selectedTopics);

                setSelectedTopics(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "actor":
                setSelectedActors(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "countries":
                setSelectedCountries(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "expert":
                setSelectedExperts(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "trends":
                setSelectedTrends(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "processes":
                setSelectedProcesses(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "technologies":
                setSelectedTechnologies(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "values":
                setSelectedValues(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "people":
                setSelectedPeople(typeof value === 'string' ? value.split(',') : value,);
                break;
            case "country-territory":
                setSelectedCountryTerritory(typeof value === 'string' ? value.split(',') : value,);
                break;
        }

    };

    const handleCustomPostTypeDelete = (idToDelete, customPostType) => {
        switch (customPostType) {
            case "actor":
                setSelectedActors((selectedActors) => selectedActors.filter((ID) => ID !== idToDelete));
                break;
            case "countries":
                setSelectedCountries((selectedCountries) => selectedCountries.filter((ID) => ID !== idToDelete));
                break;
            case "expert":
                setSelectedExperts((selectedExperts) => selectedExperts.filter((ID) => ID !== idToDelete));
                break;
            case "trends":
                setSelectedTrends((selectedTrends) => selectedTrends.filter((ID) => ID !== idToDelete));
                break;
            case "processes":
                setSelectedProcesses((selectedProcesses) => selectedProcesses.filter((ID) => ID !== idToDelete));
                break;
            case "technologies":
                setSelectedTechnologies((selectedTechnologies) => selectedTechnologies.filter((ID) => ID !== idToDelete));
                break;
            case "values":
                setSelectedValues((selectedValues) => selectedValues.filter((ID) => ID !== idToDelete));
                break;
            case "people":
                setSelectedPeople((selectedPeople) => selectedPeople.filter((ID) => ID !== idToDelete));
                break;
            case "country-territory":
                setSelectedCountryTerritory((selectedCountryTerritory) => selectedCountryTerritory.filter((ID) => ID !== idToDelete));
                break;
        }
    };

    const getAIRecommendations = () => {
        setGettingAIRecommendations(true);
        DiploWebsitesService.getCustomPostTypeRecommend(props.websiteData.selectedWebsite, props.getGeneratedSummary())
            .then((res) => {
                if (res.status != 200) {
                    alert("Error getting website's topics");
                }

                let data = res.data;
                setDisplayHelpData(data);

                availableCustomPostTypes.forEach(cpt => {
                    if(data[cpt] != undefined && data[cpt].length > 0)
                        handleCustomPostTypeSelect(data[cpt].map(el => el["ID"]), cpt)
                });

                handleCustomPostTypeSelect(data["topic"].map(el => el["ID"]), "topic")


                setDisableForms(false);
                setGettingAIRecommendations(false);

            })
            .catch((error) => {
                console.error(error);
                setDisableForms(false);
                setGettingAIRecommendations(false);
                alert(`Error getting recommendations: ${props.websiteData.selectedWebsite}. You can still add data manually.`);
            });

    }

    return (
        <>

            {
                gettingAIRecommendations
                    ? <Alert severity="info" style={{ marginTop: "20px" }}>
                        <AlertTitle><strong>The AI is currently generating the recommendations.</strong></AlertTitle>
                        Please wait patiently until the process is completed.
                    </Alert>
                    : <Alert severity="info" style={{ marginTop: "20px" }}>
                        <AlertTitle>The automatically selected data has been <strong>generated by AI and requires review and verification.</strong></AlertTitle>
                        Please ensure its accuracy and validity before proceeding further.
                    </Alert>
            }
            
            <div className="send-to-website-container add-post-data">
                <div className="send-to-website-header">
                    <h2>
                        Add post data: {props.websiteData.selectedWebsite == undefined ? "Select website first" : props.websiteData.selectedWebsite}
                    </h2>
                </div>
                <hr className="send-to-website-subheading" />
                <div className="send-to-website-body">

                    <FormControl
                        disabled={disableForms}
                    >
                        <InputLabel id="topic-select-label">Topics</InputLabel>
                        <Select
                            labelId="topic-select-label"
                            id="topic-select"
                            multiple
                            value={selectedTopics}
                            onChange={handleTopicSelect}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip
                                            key={value}
                                            // label={availableTopics.find(item => item.term_id == value).name}
                                            label={getTopicNameById(value)}
                                            clickable
                                            deleteIcon={
                                                <ClearIcon onMouseDown={(e) => e.stopPropagation()} />
                                            }
                                            onDelete={() => handleTopicDelete(value)}
                                        />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {
                                availableTopics != null && availableTopics.length != 0
                                    ? availableTopics.map(
                                        element => <MenuItem
                                            key={element.term_id}
                                            value={element.term_id}
                                        >
                                            {element.name}
                                        </MenuItem>
                                    )
                                    : <></>
                            }
                        </Select>
                    </FormControl>

                    {
                        availableCustomPostTypes.length != 0 &&
                        availableCustomPostTypes.map(
                            (customPostType, index) => <FormControl
                                disabled={availableCustomPostTypes.length == 0}
                            >
                                <InputLabel id={"custom-post-type-select-label-" + index}>{customPostType}</InputLabel>
                                <Select
                                    disabled={disableForms}
                                    labelId={"custom-post-type-select-label-" + index}
                                    label={customPostType}
                                    id={"custom-post-type-select-" + index}
                                    multiple
                                    value={getSelectedCustomPostTypeData(customPostType)}
                                    onChange={(event) => handleCustomPostTypeSelect(event.target.value, customPostType)}
                                    input={<OutlinedInput id={"select-multiple-chip" + index} label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((id) => (
                                                <Chip
                                                    key={id}
                                                    // label={getSpecificCustomPostTypeData(customPostType).find(item => item.ID == id).post_title}
                                                    label={getSpecificCustomPostTypeTitleByID(customPostType, id)}
                                                    clickable
                                                    deleteIcon={
                                                        <ClearIcon onMouseDown={(e) => e.stopPropagation()} />
                                                    }
                                                    onDelete={() => handleCustomPostTypeDelete(id, customPostType)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        getSpecificCustomPostTypeData(customPostType) != null && getSpecificCustomPostTypeData(customPostType).length != 0
                                            ? getSpecificCustomPostTypeData(customPostType).map(
                                                element => <MenuItem
                                                    key={element.ID}
                                                    value={element.ID}
                                                >
                                                    {element.post_title}
                                                </MenuItem>
                                            )
                                            : <></>
                                    }
                                </Select>
                            </FormControl>
                        )
                    }


                </div>
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                <Button onClick={props.handleBack}>
                    Back
                </Button>

                <Button onClick={handleNext}>
                    Next
                </Button>

            </Box>

        </>
    );

}

export default AddPostData;