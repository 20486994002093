import React, { Component } from 'react';

import { Button, IconButton, Tooltip } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CompareIcon from '@mui/icons-material/Compare';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DangerousIcon from '@mui/icons-material/Dangerous';
import LaunchIcon from '@mui/icons-material/Launch';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import '../assets/css/summary-page.css'
import NewsService from '../services/NewsService';

class OriginalArticle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            article: props.article,
            addToContentForSummarisation: props.addToContentForSummarisation,
            newsContentEditorOn: false,
            newNewsContent: React.createRef(),
            collapsed: false
        }
    }

    openUsingVia = (link) => {

        // openLinkInNewTab(process.env.REACT_APP_URL + "/ai-writer/news?original=" + props.openedNewsData.link)

        console.log("openUsingVia PROMENA");
        window.open(process.env.REACT_APP_URL_TEST + "/ai-writer/news?original=" + link, '_blank', 'noreferrer');

        // NewsService.getNewsSingle(link);
        // console.log(NewsService.getNewsSingle(link));
            // window.open(link, '_blank', 'noreferrer');
    }

    componentDidMount() {
    }

    saveNewsContent = () => {
        NewsService.saveNewsContent(this.state.article.link, this.state.newNewsContent.current.value)
            .then((res) => {
                alert("News content saved successfully.");

                this.setState({
                    article: res.data.properties,
                    newsContentEditorOn: false
                })
            })
            .catch((error) => {
                console.error(error);
                alert("Error saving news content.");
                this.setState({
                    newsContentEditorOn: false
                });
            });
    }

    render() {
        return (
            <div className='original-article' key={this.state.article.source}>
                <div className="original-article-header">
                    <div className="original-article-header-title">
                        <a href={this.state.article.link} target="_blank">
                            <h2 style={{ marginTop: 0, marginBottom: 0 }}>
                                {this.state.article.title}
                            </h2>
                        </a>

                        {/* Close Button */}
                        <div className="collapse-buttons">
                            {
                                this.state.collapsed
                                    ? <IconButton onClick={() => this.setState({ collapsed: false })}>
                                        <UnfoldMoreIcon style={{ width: "40px", height: "40px" }} />
                                    </IconButton>
                                    : <IconButton onClick={() => this.setState({ collapsed: true })}>
                                        <UnfoldLessIcon style={{ width: "40px", height: "40px" }} />
                                    </IconButton>
                            }
                        </div>
                    </div>
                    {
                        this.state.newsContentEditorOn
                            ? <Button
                                variant="outlined"
                                startIcon={<DangerousIcon />}
                                onClick={() => this.setState({ newsContentEditorOn: !this.state.newsContentEditorOn })}
                            >
                                Discard changes
                            </Button>
                            : <div style={{ display: "flex", justifyContent: "space-between" }}>

                                {
                                    !this.state.collapsed &&
                                    <>
                                        {/* Stari stil dugmica zamenjen novim */}
                                        <div>
                                            {/* <Button
                                                variant="outlined"
                                                startIcon={<CopyAllIcon />}
                                                onClick={() => this.state.addToContentForSummarisation(this.state.article.text)}
                                                style={{ marginRight: "20px" }}
                                            >
                                                Copy whole text
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={<CompareIcon />}
                                                onClick={() => this.setState({ newsContentEditorOn: !this.state.newsContentEditorOn })}
                                                style={{ marginRight: "20px" }}
                                            >
                                                Change article content
                                            </Button> */}
                                        </div>
                                        <div>
                                            {/* Stari stil dugmica zamenjen novim */}
                                            {/* <a href={"https://via.diplomacy.edu/" + process.env.REACT_APP_URL + "/ai-writer/news?original=" + this.state.article.link} target="_blank">
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<LaunchIcon />}
                                                    onClick={() => this.openUsingVia(this.state.article.link)}
                                                >
                                                    Open using Via
                                                </Button>
                                            </a> */}
                                            <Tooltip title={<span className="tooltip-text">Copy whole text</span>}>
                                                <IconButton
                                                    aria-label="select"
                                                    onClick={() => this.state.addToContentForSummarisation(this.state.article.text)}
                                                    style={{ color: "#00879a" }}>
                                                    <CopyAllIcon sx={{ height: 30, width: 30 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={<span className="tooltip-text">Change article content</span>}>
                                                <IconButton
                                                    aria-label="select"
                                                    onClick={() => this.setState({ newsContentEditorOn: !this.state.newsContentEditorOn })}
                                                    style={{ color: "#00879a" }}>
                                                    <CompareIcon sx={{ height: 30, width: 30 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={<span className="tooltip-text"> Open using Via</span>}>
                                                <IconButton
                                                    aria-label="select"
                                                    onClick={() => this.openUsingVia(this.state.article.link)}
                                                    style={{ color: "#00879a" }}>
                                                    <LaunchIcon sx={{ height: 30, width: 30 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </>
                                }

                            </div>
                    }
                    {
                        !this.state.collapsed &&
                        <hr style={{ marginTop: 0, marginBottom: "30px" }} />
                    }
                </div>

                {
                    this.state.collapsed
                        ? <></>
                        : <>
                            <img src={this.state.article.thumbnail} />
                            {
                                this.state.newsContentEditorOn
                                    ? <>
                                        <div className="original-article-editor">
                                            <textarea
                                                ref={this.state.newNewsContent}
                                                spellcheck="true"
                                            >
                                                {this.state.article.text}
                                            </textarea>
                                        </div>
                                        <Button
                                            variant="outlined"
                                            startIcon={<SaveAsIcon />}
                                            onClick={this.saveNewsContent}
                                        >
                                            Save Changes
                                        </Button>
                                    </>
                                    : <div
                                        className='original-article-content'
                                        dangerouslySetInnerHTML={{ __html: this.state.article.text.split('\n\n').map(s => `<p>${s}</p>`).join('') }}
                                    ></div>
                            }
                        </>
                }

            </div>
        );
    }
}

export default OriginalArticle;