import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SummarizerService from '../services/SummarizerService';
import OriginalArticle from './OriginalArticle';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import SummaryResults from './SummaryResults';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Button, CircularProgress, LinearProgress } from '@mui/material';
import Autorenew from '@mui/icons-material/Autorenew';
import Box from '@mui/material/Box';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ClearIcon from '@mui/icons-material/Clear';
import NewsService from '../services/NewsService';

import TestResponse from '../assets/json/test-response.json';

// kada je false onda radi kako treba
// true je za testiranje da izbaci test primer bez slanja zahteva
const testIsOn = false;

class Summarizer extends Component {

    constructor(props) {
        super(props);

        const params = new URLSearchParams(window.location.search);

        let articlesSources = [];

        // Ako je prazno prikazem formu za dodavanje clanka preko linka
        if (params.toString() != "") {
            let index = 0;
            do {
                articlesSources.push(params.get(`article${++index}`))
            } while (params.get(`article${index + 1}`) != null);
        }

        this.state = {
            articlesSources: articlesSources,
            articles: [],
            contentForSummarisation: "",
            summary: {},
            gettingData: null,
            hideContent: false,
            newArticleField: React.createRef(),
            gettingArticleInProgress: false,
            timeTaken: 0
        }

    }

    componentDidMount() {
        this.getArticles();
        window.addEventListener('scroll', this.handleScroll);
    }

    getArticles = () => {

        if (this.state.articlesSources.length == 0) {
            this.setState({
                articles: null
            });
        }

        this.state.articlesSources.forEach(source => {
            NewsService.getNewsContent(NewsService.removeViaFromLink(source))
                .then((res) => {
                    let pom = this.state.articles;

                    if (!pom.some(e => e.source === res.data.source)) {
                        pom.push(res.data);

                        this.setState({
                            articles: pom
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    alert("Error getting this article: " + source);
                });
        });

    }

    getArticle = () => {
        this.setState({
            gettingArticleInProgress: true
        });

        NewsService.getNewsContent(NewsService.removeViaFromLink(this.state.newArticleField.current.value))
            .then((res) => {
                let pom = this.state.articles;

                if (pom == null)
                    pom = [];

                if (!pom.some(e => e.link === res.data.link)) {
                    pom.push(res.data);

                    this.setState({
                        articles: pom,
                        gettingArticleInProgress: false
                    });

                    this.state.newArticleField.current.value = "";
                }
            })
            .catch((error) => {
                console.error(error);
                alert("Error getting this article: " + this.state.newArticleField.current.value);
            });
    }

    startTimer = () => {
        this.setState({
            timerInterval: setInterval(() => {
                this.setState({ timeTaken: this.state.timeTaken + 1 }); // Increment seconds
            }, 1000)
        })
    }

    stopTimer = () => {
        clearInterval(this.state.timerInterval);
        // this.setState({ timeTaken: 0 });
    }

    startSummarisation = () => {

        this.startTimer();

        if (testIsOn) {
            this.setState({
                gettingData: false,
                summary: TestResponse
            });

            document.getElementById("results-container").scrollIntoView();
        }
        else {
            this.setState({
                gettingData: true
            });

            SummarizerService.getSummary(this.state.contentForSummarisation)
                .then((res) => {
                    this.stopTimer();
                    var text=Object.assign(this.state.summary,res.data.server)
                    this.setState({
                        gettingData: false,
                        summary:text 
                    });
                    console.log(this.state.summary);

                    document.getElementById("results-container").scrollIntoView();
                })
                .catch((error) => {
                    this.stopTimer();
                    // Error
                    if (error.response) {
                        console.error(error.response);
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    console.error(error.config);
                    alert("Sorry, but it seems that external LLM provider is not working at the moment. Please try later.");
                    // alert("Error getting summary.");
                    this.setState({
                        gettingData: null
                    });
                });
                SummarizerService.getTextAnalysis(this.state.contentForSummarisation)
                    .then((res) => {
                        var text=Object.assign(this.state.summary,res.data)
                        this.setState({
                            
                            summary: text

                        });
                        console.log(this.state.summary);
                    }).catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response);
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        console.error(error.config);
                        alert("Sorry, but it seems that external LLM provider is not working at the moment. Please try later.");
                        // alert("Error getting summary.");
                        this.setState({
                            gettingData: null
                        });
                    });
        }


    }

    clearSummarisation = () => {
        this.setState({
            contentForSummarisation: ""
        });
    }

    handleScroll = (event) => {

        let divOriginalArticles = document.getElementById("original-articles");
        let divDiploSummary = document.getElementById("diplo-summary");
        let scroll = window.scrollY;

        let startScrollOffset = divOriginalArticles.getBoundingClientRect().top;

        if (startScrollOffset <= 0) {

            let maxScroll = divOriginalArticles.getBoundingClientRect().height - divDiploSummary.getBoundingClientRect().height;
            // let top = divOriginalArticles.getBoundingClientRect().height - 700 < scroll ? divOriginalArticles.getBoundingClientRect().height - 700 : scroll;
            let top = maxScroll < scroll ? maxScroll : scroll;

            divDiploSummary.style.top = top + 100 + "px";
        }
        else {
            divDiploSummary.style.top = "auto";
        }

    };

    componentDidUpdate() {
        if (this.state.articles != null) {

            let originalArticles = document.getElementsByClassName("original-article-content");

            if (originalArticles.length == 0) return;

            for (let i = 0; i < originalArticles.length; i++) {
                for (let j = 0; j < originalArticles[i].children.length; j++) {
                    // originalArticles[i].children[j].addEventListener("dblclick", this.addToSummarisationContent);
                    originalArticles[i].children[j].addEventListener("click", this.addToSummarisationContent);
                }
            }

        }
    }

    setContentForSummarisation(text) {
        this.setState({
            contentForSummarisation: text
        });
    }

    addToContentForSummarisation = (text) => {
        this.setState({
            contentForSummarisation: this.state.contentForSummarisation + text
        });
    }

    addToSummarisationContent = (event) => {
        event.preventDefault();

        this.setState({
            contentForSummarisation: this.state.contentForSummarisation + event.target.innerText + "\n\n"
        });
    }

    newArticleOnKeyDown = (e) => {
        if (e.keyCode == 13) {
            this.getArticle();
        }
    }

    hideContent = () => {
        this.setState({
            hideContent: !this.state.hideContent
        });
    }

    render() {
        return (
            <>

                <div className='summarizer-options'>
                    {
                        this.state.hideContent
                            ? <Button onClick={this.hideContent} variant="outlined" startIcon={<FullscreenExitIcon />} style={{ marginBottom: "20px" }}>
                                Exit full screen editor
                            </Button>
                            : <Button onClick={this.hideContent} variant="outlined" startIcon={<FullscreenIcon />} style={{ marginBottom: "20px" }}>
                                Full screen editor
                            </Button>
                    }
                </div>

                <div className='content-editor'>

                    <div id="original-articles" className='original-articles' style={this.state.hideContent ? { display: "none" } : { width: "45%" }}>
                        {

                            this.state.articles == null
                                ? <></>
                                : this.state.articles.length == 0
                                    ? <>Loading</>
                                    :
                                    this.state.articles.map(
                                        article => <OriginalArticle article={article} addToContentForSummarisation={this.addToContentForSummarisation} key={article.source} />
                                    )
                        }
                        <>
                            <TextField
                                inputRef={this.state.newArticleField}
                                id="new-article-url-feild"
                                label="Add new article URL"
                                variant="outlined"
                                style={{ width: "100%" }}
                                onKeyDown={this.newArticleOnKeyDown}
                            />
                            {
                                this.state.gettingArticleInProgress
                                    ? <Box
                                        style={{ marginTop: "20px", float: "right" }}
                                    >
                                        <CircularProgress style={{ width: "35px", height: "35px" }} />
                                    </Box>
                                    : <IconButton
                                        aria-label="select"
                                        style={{ float: "right" }}
                                        onClick={this.getArticle}
                                    >
                                        <AddRoundedIcon style={{ width: "35px", height: "35px" }} />
                                    </IconButton>
                            }
                        </>
                    </div>

                    <div id="diplo-summary" className='diplo-summary' style={this.state.hideContent ? { width: "100%" } : { width: "45%", position: "absolute" }}>
                        {
                            this.state.articles == null
                                ? <><textarea
                                    value={this.state.contentForSummarisation}
                                    onChange={e => this.setContentForSummarisation(e.target.value)}
                                    placeholder="Add content for summarization"
                                /></>
                                : this.state.articles.length == 0
                                    ? <>Loading</>
                                    : <textarea
                                        disabled={this.state.gettingData}
                                        value={this.state.contentForSummarisation}
                                        onChange={e => this.setContentForSummarisation(e.target.value)}
                                        placeholder="Double-click on paragraphs to add them for summarization."
                                    />
                        }
                        <div className='diplo-summary-text-info'>
                            <div className='diplo-summary-text-info-row number-of-characters'>
                                <div>
                                    {this.state.contentForSummarisation.trim().length} characters
                                </div>
                            </div>
                            {/* {
                                this.state.gettingData && */}
                            <>
                                <div className='diplo-summary-text-info-row'>
                                    <div>
                                        Execution Time:
                                    </div>
                                    <div className='diplo-summary-text-info-left'>
                                        {SummarizerService.formatTime(this.state.timeTaken)}
                                    </div>
                                </div>
                                {/* <div className='diplo-summary-text-info-row'>
                                    <div>
                                        Generating Summary - Estimated Time:
                                    </div>
                                    <div>
                                        2 minutes 31 seconds
                                    </div>
                                </div> */}
                            </>
                            {/* } */}
                        </div>
                        <hr />
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            <Button
                                disabled={this.state.gettingData}
                                variant="contained"
                                endIcon={<ClearIcon />}
                                onClick={this.clearSummarisation}
                                style={{ backgroundColor: "#00879a", float: "right" }}
                            >
                                Clear
                            </Button>
                            <Button
                                disabled={this.state.gettingData}
                                variant="contained"
                                endIcon={<Autorenew />}
                                onClick={this.startSummarisation}
                                style={{ backgroundColor: "#00879a", float: "right" }}
                            >
                                Summarise
                            </Button>
                            {/* {this.state.gettingData && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: "green",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )} */}


                        </div>
                        {
                            this.state.gettingData &&
                            <div style={{ width: "100%", marginTop: "30px" }}>
                                <LinearProgress width="100%" />
                            </div>
                        }
                        {/* <button type="reset" style={{ float: "right" }} onClick={this.startSummarisation}>Summarise</button> */}
                    </div>
                </div>

                <div id="results-container" className="results-container">
                    {
                        this.state.gettingData == false
                            ? <SummaryResults summary={this.state.summary} />
                            : <></>
                    }
                </div>
            </>
        );
    }
}

export default Summarizer;