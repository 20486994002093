import React from 'react'
import { removeToken } from '../tools/Auth';
import Sidenav from './Sidenav';
import Nav from '../Nav';


function Dashboard({layout,page}) {
  
  

  return (
    <div className='db-main-container-wrap'>
      <Nav/>
      <div className='db-main-container'>
        <Sidenav currentPage={page}/>
        {layout}
      </div>
    </div>
  );
}

export default Dashboard