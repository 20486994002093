import React, { Component, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import iconSet from "../assets/icon-set/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { IconButton, Tooltip } from '@mui/material';
import NewsService from '../services/NewsService';

function NewsPreviewModal(props) {

    const openLinkInNewTab = (link) => {
        window.open(link, '_blank', 'noreferrer');
    }
    const [like,setLike]=useState('')
    // const openUsingVia = (link) => {
    //     NewsService.getNewsSingle(link);
    // }

    const handleLike = () => {
        setLike('like')
        NewsService.sendFeedback(props.openedNewsData.link, 'up');
    }

    const handleDislike = () => {
        setLike('dislike')

        NewsService.sendFeedback(props.openedNewsData.link,'down');
    }

    return (
        <>
            <Modal
                open={props.newsPreviewModalOpen}
                onClose={() => props.closeNewsPreviewModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal" style={{width: "auto"}}>

                    <div className="modal-container">

                        {
                            props.openedNewsData != null && props.openedNewsData != undefined
                                ? <div id='news-page-container'>
                                    <div className='header'>
                                        <h1>
                                            {props.openedNewsData.title}
                                        </h1>
                                        <div className="date">
                                            <p>
                                                {new Date(props.openedNewsData.date).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="actions-bar">

                                        <Tooltip title={<span className="tooltip-text">Like recommendation</span>}>
                                            <IconButton
                                                onClick={() => handleLike()}
                                            >
                                                <IcomoonReact
                                                    // className="advanced-ai-prompting-icon"
                                                    iconSet={iconSet}
                                                    size={32}
                                                    icon="thumbs-up"
                                                    color={like=='like'?'#4db847':'#00879a'}
                                                // onClick={() => this.openTitlePromptingModal()}
                                                />
                                            </IconButton>

                                        </Tooltip>

                                        <Tooltip title={<span className="tooltip-text">Dislike recommendation</span>}>
                                            <IconButton
                                                onClick={() => handleDislike()}
                                            >
                                                <IcomoonReact
                                                    // className="advanced-ai-prompting-icon"
                                                    iconSet={iconSet}
                                                    size={32}
                                                    icon="thumbs-down"
                                                    color={like=='dislike'?'#C85250':'#00879a'}
                                                // onClick={() => this.openTitlePromptingModal()}
                                                />
                                            </IconButton>
                                        </Tooltip>

                                        {/* <Tooltip title={<span className="tooltip-text">Assign to</span>}>
                                            <IconButton
                                                onClick={() => this.setState({ newsContentEditorOn: !this.state.newsContentEditorOn })}
                                            >
                                                <IcomoonReact
                                                    // className="advanced-ai-prompting-icon"
                                                    iconSet={iconSet}
                                                    size={32}
                                                    icon="assign-user"
                                                // onClick={() => this.openTitlePromptingModal()}
                                                />
                                            </IconButton>
                                        </Tooltip> */}

                                        <Tooltip title={<span className="tooltip-text">Read original news</span>}>
                                            <IconButton onClick={() => openLinkInNewTab(props.openedNewsData.link)}>
                                                <IcomoonReact
                                                    className='news-preview-icon'
                                                    iconSet={iconSet}
                                                    size={32}
                                                    icon="read-news"
                                                />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={<span className="tooltip-text">Read and annotate</span>}>
                                            <IconButton onClick={() => openLinkInNewTab(process.env.REACT_APP_URL_TEST + "/ai-writer/news?original=" + props.openedNewsData.link)}>
                                                <IcomoonReact
                                                    className='news-preview-icon'
                                                    iconSet={iconSet}
                                                    size={32}
                                                    icon="hypothesis"
                                                />
                                            </IconButton>
                                        </Tooltip>

                                    </div>

                                    <div>
                                        <img src={props.openedNewsData.thumbnail} />
                                    </div>

                                    <div className='content'
                                        dangerouslySetInnerHTML={{ __html: props.openedNewsData.text.split('\n\n').map(s => `<p>${s}</p>`).join('') }}>
                                    </div>

                                    <a className="original-source" href="#" onClick={() => openLinkInNewTab(props.openedNewsData.link)}>
                                        Original source at: {props.openedNewsData.source}
                                    </a>
                                </div>
                                : <>
                                    <h3>Loading data from...</h3>
                                </>
                        }

                    </div>

                </Box>
            </Modal>
        </>
    );

}

export default NewsPreviewModal;