import React,{useState,useEffect} from 'react'
import Notifications from './Notifications'
import NotiSingle from './NotiSingle';
import axios from 'axios';
import {fetchDecodedData} from '../tools/Auth'

function Inbox() {
  const [list,setList]=useState([])

  useEffect(() => {
      
    axios.get(process.env.REACT_APP_BACKEND_URL+'/notify/all/'+fetchDecodedData().email).then(res=>{
      var layout=[]
      const response=res.data;
      var oldDate=''
      for(var i=0;i<response.length;i++){
          let date = new Date(response[i]['created']);
          if (oldDate!=date.toLocaleDateString("en-GB")) {
            
            oldDate=date.toLocaleDateString("en-GB")

            layout.push(<h4>{date.toLocaleDateString("en-GB")}</h4>)
            layout.push(<hr/>)
          }
          layout.push(<NotiSingle title={response[i]['title']} text={response[i]['text']} time={date.toLocaleString("en-GB")} />)
      }
      setList(layout)

  }).catch(err=>console.log(err))
  

    return () => {}
  }, [])

  return (
    
    <div className='inbox'>
      <h2>Inbox</h2>
      <hr />
      <div className='inbox-list'>
      
      {list.length>0?list.map(e=>{return e}):'No notifications'}
      </div>

    </div>
    
  )
}

export default Inbox