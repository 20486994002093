import React, { Component } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import DiploWebsitesService from '../services/DiploWebsitesService';
// import SendToWebsiteModal from './SendToWebsiteModal';
import SendToWebsiteModal from './SendToWebsite/SendToWebsiteModal';
import { TextField } from '@mui/material';
import { maxWidth } from '@mui/system';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ImagesModal from './ImagesModal';
import SendToWebsiteModalSTARI from './SendToWebsiteModalSTARI';

//Loading custom icons made by icomoon.io
import iconSet from "../assets/icon-set/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import TitlePromptingModal from './SummaryResults/TitlePromptingModal';

class SummaryResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            summary: props.summary,
            gettingDataStarted: props.gettingDataStarted,
            publishStatus: "",
            postType: "",
            webSitesData: null,
            relateNewsExcerpt: true,
            imagesPage: 0,
            generatedTitle: props.summary.text_title,
            generatedSummary: props.summary.summary,
            generatedExcerpt: props.summary.excerpt,
            generatedAnalysis: props.summary.analysis,
            // selectedImages: ['https://images.unsplash.com/photo-1579567761406-4684ee0c75b6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MjY0MzN8MHwxfHNlYXJjaHwxfHxjeWJlcnxlbnwwfHx8fDE2ODM3OTk2Njl8MA&ixlib=rb-4.0.3&q=80&w=1080','https://images.unsplash.com/photo-1550751827-4bd374c3f58b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0MjY0MzN8MHwxfHNlYXJjaHwyfHxjeWJlcnxlbnwwfHx8fDE2ODM3OTk2Njl8MA&ixlib=rb-4.0.3&q=80&w=1080'],
            selectedImages: [],
            featuredImage: null,
            postImages: [],

            titlePromptingModalOpen: false,
        }

    }

    componentDidMount() {
        this.setState({
            webSitesData: {
                "postTypes": DiploWebsitesService.getPostTypes(),
                "availablePostStatuses": DiploWebsitesService.getAvailablePostStatuses(),
                "topics": DiploWebsitesService.getTopics(),

            }
        });
    }

    setGeneratedSummary = (text) => {
        this.setState({
            generatedSummary: text
        });
    }

    getGeneratedSummary = () => {
        return this.state.generatedSummary;
    }

    setGeneratedExcerpt = (text) => {
        this.setState({
            generatedExcerpt: text
        });
    }

    getGeneratedExcerpt = () => {
        return this.state.generatedExcerpt;
    }

    setGeneratedTitle = (text) => {
        this.setState({
            generatedTitle: text
        });
    }

    getGeneratedTitle = () => {
        return this.state.generatedTitle;
    }

    changeRelatedNewsDisplay = (event) => {
        this.setState({
            relateNewsExcerpt: !this.state.relateNewsExcerpt
        })
    }

    getRecomendedTopics = () => {
        return this.state.summary.topics;
    }

    setSelectedImages = (images) => {
        this.setState({
            selectedImages: images
        });
    }

    getSelectedImages = () => {
        return this.state.selectedImages;
    }

    setFeaturedImageLink = (image) => {
        this.setState({
            featuredImage: {
                "url": image
            }
        });
    }

    //Meta data can be either "description", "title" or "alt_text"
    setFeaturedImageMetaData = (data, metaDataType) => {
        if (metaDataType === "description" || metaDataType === "title" || metaDataType === "alt_text") {
            let featuredImage = this.state.featuredImage;
            featuredImage[metaDataType] = data;
            this.setState({
                featuredImage: featuredImage
            });
            return;
        }

        return false;
    }

    getFeaturedImage = () => {
        return this.state.featuredImage;
    }

    addPostImage = (image) => {
        let postImages = this.state.postImages;
        postImages.push(image);
        this.setState({
            postImages: postImages
        });
    }

    removePostImage = (image) => {
        let postImages = this.state.postImages;
        let index = postImages.indexOf(image);
        if (index > -1) {
            postImages.splice(index, 1);
        }

        this.setState({
            postImages: postImages
        });
    }

    getPostImages = () => {
        return this.state.postImages;
    }

    handlePostImageChange = (element) => {
        let postImages = this.state.postImages;
        let index = postImages.indexOf(element);
        if (index > -1) {
            postImages.splice(index, 1);
        } else {
            postImages.push(element);
        }
    }

    renderAnalysisTitle = (key) => {
        switch (key) {
            case "core_argument":
                return "Core argument"
            case "major_arguments":
                return "Major arguments"
            case "conclusions":
                return "Conclusions"
            case "evidences":
                return "Evidences"
            case "claims":
                return "Claims"
            case "reasons":
                return "Reasons"
            case "supporting_facts":
                return "Supporting facts"
            case "proposals":
                return "Proposals"
            case "stances":
                return "Stances"
            default:
                return "Unknown"
        }
    }

    openTitlePromptingModal = () => {
        this.setState({
            titlePromptingModalOpen: true
        });
    }

    closeTitlePromptingModal = () => {
        this.setState({
            titlePromptingModalOpen: false
        });
    }

    render() {
        return (
            <>
                <div className='results-header'>
                    <h2>
                        Summary results
                    </h2>
                </div>
                <div className='results-body'>
                    <div className='results-main'>

                        {/* Title */}
                        <div className='title-container card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Title:
                                </h2>
                            </div>
                            <hr />

                            {/* <div className="results-generated-titles">
                                <p>
                                    <b>Regular title:</b> <span onClick={(e) => this.setGeneratedTitle(this.props.summary.text_title)}>{this.props.summary.text_title}</span>
                                </p>
                                <p>
                                    <b>Social media title:</b> <span onClick={(e) => this.setGeneratedTitle(this.state.summary.social_media_title)}>{this.state.summary.social_media_title}</span>
                                </p>
                            </div> */}

                            <div className="generated-title">
                                {
                                    this.state.summary != undefined
                                        ? <TextField
                                            id="standard-basic"
                                            label="Post title"
                                            variant="outlined"
                                            value={this.state.generatedTitle}
                                            onChange={(e) => {
                                                this.setGeneratedTitle(e.target.value);
                                            }}
                                            style={{ width: "100%" }}
                                        />
                                        : <div className="skeleton-loading">

                                        </div>
                                }
                            </div>

                            {/* <div className="advanced-ai-prompting">
                                <IcomoonReact
                                    className="advanced-ai-prompting-icon"
                                    iconSet={iconSet}
                                    color="#2d0000"
                                    size={42}
                                    icon="ai-analyse"
                                    onClick={() => this.openTitlePromptingModal()}
                                />
                            </div> */}

                        </div>

                        <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Social media:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-generated-keypoints-body">
                                {
                                    this.props.summary.social_media != undefined
                                        ? <textarea
                                            id='generated-results-2'
                                            value={this.props.summary.social_media}
                                        />
                                        : <div className="skeleton-loading">

                                        </div>
                                }
                            </div>
                        </div>

                        {/* Generated Summary */}
                        <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Generated Summary:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-generated-keypoints-body">
                                {
                                    this.state.summary != undefined
                                        ? <textarea
                                            id='generated-results'
                                            value={this.state.generatedSummary}
                                            onChange={e => this.setGeneratedSummary(e.target.value)}
                                        />
                                        : <div className="skeleton-loading">

                                        </div>
                                }
                            </div>
                        </div>

                        {/* Generated Excerpt */}
                        <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Generated Excerpt:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-generated-keypoints-body">
                                {
                                    this.state.summary != undefined &&
                                    <textarea
                                        id='recommended-excerpt'
                                        value={this.state.generatedExcerpt}
                                        onChange={e => this.setGeneratedExcerpt(e.target.value)}
                                    />
                                }
                            </div>
                        </div>

                        {/* Recommendations */}
                        <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Recommendations:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-generated-keypoints-body">
                                <h4>
                                    Topics:
                                </h4>
                                <div className="topics">
                                    {
                                        this.state.summary != undefined &&
                                        this.state.summary.topics.map(topic =>
                                            <span className='topic'>{topic}</span>
                                        )
                                    }
                                </div>
                                <hr />
                                <h4>
                                    SEO Keywords:
                                </h4>
                                <div className="keywords">
                                    {
                                        this.state.summary.long_tail_keywords != undefined &&
                                        this.state.summary.long_tail_keywords
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Temporary disabled */}
                        {/* <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Selected Images:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-recomended-images">
                                {
                                    this.state.selectedImages.map(element =>
                                        <a href={element} target="_blank">
                                            <img src={element} />
                                            {/* <input 
                                            type="checkbox" 
                                            className="image-checkbox" 
                                            onChange={this.handlePostImageChange(element)}
                                            checked={this.state.postImages.includes(element)}
                                            /> *
                                            <Checkbox
                                                className="image-checkbox"
                                                icon={<BookmarkBorderIcon />}
                                                checkedIcon={<BookmarkIcon />}
                                                checked={this.state.featuredImage != null && this.state.featuredImage.url == element}
                                                onClick={() => { this.setFeaturedImageLink(element) }}
                                            />
                                        </a>
                                    )
                                }
                            </div>
                            <ImagesModal selectedImages={this.state.selectedImages} setSelectedImages={this.setSelectedImages} />
                        </div> */}

                    </div>

                    <div className="results-sidebar">

                        {/* Analysis */}
                        <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Analysis:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-generated-keypoints-body">

                                {
                                    this.state.generatedAnalysis != undefined &&
                                    this.state.generatedAnalysis.split('\n').map(key => {

                                        

                                        // if (key == "ner") return;

                                        // return <>
                                        //     <h4>
                                        //         {this.renderAnalysisTitle(key)}
                                        //     </h4>
                                        //     <ul>
                                        //         {
                                        //             this.state.generatedAnalysis[key].map(argument => <li key={argument}>{argument}</li>)
                                        //         }
                                        //     </ul>
                                        //     <hr />
                                        // </>
                                        return <>{key}<br/></>
                                    }
                                    )
                                }

                            </div>
                        </div>

                        {/* <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    Recomended topics:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-generated-keypoints-body">
                                <ul>
                                    {
                                        this.state.summary != undefined &&
                                        this.state.summary.topics.map(topic => <li>{topic}</li>)
                                    }
                                </ul>
                            </div>
                        </div> */}

                        {/* People interested in text */}
                        <div className='card-container'>
                            <div className="card-container-header">
                                <h2>
                                    People interested in text:
                                </h2>
                            </div>
                            <hr />
                            <div className="results-generated-keypoints-body">
                                <ul>
                                    {
                                        this.state.summary != undefined &&
                                        this.state.summary.experts.map(expert => <li>{expert}</li>)
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

                <div className='send-to-website-container'>
                    <SendToWebsiteModal
                        getGeneratedSummary={this.getGeneratedSummary}
                        getGeneratedExcerpt={this.getGeneratedExcerpt}
                        getGeneratedTitle={this.getGeneratedTitle}
                        getRecomendedTopics={this.getRecomendedTopics}
                        getFeaturedImage={this.getFeaturedImage}
                        setFeaturedImageMetaData={this.setFeaturedImageMetaData}
                        getSelectedImages={this.getSelectedImages}
                        originalAIGeneratedData={this.props.summary}
                    />
                    {/* <SendToWebsiteModalSTARI
                        getGeneratedSummary={this.getGeneratedSummary}
                        getGeneratedExcerpt={this.getGeneratedExcerpt}
                        getGeneratedTitle={this.getGeneratedTitle}
                        getRecomendedTopics={this.getRecomendedTopics}
                        getFeaturedImage={this.getFeaturedImage}
                        setFeaturedImageMetaData={this.setFeaturedImageMetaData}
                        getSelectedImages={this.getSelectedImages}
                    /> */}
                </div>

                <div className='related-news-container'>
                    <h2>
                        Related updates
                    </h2>
                    <div className="related-news">
                        {
                            this.state.summary.similar_updates != undefined &&
                            this.state.summary.similar_updates.map(el =>
                                <div className='card-container-transparent' onClick={this.changeRelatedNewsDisplay}>
                                    <a href={el.link} target="_blank">
                                        <h3>
                                            {el.title}
                                        </h3>
                                    </a>

                                    <div className="topics">
                                        {el.topics != undefined &&
                                            el.topics.map((topic) => {
                                                return (
                                                    <span className='topic'>{topic}</span>
                                                );
                                            })}
                                    </div>
                                    <p style={{ cursor: "pointer" }}>
                                        {
                                            this.state.relateNewsExcerpt
                                                ? el.text.substr(0, 150) + " ..."
                                                : el.text
                                        }
                                    </p>
                                </div>)
                        }
                    </div>
                </div>

                {/* Modals */}

                <TitlePromptingModal
                    titlePromptingModalOpen={this.state.titlePromptingModalOpen}
                    closeModal={this.closeTitlePromptingModal}
                    originalAiCreatedTitle={this.props.summary.text_title}
                    setGeneratedTitle={this.setGeneratedTitle}
                    getGeneratedTitle={this.getGeneratedTitle}
                />

            </>
        );
    }
}

export default SummaryResults;