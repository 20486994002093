import { useLocation,Navigate,useParams,useSearchParams } from "react-router-dom"
import Cookies from 'universal-cookie';

import axios from "axios";
import ErrorHandler from "./ErrorHandler";

let cookie = new Cookies();

export const setToken=(token)=>{
    var test=parseJwt(token)
    var date2=new Date(test['maxAge'])
    
    cookie.set('diplo', token, { path: '/',expires:date2 });
    
}

export const fetchToken=()=>{
    
    return cookie.get('diplo')
}

export const checkTokenTime=()=>{
    let data=fetchDecodedData()
    if(data){
        let toketdate=new Date(data['maxAge'])
        let timeNow=new Date().toISOString().split('T')[0];
    
        if(toketdate<timeNow){
            return false;
    
        }else{
            return true;
        }
    }
    return true;
}

export const fetchDecodedData=()=>{

    var data=cookie.get('diplo')
    if(data){
        return parseJwt(data)

    }
    return null
}

export const removeToken=()=>{

    cookie.remove('diplo', { path: '/' })

}

export function ViaVali({children,...props}){
    let auth = fetchToken()
    let location = useLocation()
    let ViaToken=location.search.split('?key=')[1]

    if(!auth&&!ViaToken){

        return <Navigate to='/login' state ={{from : location}}/>;
    }
    if(!auth){
        setToken(ViaToken)

    }
    return children;
}

export function RequireToken({children}){

    let auth = fetchToken()
    let location = useLocation()
    if(!auth){

        return <Navigate to='/login' state ={{from : location}}/>;
    }
    
    return children;
}

export function CheckIfToken({children,getError,error,setAuth,session_auth}){
    const [searchParams, setSearchParams] = useSearchParams();
    var auth = fetchToken()
    let location = useLocation()
    let {id}=useParams();

    if(!auth&&id&&!getError){

        
        axios.get(process.env.REACT_APP_BACKEND_URL+'/validate/'+id)
        .then((response)=>{
            if(response.data.status_code){
                error({'msg':response.data.detail,'code':response.data.status_code})
                return 
            }
            setToken(response.data)

            error('')
            window.location.reload(false);
        }).catch((err)=>{
            error({'msg':'error','code':err.status})
            
        })
        
    }

    if(fetchToken()){
        
        return <Navigate to='/' state ={{from : location}}/>;
    }
    

    return children
    


}


function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}