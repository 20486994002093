import React,{useEffect,useState,useRef} from 'react'

function Sidenav({currentPage}) {
  
 
  useEffect(()=>{
    let nav=document.getElementsByClassName('side-items')
    
    for(var i=0;i<nav.length;i++){
      var selected=nav[i].querySelector("a")
      console.log(currentPage);
      if(selected.href.includes(currentPage)){
        nav[i].classList.add('active')
        break;
      }
    }
  },[])
  return (
    <div className="nav nav-side">
      <div className="side-menu">
        <div className="side-items"  data-id='0'><a href="/">Dashboard</a></div>
        <div className="side-items"  data-id='1'><a href="/apps">All apps</a></div>
        <div className="side-items"  data-id='2'><a href="/inbox">Inbox</a></div>
      </div>
      <div className="side-bottom-txt">
        
      </div>
    </div>
  )
}

export default Sidenav