import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

import DiploWebsitesService from '../services/DiploWebsitesService';
import NewsService from '../services/NewsService';
import UserService from '../../../tools/UserService';
import { fetchDecodedData } from '../../../tools/Auth';
class AssignToModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEmail: null,
            diploPeople: props.diploPeople
        }
    }

    componentDidMount() {
        // this.setState({
        //     diploPeople: DiploWebsitesService.getDiploPeople()
        // })
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleListItemClick = (value) => {
        NewsService.assignTo(this.props.newsLink, value)
            .then((res) => {
                alert("Successfully assigned to " + value + "!");
                this.props.changeAssignedUser(DiploWebsitesService.getDiploUser(this.props.diploPeople,value));

                // sending notifications (email,app_id,payload,title)
                UserService.setNotifyUser(value,0,'The user: '+fetchDecodedData().email+' has assinged you an article: [['+process.env.REACT_APP_URL+'/ai-writer/summarizer?article1='+this.props.newsLink+']]','Ai-writer')
                this.props.onClose();
            })
            .catch((error) => {
                console.error(error);
                alert("Error assigning article to " + value + "!");
            });
    };

    render() {
        return (
            <Dialog onClose={this.handleClose} open={this.props.open}>
                <DialogTitle>Assign to</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {this.state.diploPeople &&
                        this.state.diploPeople.map((user) => (
                            <ListItem disableGutters>
                                <ListItemButton onClick={() => this.handleListItemClick(user.email)} key={user.email}>
                                    <ListItemAvatar>
                                        <Avatar alt={user.name + user.lastName} src={''} />
                                    </ListItemAvatar>
                                    <ListItemText primary={user.name + " " + user.lastName} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                </List>
            </Dialog>
        );
    }
}

export default AssignToModal;