import json from "../../apps.json"

export function setLastcliked(click) {
    if(!localStorage.getItem('last_used')){
        localStorage.setItem('last_used','[]')
    }
    var data=JSON.parse(localStorage.getItem('last_used'))||[]
    console.log(data.find(e => e.id === click));
    if (data.length<3&&!data.find(e => e.id === click)) {

        data.push(json['All'][click])
    }else if(data.find(e => e.id === click)){
        var id=-1
        
        for (let index = 0; index < data.length; index++) {
            if(data[index].id===click){
                id=index
            }
            
        }
        data.splice(id, 1)
        data.unshift(json['All'][click])
    }else{
        data.unshift(json['All'][click])
    }
    localStorage.setItem('last_used',JSON.stringify(data))
    return
}

export function getLastcliked(){
    if(!localStorage.getItem('last_used')){
        

        localStorage.setItem('last_used','[]')
        var rs=localStorage.getItem('last_used')

        console.log(rs);
    }
    
    var data=JSON.parse(localStorage.getItem('last_used'))||[]
    
    
    return data
}