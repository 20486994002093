import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewsService from '../services/NewsService';
import '../assets/css/news-page.css';

import { Helmet } from "react-helmet";

function NewsPage(props) {

    const [newsData, setnewsData] = useState(null);
    const [link, setLink] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setLink(params.get("original"));

        NewsService.getNewsSingleNoEmail(params.get("original"))
            .then((res) => {
                setnewsData(res.data);
                loadTextus();
            })
            .catch((error) => {
                console.error(error);
                alert("Error getting news content.");
            });

    }, []);

    const openLinkinNewTab = (link) => {
        window.open(link, '_blank', 'noreferrer');
    }

    /**
     * Ucitava sve textus elemente
     */
    async function loadTextus() {

        // handleLoadingStyle = setInterval(loadTextusStyle, 100);

        var iframe = document.createElement('iframe');
        iframe.setAttribute("id", "hServerIframe");
        iframe.src = 'https://h.diplomacy.edu/login';
        iframe.width = "1";
        iframe.height = "1";
        document.body.appendChild(iframe);

        console.log("==================================");
        console.log("UCITAVAAA");
        console.log(iframe);

        // if (courseName.getAttribute("isPostChatTranscript") == 1) {
        //     await waitUntil(() => document.getElementsByClassName("wcLoading")[0] == undefined);
        // }

        var script = document.createElement("script");
        script.src = "https://h.diplomacy.edu/embed.js";
        script.setAttribute("id", "hServerScript");
        document.head.appendChild(script);


        // if(courseName.getAttribute("isPostChatTranscript") == 1){
        // 	console.log("ovo je chat transcript");

        // 	// Ako nema elementa wcLoading to znaci da je gotovo ucitavanje wise chat-a i mozemo ucitati textus
        // 	var handle = setInterval(() => {
        // 		console.log("POZIV");
        // 		if( document.getElementsByClassName("wcLoading")[0] == undefined){
        // 			console.log("LOADING GOTOV UCITAVA TEXTUS");
        // 			console.log(script);
        // 			document.head.appendChild(script);
        // 			clearInterval(handle);
        // 		}
        // 	}, 100);

        // }else{
        // 	document.head.appendChild(script);
        // }

    }

    /**
     * Ucitava CSS za Textus elemente
     * regularStyle se uvek ucitava
     * loginReminderStlye se ucitava kada student nije ulogovan i kada ga treba podsetiti da se uloguje (animacija)
     */
    function loadTextusStyle() {

        var hypothesis = document.getElementsByTagName("hypothesis-sidebar")[0];

        console.log("======================")
        console.log("novi ispis")

        if (hypothesis != undefined) {

            console.log("usao u if")
            console.log(hypothesis)

            var myShadowDom = hypothesis.shadowRoot;
            let style = document.createElement('style');


            /**
             * Stari stil odgovarao je prosloj verziji textus-a
             */
            // let regularStyle = `

            // 	.annotator-toolbar__sidebar-toggle{
            // 		background-color: #F7AF2C!important;
            // 		border-color: #F7AF2C!important;
            // 		height: 80px!important;
            // 		border-radius: 10px 0 0 10px!important;
            // 		/* animation: blink 1s infinite; */
            // 	}

            // 	@keyframes blink{
            // 		0%		{ background-color: #F7AF2C;}
            // 		100%	{ background-color: #FFFFFF;}
            // 	}

            // 	.annotator-toolbar__sidebar-toggle .svg-icon svg {
            // 		color: #3E3D42;
            // 	}

            // 	button.annotator-toolbar-button {
            // 		display: none!important;
            // 	}

            // 	.annotator-toolbar__sidebar-toggle {
            // 		-webkit-animation: goUp 1.8s;
            // 		-webkit-animation-fill-mode: forwards;
            // 	}

            // 	@-webkit-keyframes goUp {
            // 		0% {
            // 			transform: translate(0px, 30vh);
            // 		}
            // 		100%{
            // 			transform: translate(0px, 0vh); 
            // 		}
            // 	}

            // `;

            let regularStyle = `
	
				.text-grey-5{
					background-color: #F7AF2C!important;
					border-color: #F7AF2C!important;
					height: 80px!important;
					color: #3c3c3c;
					border-radius: 10px 0 0 10px!important;
					/* animation: blink 1s infinite; */
				}

				.text-grey-5:hover{
					color: #3E3D42;
				}

				@keyframes blink{
					0%		{ background-color: #F7AF2C;}
					100%	{ background-color: #FFFFFF;}
				}
	
				.text-grey-5 .svg-icon svg {
					color: #3E3D42;
				}
	
				.text-grey-6 {
					display: none;
				}
	
				.text-grey-5 {
					-webkit-animation: goUp 1.8s;
					-webkit-animation-fill-mode: forwards;
				}

				@-webkit-keyframes goUp {
					0% {
						transform: translate(0px, 30vh);
					}
					100%{
						transform: translate(0px, 0vh); 
					}
				}

			`;

            // let loginReminderStyle = `

            // .annotator-toolbar__sidebar-toggle {
            // 	-webkit-animation: bounce 1.2s infinite;
            // }

            // .annotator-frame .annotator-toolbar {
            // 	top: 30vh;
            // }

            // @-webkit-keyframes bounce {
            // 	0% {
            // 		transform: scale(1,1) translate(0px, 0px);
            // 	}

            // 	30%{
            // 		transform: scale(1,0.8) translate(0px, 10px); 
            // 	}

            // 	75%{
            // 		transform: scale(1,1.1) translate(0px, -25px); 
            // 	}

            // 	100% {
            // 		transform: scale(1,1) translate(0px, 0px);
            // 	}
            // }

            // `;

            let loginReminderStyle = `
				
			.text-grey-5 {
				-webkit-animation: bounce 1.2s infinite;
			}
	
			.text-px-base {
				top: 30vh;
			}
	
			@-webkit-keyframes bounce {
				0% {
					transform: scale(1,1) translate(0px, 0px);
				}
				
				30%{
					transform: scale(1,0.8) translate(0px, 10px); 
				}
				
				75%{
					transform: scale(1,1.1) translate(0px, -25px); 
				}
				
				100% {
					transform: scale(1,1) translate(0px, 0px);
				}
			}
	
			`

            myShadowDom.appendChild(style);
            // clearInterval(handleLoadingStyle);
        }

    }

    return (
        <>
            {console.log("NEWS PAGE novi 3")}
            {
                newsData != null
                    ?
                    <>
                        <Helmet>
                            <title>{newsData.title} | Diplo AI Admin</title>
                        </Helmet>
                        <div id='news-page-container'>
                            <div className='header'>
                                <a href="#" onClick={() => openLinkinNewTab(newsData.link)}>
                                    <h1>
                                        {newsData.title}
                                    </h1>
                                </a>
                                <div className="date">
                                    <p>
                                        {new Date(newsData.date).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}
                                    </p>
                                </div>
                            </div>

                            <div>
                                <img src={newsData.thumbnail} />
                            </div>

                            <div className='content'
                                dangerouslySetInnerHTML={{ __html: newsData.text.split('\n\n').map(s => `<p>${s}</p>`).join('') }}>
                            </div>

                            <a className="original-source" href="#" onClick={() => openLinkinNewTab(newsData.link)}>
                                Original source at: {newsData.source}
                            </a>
                        </div>
                    </>
                    : <>
                        <h3>Loading data from...</h3>
                    </>
            }
        </>
    );
}

export default NewsPage;