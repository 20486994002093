import React from 'react';
import "../assets/css/ai-writer-home-page.css"
import iconSet from "../assets/icon-set/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { Navigate, useLocation } from 'react-router-dom';
import { BRIEFING_PAGE_ROUTE, BLANK_WORKSPACE_PAGE_ROUTE } from '../../utils/routes';
import { useNavigation } from '../hooks/useNavigation';

function AIWriterHomePage() {

    const { navigateTo } = useNavigation();

    return (
        <div id="ai-writer-home-page-container">
            <div className="ai-writer-home-page-header">
                <h1>Please select how would you like to proceed</h1>
            </div>
            <div className="ai-writer-home-page-body">
                <div className='button-card' style={{ background: "#c75c5c" }}>
                    <IcomoonReact
                        iconSet={iconSet}
                        size={216}
                        icon="news"
                        onClick={() => navigateTo(BRIEFING_PAGE_ROUTE)}
                    />
                    <h2>Our pick</h2>
                </div>
                <div className='button-card' style={{ background: "#77b3d4" }}>
                    <IcomoonReact
                        iconSet={iconSet}
                        size={216}
                        icon="pencil"
                        onClick={() => navigateTo(BLANK_WORKSPACE_PAGE_ROUTE)}
                    />
                    <h2>Free form</h2>
                </div>
            </div>
        </div>
    );
}

export default AIWriterHomePage;