import React,{useState,useEffect,useRef} from 'react'
import { removeToken,fetchDecodedData,fetchToken } from './tools/Auth';
import { useNavigate } from "react-router";
import diploAi from"../images/diplo_ai3.png"
import axios from 'axios';
import Notifications from './admin/Notifications';
function Nav() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [list,setList]=useState('')

  const menu=useRef('')

  const handleOpen = () => {
    setOpen(!open);
  };

  const signOut = () => {


    var data=fetchToken()
   
    axios.get(process.env.REACT_APP_BACKEND_URL+'/logout/'+data).then(response=>{
      return response
    }).then(response=>{
      removeToken();
      navigate("/login");

    }).catch(error=>{
      removeToken();
      navigate("/login");
    })
  };

  useEffect(() => {
   var user=fetchDecodedData()
   setUser(user)
  },[])


  function handleClickOutside(event) {
    const btn=document.getElementById('dropdown-prof')
    if (menu.current && !menu.current.contains(event.target)&&!btn.contains(event.target)) {
      setOpen(false);
    }
  }

useEffect(() => {

    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
}, [menu])

  return (
    <div className='nav nav-top'>
      <a className="nav-icon" href='/'><img src={diploAi} alt="" srcSet="" /></a>
      <div className="nav-centar"></div>
      <div className="nav-user">
        <Notifications setList={setList} list={list}/>
        <span className='prof-name'>
          {user.name+' '+user.lastName}
        </span> 
        <div className="prof-img no-picture" id='dropdown-prof' onClick={()=>handleOpen()}></div>
        {open ? (
        <div className="nav-drop" ref={menu}>
          
          <ul className="menu">
            <li className="menu-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="menu-item">
              <a href='/inbox'>Inbox</a>
            </li>
            <li className="menu-item">
              <a href='/settings'>Settings</a>
            </li>
            <li className="menu-item">
              <a href='#' onClick={signOut}>Logout</a>
            </li>
          </ul>
        </div>
        ) : null}
      </div>
    </div>
  )
}

export default Nav