import React from 'react'
import MapIcon from '@mui/icons-material/Map';
function Index() {
  return (
    <div className='student-stat-main'>
      <h1><strong>Student Statistics</strong></h1>
      <div className='btn-wrap-form'>
      <a className='btn' href='/table-statistics'><img className='stud-img' src={`/img/stat.svg`} alt="Ikonica1"/><span className="btn-grid-button_text">Table Statistics</span></a>
      <a className='btn' href='/map-statistics'><img className='stud-img2' src={`/img/globe.svg`} alt="Ikonica1"/><span className="btn-grid-button_text">Map Statistics</span></a>

      </div>
    </div>
  )
}

export default Index