import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Button from '@mui/joy/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useEffect,useState } from 'react';
import axios from 'axios';

function Filter(props) {
  const [download,addDownload]=useState([])
  const [loading,setLoading]=useState(true)
  const [classes,setClasses]=useState(false)
  const [year,setYear]=useState(false)
  const [loc,setLoc]=useState(false)
  const [nat,setNat]=useState(false)
  const [sect,setSect]=useState(false)


  const [is_loaded,setIsLoaded]=useState(false)

  useEffect(() => {
    
    axios.get(process.env.REACT_APP_STUDENT_API+'/students/classes').then((data) =>{

      setClasses(data.data['class'])
      setYear(data.data['time'].reverse())
    }).then(()=>{setIsLoaded(0)})

    axios.get(process.env.REACT_APP_STUDENT_API+'/students/locations').then((data) =>{

      setLoc(data.data['loc'])
      setNat(data.data['nat'])
      console.log(data.data)
    }).then(()=>{setIsLoaded(1)})
    
    axios.get(process.env.REACT_APP_STUDENT_API+'/students/sector').then((data) =>{

      setSect(data.data['sector'])

    }).then(()=>{setIsLoaded(2)})
  }, [])
  

  useEffect(() => {

    if (classes &&year&&loc&&sect){
      setLoading(false)
    }
    
    
  }, [is_loaded])


  

  return (<>
    <form className='fliter-wrap' onSubmit={(e)=>props.search(e)}>
    { loading?<Skeleton variant="rectangular" width={"100%"} height={250}/>:
      <>
    
    <div id='filter-main' >
        <FormControl>
          <FormLabel>Name: (Three char. minimum)</FormLabel>
          <Input className='search-stat' placeholder="Name" name='name' onChange={(e)=>{props.setName(e.target.value)}}/>
        </FormControl>
        <FormControl>
          <FormLabel>Last name: (Three char. minimum)</FormLabel>
          <Input className='search-stat' placeholder="Last Name" name='lastName' onChange={(e)=>{props.setlastname(e.target.value)}}/>
        </FormControl>
        <FormControl sx={{ width: 430 }}>
          <FormLabel>Course:</FormLabel>
          <Select defaultValue="All" name='course' onChange={(e,obj)=>{props.setCourse(obj)}}>
          <Option value={'All'}>All</Option>
            {Object.keys(classes['name']).map((key)=>{
              return <Option value={classes['group_id'][key]}>{classes['name'][key]}</Option>
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 120 }} >
          <FormLabel>Year:</FormLabel>
          <Select defaultValue="All" name='year' onChange={(e,obj)=>{props.setYear(obj)}}>
          <Option value={'All'}>All</Option>
          {year.map((e)=>{
            return <Option value={e}>{e}</Option>
          })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 220 }}>
          <FormLabel>Country residence:</FormLabel>
          <Select defaultValue="All" name='location' onChange={(e,obj)=>{props.setLoc(obj)}}>
          <Option value={'All'}>All</Option>
          {Object.keys(loc).map((e,val)=>{
            return <Option value={loc[e]}>{loc[e]}</Option>
          })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 220 }}>
          <FormLabel>Nationality:</FormLabel>
          <Select defaultValue="All" name='nationality' onChange={(e,obj)=>{props.setNat(obj)}}>
          <Option value={'All'}>All</Option>
          {Object.keys(nat).map((e,val)=>{
            return <Option value={nat[e]}>{nat[e]}</Option>
          })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 220 }}>
          <FormLabel>Sector:</FormLabel>
          <Select defaultValue="All" name='sector' onChange={(e,obj)=>{props.setSect(obj)}}>
          <Option value={'All'}>All</Option>
          {Object.keys(sect).map((e)=>{
            return <Option value={e}>{sect[e]}</Option>
          })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 120 }}>
          <FormLabel>Gender:</FormLabel>
          <Select defaultValue="Any" name='gender' onChange={(e,obj)=>{props.setGender(obj)}}>
            <Option value='Any'>Any</Option>
            <Option value='Male'>Male</Option>
            <Option value='Female'>Female</Option>
            <Option value='Undisclosed'>Undisclosed</Option>

          </Select>
        </FormControl>
        <FormControlLabel control={<Checkbox  onChange={(e)=>{props.setAlumni(e.target.checked)}}/>} label="Alumni" />
        
    </div>
    <div className='stu-wrap'>
          <Button type='submit'>Send</Button>
    </div>
        </>
        }

    </form>
    {props.hideDownload?
    
      <div style={{'padding':'20px','height':'70px'}}>
        <Button className='st-download' onClick={props.download}>Download</Button>

      </div>
    
    :''

    }
    
  </>
  )
}

export default Filter