// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-news-search{
    display: flex;
    flex-basis: 100%;
    background-color: #fff;
    box-shadow: 0 0 13px 0 rgba(34, 62, 153, .1);
    border-radius: 10px;
    padding: 5px;
}

.ai-recommended-news-search-bar {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ai-days-filter-select{
    background-color: #fff;
    box-shadow: 0 0 13px 0 rgba(34, 62, 153, .1);
    border-radius: 10px;
    margin: 0 0 0 10px!important;
}


.briefing-sidebar-container .option-buttons{
    height: 56px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.assigned-people {
    flex-basis: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/apps/ai-writer/assets/css/briefing-page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,4CAA4C;IAC5C,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,4CAA4C;IAC5C,mBAAmB;IACnB,4BAA4B;AAChC;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".ai-news-search{\n    display: flex;\n    flex-basis: 100%;\n    background-color: #fff;\n    box-shadow: 0 0 13px 0 rgba(34, 62, 153, .1);\n    border-radius: 10px;\n    padding: 5px;\n}\n\n.ai-recommended-news-search-bar {\n    flex-basis: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n.ai-days-filter-select{\n    background-color: #fff;\n    box-shadow: 0 0 13px 0 rgba(34, 62, 153, .1);\n    border-radius: 10px;\n    margin: 0 0 0 10px!important;\n}\n\n\n.briefing-sidebar-container .option-buttons{\n    height: 56px;\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    margin-bottom: 20px;\n}\n\n.assigned-people {\n    flex-basis: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
