import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import iconSet from "../../assets/icon-set/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";

function TitlePromptingModal(props) {

    const pom = [
        "GDPR Unleashed: How Video Analytics Faces a Privacy Revolution in Europe!",
        "Shocking Revelations: How GDPR Turned Video Analytics Upside Down in Europe!",
        "GDPR's Impact on Video Analytics in Europe",
        "The Data Privacy Revolution: Understanding the Profound Impact of the General Data Protection Regulation (GDPR) on Video Analytics and Data Protection in Europe",
    ]

    const [suggestedTitles, setSuggestedTitles] = React.useState([]);

    const [i, setI] = React.useState(0);

    const askPrompt = (inputTitle, promptType) => {

        let newElement = [{ 
            "inputTitle": inputTitle, 
            "promptType": promptType, 
            "suggestedTitle": pom[i] 
        }]

        setSuggestedTitles(suggestedTitles => [...suggestedTitles, ...newElement]);

        setI(i + 1);
    }

    return (
        <>
            <Modal
                open={props.titlePromptingModalOpen}
                onClose={() => props.closeModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal">

                    <div className="modal-container">

                        <div className="modal-container-header">
                            <h2>
                                Title prompting
                            </h2>
                            <hr class="send-to-website-subheading"></hr>
                        </div>

                        <div className="modal-container-body">

                            {/* Original title */}
                            {/* <div className="original-title">

                                <div className="original-title-title">
                                    <IcomoonReact
                                        // className="advanced-ai-prompting-icon"
                                        iconSet={iconSet}
                                        size={20}
                                        icon="letter-t"
                                    // onClick={() => this.openTitlePromptingModal()}
                                    />
                                    <h3>
                                        Original Title
                                    </h3>
                                </div>

                                <div className="suggested-titles-body">
                                    <div className="suggested-title">
                                        <span
                                        className='suggested-title-output'
                                            onClick={() => { props.setGeneratedTitle(props.originalAiCreatedTitle) }}
                                        >
                                            {props.originalAiCreatedTitle}
                                        </span>
                                    </div>
                                </div>
                            </div> */}

                            {/* Suggested titles */}
                            {/* <div className="suggested-titles">

                                <div className="suggested-titles-header">
                                    <IcomoonReact
                                        // className="advanced-ai-prompting-icon"
                                        iconSet={iconSet}
                                        size={20}
                                        icon="light-bulb"
                                    // onClick={() => this.openTitlePromptingModal()}
                                    />
                                    <h3>
                                        Suggested titles
                                    </h3>
                                </div>

                                <div className="suggested-titles-body">
                                    {
                                        suggestedTitles.map((suggestedTitle, index) => {
                                            return (
                                                <>
                                                    <div className="suggested-title">
                                                        <span><b>Input: </b>{suggestedTitle.inputTitle}</span>
                                                        <span><b>Prompt: </b>Make it shorter</span>
                                                        <span className="suggested-title-output" onClick={() => { props.setGeneratedTitle(suggestedTitle.suggestedTitle) }}><b>Output: </b>{suggestedTitle.suggestedTitle}</span>
                                                    </div>
                                                    <hr />
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div> */}

                            {/* Quick prompts */}
                            <div className="quick-prompts">

                                <div className="quick-prompts-header">
                                    <IcomoonReact
                                        // className="advanced-ai-prompting-icon"
                                        iconSet={iconSet}
                                        size={20}
                                        icon="lightning-bolt"
                                    // onClick={() => this.openTitlePromptingModal()}
                                    />
                                    <h3>
                                        Quick prompts
                                    </h3>
                                </div>

                                <div className="quick-prompts-body">
                                    <p style={{marginTop: "0"}}>Selected Title:<b> {props.getGeneratedTitle()}</b></p>
                                </div>

                                <div className="quick-prompts-body">
                                    <div className="prompts" onClick={() => askPrompt(props.getGeneratedTitle(), "makeItShorter")}>
                                        <span>Make it shorter</span>
                                        {/* <span className="prompt-description">Make title shorter so you can make it more effective</span> */}
                                    </div>
                                    <div className="prompts" onClick={() => askPrompt(props.getGeneratedTitle(), "makeItLonger")}>
                                        <span>Make it longer</span>
                                        {/* <span className="prompt-description">Make title shorter so you can make it more effective</span> */}
                                    </div>
                                    <div className="prompts" onClick={() => askPrompt(props.getGeneratedTitle(), "makeItEngaging")}>
                                        <span>Make more engaging</span>
                                        {/* <span className="prompt-description">Make title shorter so you can make it more effective</span> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </Box>
            </Modal>
        </>
    );
}

export default TitlePromptingModal;