import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function AddImageData(props) {

    const [featuredImageTitle, setFeaturedImageTitle] = React.useState("");
    const [featuredImageAltText, setFeaturedImageAltText] = React.useState("");
    const [featuredImageDescription, setFeaturedImageDescription] = React.useState("");

    const hasFeaturedImage = props.getFeaturedImage() != null && props.getFeaturedImage().url != undefined;

    // Disable next button if not all fields are filled
    const [disableNextButton, setDisableNextButton] = React.useState(hasFeaturedImage ? false : true);

    const handleNext = () => {

        if (hasFeaturedImage) {
            props.setImageData({
                featuredImage: {
                    url: props.getFeaturedImage().url,
                    title: featuredImageTitle,
                    altText: featuredImageAltText,
                    description: featuredImageDescription,
                },
            })
        } else {
            props.setImageData({
                featuredImage: null
            })
        }

        props.handleNext();
    };

    const handleTitleChange = (event) => {
        if (event.target.value != "" && featuredImageAltText != "" && featuredImageDescription != "") setDisableNextButton(false);
        else setDisableNextButton(true);

        setFeaturedImageTitle(event.target.value);
        // props.setFeaturedImageMetaData(event.target.value, "title");
    };

    const handleAltTextChange = (event) => {
        if (event.target.value != "" && featuredImageAltText != "" && featuredImageDescription != "") setDisableNextButton(false);
        else setDisableNextButton(true);

        setFeaturedImageAltText(event.target.value);
        // props.setFeaturedImageMetaData(event.target.value, "alt_text");
    };

    const handleDescriptionChange = (event) => {
        if (event.target.value != "" && featuredImageAltText != "" && featuredImageDescription != "") setDisableNextButton(false);
        else setDisableNextButton(true);

        setFeaturedImageDescription(event.target.value);
        // props.setFeaturedImageMetaData(event.target.value, "description");
    };

    return (
        <>
        
            <div className="send-to-website-container add-image-data">
                <div className="send-to-website-header">
                    <h2>
                        Add image data:
                    </h2>
                </div>
                <hr className="send-to-website-subheading" />
                <div className="send-to-website-body">

                    {/* Situacija kada za sada ima samo jednu featured image */}
                    {
                        props.getFeaturedImage() != null && props.getFeaturedImage().url != undefined
                            ? <>
                                <div className='image-container'>
                                    <img src={props.getFeaturedImage().url} />
                                </div>
                                <div className="image-data-container">

                                    <TextField
                                        label="Title"
                                        variant="outlined"
                                        onChange={(event) => {
                                            handleTitleChange(event);
                                        }}
                                        style={{ width: "100%" }}
                                    />

                                    <TextField
                                        label="Alt text"
                                        variant="outlined"
                                        onChange={(event) => {
                                            handleAltTextChange(event);
                                        }}
                                        style={{ width: "100%" }}
                                    />

                                    <TextField
                                        label="Description"
                                        multiline
                                        rows={3}
                                        onChange={(event) => {
                                            handleDescriptionChange(event);
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </>
                            : <>
                                {/* <h2>No selected image</h2> */}
                                <h2>Uploading images is currently unavailable. However, you can still upload images to your WordPress website once you have sent a post.</h2>
                            </>
                    }


                    {/* Situacija kada moze da ima vise selected imagrs */}
                    {/* {props.getSelectedImages().map((image, index) =>
                    <>
                        <div className='image-container' key={index}>
                            <img src={image} alt="" />
                        </div>
                        <div className="image-data-container">
                            <TextField label="Title" variant="outlined" style={{width:"100%"}} />
                            <TextField label="Alt text" variant="outlined" style={{width:"100%"}}/>
                            <TextField
                                label="Description"
                                multiline
                                rows={3}
                                style={{width:"100%"}}
                            />
                        </div>
                    </>

                )} */}
                </div>
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                <Button onClick={props.handleBack}>
                    Back
                </Button>

                <Button onClick={handleNext}>
                    Next
                </Button>

            </Box>

        </>
    );
}

export default AddImageData;