import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SendIcon from '@mui/icons-material/Send';
import { Alert, Step, StepLabel, Stepper } from '@mui/material';
import AddWebsiteData from './Steps/AddWebsiteData';
import AddPostData from './Steps/AddPostData';
import DiploWebsitesService from '../../services/DiploWebsitesService';
import AddImageData from './Steps/AddImageData';
import PostPreview from './Steps/PostPreview';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80vw",
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
};

const steps = ['Select website', 'Add post data', 'Add image data', 'Final check'];

function SendToWebsiteModal(props) {

    const [open, setOpen] = React.useState(false);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const [websiteData, setWebsiteData] = React.useState(null);
    const [postData, setPostData] = React.useState(null);
    const [imageData, setImageData] = React.useState(null);

    // Functions for handling steps

    const isStepOptional = (step) => {
        return false;
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderSwitch = (param) => {
        switch (param) {
            case 0:
                return <AddWebsiteData
                    websiteData={websiteData}
                    setWebsiteData={setWebsiteData}
                    handleNext={handleNext}
                />;
            case 1:
                return <AddPostData
                    websiteData={websiteData}
                    getGeneratedSummary={props.getGeneratedSummary}
                    getRecomendedTopics={props.getRecomendedTopics}
                    setPostData={setPostData}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />;
            case 2:
                return <AddImageData
                    postData={postData}
                    getFeaturedImage={props.getFeaturedImage}
                    setFeaturedImageMetaData={props.setFeaturedImageMetaData}
                    setImageData={setImageData}
                    handleBack={handleBack}
                    handleNext={handleNext}
                />;
            case 3:
                return <PostPreview
                    websiteData={websiteData}
                    postData={postData}
                    imageData={imageData}

                    getGeneratedSummary={props.getGeneratedSummary}
                    getGeneratedExcerpt={props.getGeneratedExcerpt}
                    getGeneratedTitle={props.getGeneratedTitle}

                    originalAIGeneratedData={props.originalAIGeneratedData}

                    handleBack={handleBack}
                    handleNext={handleNext}
                />;
            case 4:
                return <Alert severity="success">Post successfully sent to website!</Alert>;
            default:
                return false;
        }
    };

    return (
        <>
            <>
                {/* <Button variant="contained" onClick={this.handleOpen}>Send to websites</Button> */}
                <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={() => setOpen(true)}
                    style={{ backgroundColor: "#00879a", float: "right" }}
                >
                    Send to websites
                </Button>

                <Modal
                    open={open}
                    onClose={() => { setActiveStep(0); setOpen(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='send-to-website-modal'
                >
                    <Box sx={style}>

                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepOptional(index)) {
                                        labelProps.optional = (
                                            <Typography variant="caption">Optional</Typography>
                                        );
                                    }
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>

                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>

                                    {
                                        renderSwitch(activeStep)
                                    }

                                </React.Fragment>
                            )}
                        </Box>

                        {/* <Button variant="contained" endIcon={<SendIcon />} style={{ backgroundColor: "#00879a" }} onClick={sendToWebSite}>
                        Send
                    </Button> */}

                    </Box>
                </Modal>
            </>
        </>
    );
}

export default SendToWebsiteModal;