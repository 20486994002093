import React from 'react';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LinkIcon from '@mui/icons-material/Link';

import '../assets/css/hemingway-main.css';
import '../../../../assets/css/main-apps.css';
import { Button, IconButton } from '@mui/material';
import HemingwayEditor from '../components/HemingwayEditor.jsx';
import RightContextMenu from '../components/RightContextMenu';
import TextAnalysisSidebar from '../components/TextAnalysisSidebar';
import SuggestionsSidebar from '../components/SuggestionsSidebar';
import HemingwayService from '../services/HemingwayService';
import RichTextEditor from '../components/RichTextEditor';
// import HtmlParser from '../components/HemingwayEditor2';

function HemingwayPage(props) {

    const hemingwayAnalysisData = HemingwayService.getHemingwayAnalysisHARDCODEDTEST("");
    const groupsOfSuggestionsIncluded = HemingwayService.getGroupsOfSuggestionsIncluded();

    const [inFocusElement, setInFocusElement] = React.useState(null);
    const [selectedSuggestionGroup, setSelectedSuggestionGroup] = React.useState(null);

    const [sidebarEditorOption, setSidebarEditorOption] = React.useState(0);
    const [textContent, setTextContent] = React.useState("");

    const changeSidebarEditor = (option) => {
        if (sidebarEditorOption == option) {
            setSidebarEditorOption(0);
        } else {
            setSidebarEditorOption(option);
        }
    }

    const changeTextContent = (text) => {
        setTextContent(text);
    }

    const schangeSelectedSuggestionGroup = (group) => {
        setSelectedSuggestionGroup(group);
        updateDiploHighlights(group);
        setInFocusElement(null)
    }

    const analyse = () => {
        console.log("analysing");
        console.log("=== text content ===");
        console.log(textContent);
    }

    function updateDiploHighlights(selectedSuggestionGroup) {

        console.log("========== POZIV ===========");
        // Get all the diplo-highlight elements
        const highlights = Array.from(document.getElementsByTagName('diplo-highlight'));

        console.log(highlights);
        console.log(selectedSuggestionGroup);

        // Iterate through each diplo-highlight element
        for (let i = 0; i < highlights.length; i++) {

            highlights[i].remove();

            // Create a new element with the desired changes
            const newHighlight = document.createElement('diplo-highlight-hide');
            newHighlight.innerHTML = "-";
            // newHighlight.innerHTML = highlights[i].innerHTML;

            // Copy over any other attributes from the original element
            Array.from(highlights[i].attributes).forEach((attribute) => {
                newHighlight.setAttribute(attribute.name, attribute.value);
            });

            // Replace the existing element with the updated one
            highlights[i].parentNode.replaceChild(newHighlight, highlights[i]);
        }

        console.log("=====================");
    }

    const renderSidebarEditor = (sidebarEditorOption) => {
        switch (sidebarEditorOption) {
            case 1:
                return <TextAnalysisSidebar
                    hemingwayAnalysisData={hemingwayAnalysisData}
                />;
            case 2:
                return <SuggestionsSidebar
                    hemingwayAnalysisData={hemingwayAnalysisData}
                    groupsOfSuggestionsIncluded={groupsOfSuggestionsIncluded}
                    setInFocusElement={setInFocusElement}
                    inFocusElement={inFocusElement}
                    selectedSuggestionGroup={selectedSuggestionGroup}
                    setSelectedSuggestionGroup={schangeSelectedSuggestionGroup}
                />;
            case 3:
                return "3";
        }
    }

    return (
        <div id="hemingway-page" className="page-container">

            <div className="page-header">
                <h1>
                    PETAR PETROVIC NJEGOS
                </h1>
            </div>

            {
                // <HtmlParser />
            }

            <div className={sidebarEditorOption != 0 ? "page-content two-columns" : "page-content"}>

                <div>
                    <HemingwayEditor
                        changeTextContent={changeTextContent}
                        groupsOfSuggestionsIncluded={groupsOfSuggestionsIncluded}
                        setInFocusElement={setInFocusElement}
                    />
                    {/* <RightContextMenu /> */}
                </div>

                <div className="sidebar">
                    <div>
                        {
                            sidebarEditorOption != 0 &&
                            <div className='sidebar-editor'>
                                {
                                    renderSidebarEditor(sidebarEditorOption)
                                }
                            </div>
                        }
                        <Button variant="outlined" onClick={analyse}>
                            Analyse
                        </Button>
                    </div>

                    <div className='sidebar-options'>
                        <IconButton className='text-analysis'>
                            <AssessmentIcon style={{ color: "black", fontSize: "50px", opacity: "0.7" }} onClick={() => { changeSidebarEditor(1) }} />
                        </IconButton>
                        <IconButton className='text-analysis'>
                            <HistoryEduIcon style={{ color: "black", fontSize: "50px", opacity: "0.7" }} onClick={() => { changeSidebarEditor(2) }} />
                        </IconButton>
                        <IconButton className='text-analysis'>
                            <LinkIcon style={{ color: "black", fontSize: "50px", opacity: "0.7" }} onClick={() => { changeSidebarEditor(2) }} />
                        </IconButton>
                    </div>

                </div>

            </div>

            
            <RichTextEditor />
        </div>
    );
}

export default HemingwayPage;