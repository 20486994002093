// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    /* display: flex;
    justify-content: center; */
}

img {
    max-width: 100%;
}

#news-page-container {
    display: flex;
    max-width: 1000px;
    flex-wrap: wrap;
    margin: auto;
    padding: 25px 20px 50px 20px;
}

#news-page-container .header {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#news-page-container h1{
    margin-top: 0;
}

#news-page-container .header a{
    flex-basis: 100%;
    display: flex;
    justify-content: center;
}

#news-page-container .header div.date{
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
}

#news-page-container .header div.date p{
    margin-bottom: 10px;
    margin-top: 0;
}

#news-page-container a.original-source{
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    margin-top: 20px;
}

#news-page-container .content{
    font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/apps/ai-writer/assets/css/news-page.css"],"names":[],"mappings":"AAAA;IACI;8BAC0B;AAC9B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".main {\n    /* display: flex;\n    justify-content: center; */\n}\n\nimg {\n    max-width: 100%;\n}\n\n#news-page-container {\n    display: flex;\n    max-width: 1000px;\n    flex-wrap: wrap;\n    margin: auto;\n    padding: 25px 20px 50px 20px;\n}\n\n#news-page-container .header {\n    flex-basis: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n#news-page-container h1{\n    margin-top: 0;\n}\n\n#news-page-container .header a{\n    flex-basis: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n#news-page-container .header div.date{\n    display: flex;\n    flex-basis: 100%;\n    justify-content: flex-end;\n}\n\n#news-page-container .header div.date p{\n    margin-bottom: 10px;\n    margin-top: 0;\n}\n\n#news-page-container a.original-source{\n    display: flex;\n    flex-basis: 100%;\n    justify-content: flex-end;\n    margin-top: 20px;\n}\n\n#news-page-container .content{\n    font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
