import React from 'react'
import Button from '@mui/material/Button';

function Settings() {
    const clear=()=>{
        localStorage.removeItem("last_used"); 
    }
  return (
    <div style={{'padding':15}}>
        <Button variant="contained" onClick={clear}>clear cache</Button>
    </div>
  )
}

export default Settings