import React, { Component, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import NewsService from '../services/NewsService';
import '../assets/css/news-page.css';
import { Box, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, CircularProgress, LinearProgress } from '@mui/material';

function getSocialMediaWriterBookmark() {
    return `
    javascript:
    var d=document,
    w=window,
    e=w.getSelection,
    k=d.getSelection,
    x=d.selection,
    s=(e?e():(k)?k():(x?x.createRange().text:0)),
    f='https://ai-apps.diplomacy.edu/ai-writer/social-media-writer',
    l=d.location,
    e=encodeURIComponent,
    u=f+'?newsURL='+e(l.protocol+'//'+l.host+l.pathname+l.search);
    a=function(){if(!w.open(u,'t','toolbar=0,resizable=1,scrollbars=1,status=1,width=900px,height=600px'))l.href=u;};
    if (/Firefox/.test(navigator.userAgent)) setTimeout(a, 0); else a();
    void(0)`;
    // return `
    // javascript:
    // var d=document,
    // w=window,
    // e=w.getSelection,
    // k=d.getSelection,
    // x=d.selection,
    // s=(e?e():(k)?k():(x?x.createRange().text:0)),
    // f='` + process.env.REACT_APP_URL + `',
    // l=d.location,
    // e=encodeURIComponent,
    // u=f+'?newsURL='+e(l.protocol+'//'+l.host+l.pathname+l.search);
    // a=function(){if(!w.open(u,'t','toolbar=0,resizable=1,scrollbars=1,status=1,width=900px,height=600px'))l.href=u;};
    // if (/Firefox/.test(navigator.userAgent)) setTimeout(a, 0); else a();
    // void(0)`;
}

function SocialMediaWriterPage(props) {

    const [newsData, setNewsData] = useState(null);
    const [newsURL, setNewsURL] = useState(null);
    const [status, setStatus] = useState(0);

    const addUpdateField = useRef(null);

    const [socialMediaPost, setSocialMediaPost] = useState(null);
    const [gettingNewsInProgress, setGettingNewsInProgress] = useState(false);

    const statusDisplay = () => {
        switch (status) {
            case -3:
                return "No update selected."
            case -2:
                return "Error creating social media post."
            case -1:
                return "Error getting update content from dig.watch."
            case 0:
                return "Loading data from dig.watch"
            case 1:
                return "Data loaded. Generating social media post."
            // case 2:
            //     return "Social media post generated."
            default:
                // Generate social media post is finished. Display the results.
                return true;
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        if (params.get("newsURL") == null) {
            setStatus(-3);
            return;
        }

        setNewsURL(params.get("newsURL"));

        startProcess(params.get("newsURL"));

    }, []);

    const startProcess = (url) => {
        setGettingNewsInProgress(true);
        NewsService.getUpdateFromDigWatch(url)
            .then((res) => {
                setGettingNewsInProgress(false);
                setNewsData(res.data);
                setStatus(1);

                NewsService.createSocialMediaPost(res.data.post_content)
                    .then((res) => {
                        setStatus(2);
                        setSocialMediaPost(res.data.server.social_media);
                    })
                    .catch((error) => {
                        console.error(error);
                        setStatus(-2);
                        // alert("Error creating social media post.");
                    });
            })
            .catch((error) => {
                console.error(error);
                setStatus(-1);
                // alert("Error getting news content.");
            });
    }

    const addUpdateOnKeyDown = (e) => {
        if (e.keyCode == 13) {
            startProcess(addUpdateField.current.value);
        }
    }

    const addUpdateButtonClick = () => {
        startProcess(addUpdateField.current.value);
    }

    return (
        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", padding: "20px 50px" }}>
            <div style={{ width: "100%", display: "flex" }}>
                <a href={getSocialMediaWriterBookmark()}>
                    <h2>
                        Diplo Social Media Writer - Bookmark
                    </h2>
                </a>
            </div>
            {
                statusDisplay() === true ?
                    <div id='news-page-container'>

                        {/* Original update */}
                        <div>
                            <div className='header'>
                                <a href={newsData.permalink} target='_blank'>
                                    <h2>
                                        {newsData.post_title}
                                    </h2>
                                </a>
                            </div>

                            <div>
                                <img src={newsData.image} />
                            </div>

                            <div className='content'
                                dangerouslySetInnerHTML={{ __html: newsData.post_excerpt }}>
                            </div>

                            {/* <a className="original-source" href={newsData.permalink}>
                                Original source at.
                            </a> */}
                        </div>

                        {/* Twitter titles */}
                        <div style={{ width: "100%", display: "grid" }}>
                            <h2>
                                Twitter titles:
                            </h2>
                            <div>
                                {
                                    socialMediaPost != null &&
                                    socialMediaPost.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <p style={{ margin: "5px 0" }}>
                                                    {index + 1}. {item}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                    : <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        <div style={{ flexBasis: "100%" }}>
                            <h2>
                                {statusDisplay()}
                            </h2>
                        </div>
                        {
                            status == -3 &&
                            <div style={{ flexBasis: "100%" }}>
                                <TextField
                                    inputRef={addUpdateField}
                                    id="update-url-feild"
                                    label="Add update URL for analysis"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    onKeyDown={addUpdateOnKeyDown}
                                />

                                {
                                    gettingNewsInProgress
                                        ? <Box
                                            style={{ marginTop: "20px", float: "right" }}
                                        >
                                            <CircularProgress style={{ width: "35px", height: "35px" }} />
                                        </Box>
                                        : <IconButton
                                            aria-label="select"
                                            style={{ float: "right" }}
                                            onClick={() => addUpdateButtonClick()}
                                        >
                                            <AddRoundedIcon style={{ width: "35px", height: "35px" }} />
                                        </IconButton>

                                }

                            </div>
                        }
                    </div>
            }
        </div>
    );
}

export default SocialMediaWriterPage;