
import React,{useState} from 'react'

function Radios({mapType,setmapType}) {

  const onOptionChange = e => {
    setmapType(e.target.value)
  }
  return (
    <div className='radios-group'>
      <div>
        <input type="radio" id="Nationality" name="map" value="0" checked={mapType === '0'}  onChange={onOptionChange}/>
        <label for="Nationality">Nationality</label>
      </div>
      <div>
        <input type="radio" id="Location" name="map" value="1" hecked={mapType === '1'}  onChange={onOptionChange}/>
        <label for="Location">Location</label>
      </div>
    </div>
  )
}

export default Radios