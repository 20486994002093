import React, { Component } from 'react';
import NewsList from '../components/NewsList';
import SideNewsList from '../components/SideNewsList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button, CardActionArea, CardActions, IconButton, Tooltip } from '@mui/material';
import NewsService from '../services/NewsService';
import '../assets/css/main.css';
import LayersIcon from '@mui/icons-material/Layers';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import CloseIcon from '@mui/icons-material/Close';

function getDate() {
    const date = new Date(); // get the current date and time
    const options = { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleString('en-UK', options);
}

function get_diplo_getter_js_code() {

    let link = `
    javascript:
    var d=document,
    w=window,
    e=w.getSelection,
    k=d.getSelection,
    x=d.selection,
    s=(e?e():(k)?k():(x?x.createRange().text:0)),
    f='` + process.env.REACT_APP_URL_TEST + `/ai-writer/summarizer',
    l=d.location,
    e=encodeURIComponent,
    u=f+'?article1='+e(l.protocol+'//'+l.host+l.pathname+l.search);
    a=function(){if(!w.open(u,'t','toolbar=0,resizable=1,scrollbars=1,status=1,width=900px,height=600px'))l.href=u;};
    if (/Firefox/.test(navigator.userAgent)) setTimeout(a, 0); else a();
    void(0)`;

    return link;
}

class BriefingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedNews: [],
            selectedNewsPage: 0,
            selectedNewsTotalPages: 0,
            currentSelectedNews: null,
            currentRelatedNews: null,
            numbersOfNewsToDisplay: 3,
            numbersOfUpdatesToDisplay: 3
        };
    }

    setSelectedNews = (selectedNews) => {
        this.setState({
            selectedNews: selectedNews,
            currentSelectedNews: selectedNews[0],
            selectedNewsTotalPages: selectedNews.length
        })
        this.getRelatedNews(selectedNews[0])
    }

    getRelatedNews = (currentSelectedNews) => {
        if (currentSelectedNews == undefined) {
            this.setState({
                currentRelatedNews: null
            });
            return false

        };

        NewsService.getNewsSingle(currentSelectedNews.link).then((res) => {
            if (res.data.text == "") return;

            NewsService.getRelatedNews(res.data.text).then((results) => {
                this.setState({
                    currentRelatedNews: results.data
                });
            });

        });
        return false

    }

    nextPage = () => {
        let newPage = this.state.selectedNewsPage + 1;
        this.setState({
            selectedNewsPage: newPage,
            currentSelectedNews: this.state.selectedNews[newPage],
            currentRelatedNews: null
        });

        this.getRelatedNews(this.state.selectedNews[newPage]);
    }

    previousPage = () => {
        let newPage = this.state.selectedNewsPage - 1;
        this.setState({
            selectedNewsPage: newPage,
            currentSelectedNews: this.state.selectedNews[newPage],
            currentRelatedNews: null
        });

        this.getRelatedNews(this.state.selectedNews[newPage]);
    }

    startSummarization = () => {

        if (this.state.selectedNews.length == 0) {
            alert("Please select some news");
            return;
        }

        let queryParams = "";

        for (let i = 0; i < this.state.selectedNews.length; i++) {
            queryParams += (i == 0 ? '?' : '&') + `article${i + 1}=${this.state.selectedNews[i].link}`;
        }

        window.open("/ai-writer/summarizer" + queryParams);

    }

    removeSelectedNews = () => {
        let newSelectedNews = this.state.selectedNews;
        newSelectedNews.splice(this.state.selectedNewsPage, 1);

        this.setState({
            selectedNews: newSelectedNews,
            selectedNewsTotalPages: newSelectedNews.length,
            currentSelectedNews: newSelectedNews.length == 0 ? null : newSelectedNews[this.state.selectedNewsPage],
            currentRelatedNews: null
        });

        this.getRelatedNews(newSelectedNews.length == 0 ? null : newSelectedNews[this.state.selectedNewsPage]);
    }

    render() {
        return (
            <div className='page-container'>
                <div className='page-header'>
                    <h1>
                        Your briefing
                    </h1>
                    <h4>
                        {getDate()}
                    </h4>
                    <h4>
                        <a href={get_diplo_getter_js_code()}>
                            Diplo Get It - Bookmark
                        </a>
                    </h4>
                </div>
                <div className='page-content'>

                    {/* Main Page Container */}
                    <div className='briefing-container'>
                        <NewsList setSelectedNews={this.setSelectedNews} />
                    </div>

                    {/* Sidebar Container */}
                    <div className="briefing-sidebar-container">

                        <div className="option-buttons">
                            <Button
                                variant="contained"
                                endIcon={<LayersIcon />}
                                style={{ backgroundColor: "#00879a", marginRight: "20px" }}
                                onClick={() => window.open("/ai-writer/summarizer")}
                            >
                                Blank workspace
                            </Button>
                            <Button
                                variant="contained"
                                endIcon={<SummarizeRoundedIcon />}
                                style={{ backgroundColor: "#00879a" }}
                                onClick={this.startSummarization}
                            >
                                Summarizer
                            </Button>
                        </div>

                        {/* Selected News */}
                        <div className='selected-news-container'>
                            <div className="card-container">
                                <div className="card-container-header">
                                    <h2>
                                        Selected News
                                    </h2>
                                    <div className="pagination" style={this.state.selectedNews.length < 2 ? { display: "none" } : {}}>
                                        <IconButton onClick={this.previousPage} style={this.state.selectedNewsPage == 0 ? { display: "none" } : {}}>
                                            <KeyboardArrowLeftIcon style={{ width: "30px", height: "30px" }}/>
                                        </IconButton>
                                        <IconButton onClick={this.nextPage} style={this.state.selectedNewsPage == this.state.selectedNewsTotalPages - 1 ? { display: "none" } : {}}>
                                            <KeyboardArrowRightIcon style={{ width: "30px", height: "30px" }}/>
                                        </IconButton>
                                    </div>
                                </div>
                                <hr />
                                <div className='ai-recommended-news-body'>
                                    {
                                        this.state.selectedNews.length == 0
                                            ? <p>
                                                Select news to get related one.
                                            </p>
                                            : <>
                                                <div className='news-element' key={this.state.currentSelectedNews.id}>
                                                    <div className="news-card">

                                                        <div className="news-card-img">
                                                            {
                                                                this.state.currentSelectedNews.thumbnail == ""
                                                                    ? <img src={require('../assets/img/update-image-100x80.webp')} />
                                                                    : <img
                                                                        src={this.state.currentSelectedNews.thumbnail}
                                                                        alt={this.state.currentSelectedNews.title}
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = require('../assets/img/update-image-100x80.webp');
                                                                        }} />
                                                            }
                                                        </div>

                                                        <div className="news-card-body">
                                                            <div className="news-card-content">
                                                                <p className='publisher'>
                                                                    {this.state.currentSelectedNews.source}
                                                                </p>
                                                                <a href={this.state.currentSelectedNews.link} target="_blank">
                                                                    <h3 className='title'>
                                                                        {this.state.currentSelectedNews.title}
                                                                    </h3>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* <div className="news-card-close">
                                                            <IconButton onClick={this.removeSelectedNews} >
                                                                <CloseIcon style={{ width: "30px", height: "30px" }}/>
                                                            </IconButton>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            <div className="topics">
                                {
                                    this.state.currentRelatedNews != null && this.state.currentRelatedNews.topics != undefined &&
                                    this.state.currentRelatedNews.topics.map(topic =>
                                        <span className='topic'>{topic}</span>
                                    )
                                }
                            </div>
                        }

                        {/* Related News */}
                        <div className='related-news-container'>
                            <div className="card-container">
                                <div className="card-container-header">
                                    <h2>
                                        Related News
                                    </h2>
                                </div>
                                <hr />
                                <div className='ai-recommended-news-body'>
                                    {
                                        this.state.currentRelatedNews != null &&
                                        <>
                                            {
                                                this.state.currentRelatedNews.similar_news.slice(0, this.state.numbersOfNewsToDisplay).map(
                                                    element => <>
                                                        <div className='news-element' key={element.id}>

                                                            <div className="news-card">

                                                                <div className="news-card-body">
                                                                    <div className="news-card-content">
                                                                        <p className='publisher'>
                                                                            {element.source}
                                                                        </p>
                                                                        <a href={element.link} target="_blank">
                                                                            <h3 className='title'>
                                                                                {element.title}
                                                                            </h3>
                                                                        </a>
                                                                        <p className='news-excerpt'>
                                                                            {element.snippet}
                                                                        </p>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <hr className='news-devider' />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                this.state.currentRelatedNews.similar_news.length > this.state.numbersOfNewsToDisplay &&
                                                <div className="load-more" >
                                                    <p onClick={() => { this.setState({ numbersOfNewsToDisplay: this.state.numbersOfNewsToDisplay + 3 }) }}>
                                                        Load more
                                                    </p>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Related Updates */}
                        <div className='related-updates-container'>
                            <div className="card-container">
                                <div className="card-container-header">
                                    <h2>
                                        Related Updates
                                    </h2>
                                </div>
                                <hr />
                                <div className='ai-recommended-news-body'>
                                    {
                                        this.state.currentRelatedNews != null &&
                                        <>
                                            {
                                                this.state.currentRelatedNews.similar_updates.slice(0, this.state.numbersOfUpdatesToDisplay).map(
                                                    element => {
                                                        let fullText = false

                                                        return <>
                                                            <div className='news-element' key={element.id}>

                                                                <div className="news-card">
                                                                    <div className="news-card-body">
                                                                        <div className="news-card-content">
                                                                            <p className='publisher'>
                                                                                {element.link.includes("dig.watch") ? "dig.watch" : element.link}
                                                                            </p>
                                                                            <a href={element.link} target="_blank">
                                                                                <h3 className='title'>
                                                                                    {element.title}
                                                                                </h3>
                                                                            </a>
                                                                            <p className='news-excerpt' onClick={(fullText) => { fullText = fullText ? false : true; }} style={{ cursor: "pointer" }}>
                                                                                {
                                                                                    fullText
                                                                                        ? element.text
                                                                                        : element.text.substr(0, 150) + " ..."
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr className='news-devider' />
                                                            </div>
                                                        </>
                                                    }
                                                )
                                            }
                                            {
                                                this.state.currentRelatedNews.similar_updates.length > this.state.numbersOfUpdatesToDisplay &&
                                                <div className="load-more" >
                                                    <p onClick={() => { this.setState({ numbersOfUpdatesToDisplay: this.state.numbersOfUpdatesToDisplay + 3 }) }}>
                                                        Load more
                                                    </p>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Related News */}
                        {/* <RelatedNews key={this.state.currentRelatedNews} currentRelatedNews={this.state.currentRelatedNews} /> */}

                        {/* Related Updates */}
                        {/* <RelatedUpdates key={this.state.currentRelatedNews} currentRelatedNews={this.state.currentRelatedNews} /> */}

                    </div>
                </div>
            </div>
        );
    }
}

export default BriefingPage;