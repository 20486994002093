import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Alert, Checkbox, CircularProgress, FormControl, ImageList, ImageListItem, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@mui/material';
import ImagesService from '../services/ImagesService';

import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// Import styles
import '../../../../assets/css/main-apps.css';
import '../assets/css/image-searcher-main.css';

// Images
import diploImageSearchIcon from "../assets/img/cloud-computing.png";
import externalImageSearchIcon from "../assets/img/external-link.png";
import AIGenerateImagesIcon from "../assets/img/artificial-intelligence.png";
import searchByImage from "../assets/img/photo.png";
import { render } from '@testing-library/react';

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const testSelectedImages = [
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/04/washington-monument-in-washington-dc-united-states-of-america-usa-1024x682.jpg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/03/Global_Digital_Compact_home.webp",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/03/uk-flag-close-up-1024x682.jpg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2021/12/8U8BWHpU-Meta.jpeg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/04/washington-monument-in-washington-dc-united-states-of-america-usa-1024x682.jpg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/03/Global_Digital_Compact_home.webp",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/03/uk-flag-close-up-1024x682.jpg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2021/12/8U8BWHpU-Meta.jpeg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/04/washington-monument-in-washington-dc-united-states-of-america-usa-1024x682.jpg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/03/Global_Digital_Compact_home.webp",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2023/03/uk-flag-close-up-1024x682.jpg",
    "https://diplo-media.s3.eu-central-1.amazonaws.com/2021/12/8U8BWHpU-Meta.jpeg",
]

function ImageSearcher(props) {

    const [inProgress, setInProgress] = React.useState(false);

    // Search Type
    // 0 => Diplo Images Search
    // 1 => External Images Search
    // 2 => AI Generate Images
    const [selectedSearchType, setSelectedSearchType] = React.useState(0);

    // Selected Images
    // const [selectedImages, setSelectedImages] = React.useState(props.selectedImages);
    const searchField = React.createRef();

    // External Images Search
    // const [foundExternalImages, setFoundExternalImages] = React.useState(testSelectedImages);
    const [foundExternalImages, setFoundExternalImages] = React.useState([]);

    // Diplo Images Search
    // const [foundDiploImages, setFoundDiploImages] = React.useState(testSelectedImages);
    const [foundDiploImages, setFoundDiploImages] = React.useState([]);

    // Images Generate
    const [generatedImages, setGeneratedImages] = React.useState([]);

    const zapierVibes = ImagesService.getVibesFromZapier();
    const [seletedVibe, setSeletedVibe] = React.useState(zapierVibes[0]);

    const zapierStyles = ImagesService.getStylesFromZapier();
    const [seletedStyle, setSeletedStyle] = React.useState(zapierStyles[0]);

    const zapierPerspectives = ImagesService.getPerspectivesFromZapier();
    const [seletedPerspective, setSeletedPerspective] = React.useState(zapierPerspectives[0]);


    React.useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = (event) => {

        let divSelectedImages = document.getElementById("selected-images");
        let divSearchedImages = document.getElementById("searched-images");
        let scroll = window.scrollY;

        let startScrollOffset = divSelectedImages.getBoundingClientRect().top;

        if (startScrollOffset <= 70) {

            let right = divSelectedImages.getBoundingClientRect();
            let left = divSearchedImages.getBoundingClientRect();

            console.log("=====================================")
            
            console.log("left.top: " + left.top);
            console.log("left.bottom: " + left.bottom);
            
            console.log("right.top: " + right.top);
            console.log("right.bottom: " + right.bottom);
            
            console.log("=====================================")

            divSelectedImages.style.top =  270 + "px";
        }
        else {
            console.log("NEMA SCROLA")
            divSelectedImages.style.top = "auto";
        }

    };

    const handleScroll2 = (event) => {

        let divSelectedImages = document.getElementById("selected-images");
        let divSearchedImages = document.getElementById("searched-images");
        let scroll = window.scrollY;

        let startScrollOffset = divSearchedImages.getBoundingClientRect().top;

        console.log(startScrollOffset);
        if (startScrollOffset <= 70) {

            console.log("SCROLLL");
            
            // windowHeight = Math.round(window.innerHeight / (100 / vh));

            console.log("divSearchedImages:" + divSearchedImages.getBoundingClientRect().top)
            console.log("scorll:" + scroll)
            console.log("height: " + window.innerHeight )
            // divSelectedImages.style.top = scroll - window.innerHeight  + 70 + "px";
            divSelectedImages.style.top =  scroll - + 170 + "px";

            // let maxScroll = divOriginalArticles.getBoundingClientRect().height - divDiploSummary.getBoundingClientRect().height;
            
            /////// let top = divOriginalArticles.getBoundingClientRect().height - 700 < scroll ? divOriginalArticles.getBoundingClientRect().height - 700 : scroll;
            
            // let top = maxScroll < scroll ? maxScroll : scroll;

            // divDiploSummary.style.top = top + 100 + "px";
        }
        else {
            console.log("NEMA SCROLA")
            divSelectedImages.style.top = "auto";
        }

    };

    const handleImageSearch = () => {
        switch (selectedSearchType) {
            case 0:
                handleDiploImageSearch();
                break;
            case 1:
                handleExternalImageSearch();
                break;
            case 2:
                handleAIGenerateImages();
                break;

            default:
                break;
        }
    };

    const imageSearchOnKeyDown = (e) => {
        if (e.keyCode == 13) {
            handleImageSearch();
        }
    }

    // External Images Search

    const handleExternalImageSearch = () => {
        setInProgress(true);
        ImagesService.searchExternalImages(searchField.current.value)
            .then((response) => {
                setFoundExternalImages(response.data);
                setInProgress(false);
            })
            .catch((error) => {
                console.error(error);
                setInProgress(false);
                alert("Error getting images");
            });
    };

    // Diplo Images Search

    const handleDiploImageSearch = () => {
        setInProgress(true);
        ImagesService.searchDiploImages(searchField.current.value)
            .then((response) => {

                // Razlika u odgovoru izmedju External i Diplo Image Search-a

                let images = [];

                for (let i = 0; i < response.data.length; i++) {
                    const element = response.data[i];
                    images.push(element.link);
                }

                setFoundDiploImages(images);
                setInProgress(false);
            })
            .catch((error) => {
                console.error(error);
                setInProgress(false);
                alert("Error getting images");
            });
    };

    // AI Generate Images

    const handleAIGenerateImages = () => {
        let data = {
            "subject": searchField.current.value,
            "style": seletedStyle,
            "vibe": seletedVibe,
            "perspective": seletedPerspective,
        };

        setInProgress(true);
        ImagesService.generateImages(data)
            .then((response) => {
                console.log(response.data);
                setGeneratedImages(response.data.image_urls);
                setInProgress(false);
            })
            .catch((error) => {
                console.error(error);
                setInProgress(false);
                alert("Error getting images");
            });
    };

    const renderImageListContent = () => {
        switch (selectedSearchType) {
            case 0:
                return foundDiploImages.map((item) => (
                    renderImageListItem(item)
                ))
            case 1:
                return foundExternalImages.map((item) => (
                    renderImageListItem(item)
                ))
            case 2:
                return generatedImages.map((item) => (
                    renderImageListItem(item)
                ))
        }
    }

    const renderImageListItem = (item) => {
        return <ImageListItem key={item} cols={2} rows={1}>
            <div className="image-container">
                <img
                    src={item}
                    // {...srcset(item, 121)}
                    loading="lazy"
                    onClick={() => props.addSelectedImage(item)}
                    style={{ cursor: "pointer" }}
                />
                <IconButton
                    aria-label="select"
                    className="image-select-button"
                    onClick={() => window.open(item, "_blank")}
                >
                    <FullscreenIcon style={{ width: "35px", height: "35px" }} />
                </IconButton>
            </div>
        </ImageListItem>
    }

    return (
        <>

            <div className="page-header">
                <h1>
                    Image Search
                </h1>
            </div>

            <div className="image-search-type-cards">
                <div
                    className={selectedSearchType == 0 ? "image-search-type-card active" : "image-search-type-card"}
                    onClick={(e) => setSelectedSearchType(0)}
                >
                    <img src={diploImageSearchIcon} />
                    {
                        selectedSearchType == 0 &&
                        <h2>Searching Diplo Images</h2>
                    }
                </div>

                <div
                    className={selectedSearchType == 1 ? "image-search-type-card active" : "image-search-type-card"}
                    onClick={(e) => setSelectedSearchType(1)}
                >
                    <img src={externalImageSearchIcon} />
                    {
                        selectedSearchType == 1 &&
                        <h2>Searching External Images</h2>
                    }
                </div>

                <div
                    className={selectedSearchType == 2 ? "image-search-type-card active" : "image-search-type-card"}
                    onClick={(e) => setSelectedSearchType(2)}
                >
                    <img src={AIGenerateImagesIcon} />
                    {
                        selectedSearchType == 2 &&
                        <h2>Generate AI Images</h2>
                    }
                </div>

                {/* <div
                    className={selectedSearchType == 3 ? "image-search-type-card active" : "image-search-type-card"}
                    onClick={(e) => setSelectedSearchType(3)}
                >
                    <img src={searchByImage} />
                    {
                        selectedSearchType == 3 &&
                        <h2>Searching By Image</h2>
                    }
                </div> */}
            </div>

            <div className="page-content">

                <div className="images-preview-container card-container">
                    <h2 >Searched images:</h2>
                    <hr className="heading-devider" />

                    <ImageList
                        variant="quilted"
                        cols={4}
                    >
                        {
                            renderImageListContent()
                        }
                    </ImageList>
                </div>

                <div className="images-options-container">

                    <div id="searched-images" className="card-container">

                        <h2 style={{ marginTop: "0" }}>Search options:</h2>
                        <hr className="heading-devider" />

                        <div className='search-field'>
                            <TextField
                                id="outlined-search"
                                label="Search field"
                                type="search"
                                inputRef={searchField}
                                style={{ width: "100%", marginBottom: "20px" }}
                                onKeyDown={imageSearchOnKeyDown}
                            />
                        </div>

                        {
                            selectedSearchType == 2 &&
                            <>

                                {/* Vibes */}
                                <div className='dropdown-options-vibes'>
                                    <InputLabel id="vibes-select">Vibes</InputLabel>
                                    <Select
                                        labelId="vibes-select"
                                        value={seletedVibe}
                                        onChange={(e) => setSeletedVibe(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        {
                                            zapierVibes != null
                                                ? zapierVibes.map(
                                                    element => <MenuItem value={element}>{element}</MenuItem>
                                                )
                                                : <></>
                                        }
                                    </Select>
                                </div>

                                {/* Styles */}
                                <div className='dropdown-options-styles'>
                                    <InputLabel id="styles-select">Styles</InputLabel>
                                    <Select
                                        labelId="styles-select"
                                        value={seletedStyle}
                                        onChange={(e) => setSeletedStyle(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        {
                                            zapierStyles != null
                                                ? zapierStyles.map(
                                                    element => <MenuItem value={element}>{element}</MenuItem>
                                                )
                                                : <></>
                                        }
                                    </Select>
                                </div>

                                {/* Perspective */}
                                <div className='dropdown-options-perspective'>
                                    <InputLabel id="styles-select">Perspective</InputLabel>
                                    <Select
                                        labelId="styles-select"
                                        value={seletedPerspective}
                                        onChange={(e) => setSeletedPerspective(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        {
                                            zapierPerspectives != null
                                                ? zapierPerspectives.map(
                                                    element => <MenuItem value={element}>{element}</MenuItem>
                                                )
                                                : <></>
                                        }
                                    </Select>
                                </div>
                            </>
                        }

                        <hr />


                        {/* Search button */}
                        <div>
                            {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
    <InputLabel id="days-filter-select">Days Filter</InputLabel>
    <Select
        labelId="days-filter-select"
        // value={this.state.daysFilter}
        label="Days Filter"
    // onChange={this.handleDaysFilterChange}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value={1}>1 day ago</MenuItem>
        <MenuItem value={2}>2 days ago</MenuItem>
        <MenuItem value={3}>3 days ago</MenuItem>
    </Select>
</FormControl> */}
                            {
                                inProgress
                                    ? <CircularProgress className={"circular-progress"} />
                                    : <Button
                                        variant="contained"
                                        endIcon={<SearchIcon />}
                                        onClick={handleImageSearch}
                                        className="search-button"
                                    >
                                        Search
                                    </Button>
                            }
                        </div>

                    </div>

                    <div id="selected-images" className="card-container" style={{position: "relative"}}>
                        <h2 style={{ marginTop: "0" }}>Selected Images:</h2>
                        <hr className="heading-devider" />

                        <div className="selected-images-container">
                            {
                                props.selectedImages.map(element =>
                                    <div className="selected-image">
                                        <img src={element}
                                            onClick={() => { props.removeSelectedImage(element) }}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                )
                            }
                        </div>

                        <hr />
                    </div>

                </div>

            </div>

        </>
    );
}

export default ImageSearcher;