import { useNavigate } from 'react-router-dom';

export function useNavigation() {
    const navigate = useNavigate();

    function navigateTo(url) {
        navigate(url);
    }

    return { navigateTo };
}
