import React from "react";
import ReactDOM from "react-dom";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import HemingwayService from "../services/HemingwayService";
import cheerio from 'cheerio';
import HemingwayPopup from "./HemingwayPopup";

const HemingwayContent = [
    {
        body: `<h1>Hemingway App makes your writing bold and clear.</h1>`
    },
    {
        body: `
        <p>
            The app highlights lengthy, complex sentences and common errors; if you see a yellow sentence, shorten or split it. If you see a red highlight, your sentence is so dense and complicated that your readers will get lost trying to follow its meandering, splitting logic — try editing this sentence to remove thsde red.
        </p>`
    },
    {
        body: `
        <p>
            You can utilize a shorter word in place of a purple one. Mouse over them for hints.
        </p>`
    },
    {
        body: `
        <p>
            Adverbs and weakening phrases are helpfully shown in blue. Get rid of them and pick words with force, perhaps.
        </p>`
    },
    {
        body: `
        <p>
            Phrases in green have been marked to show passive voice.
        </p>`
    },
    {
        body: `
        <p>
            You can format your text with the toolbar.
        </p>
        `
    },
    {
        body: `
        <p>
            Paste in something you're working on and edit away. Or, click the Write button and compose something new.
        </p>`
    }
];

class HemingwayEditor extends React.Component {

    constructor() {
        super();
        this.state = {
            // html: HemingwayContent,
            html: HemingwayService.getContent(),
            plainText: "",
            editable: true,
            position: { x: 0, y: 0 },
            popupData: {
                "positionX": 0,
                "positionY": 0,
                "display": "none",
                "type": "",
                "recommendation": "",
                "description": ""
            },
            testOriginal: "",
            testIzmena: "",
        };
    }

    componentDidMount() {

        const timer = setInterval(() => {
            this.addPopupEventListeners();
        }, 500);

        // this.addPopupEventListeners();
    }

    addPopupEventListeners = () => {

        let diploElements = document.getElementsByTagName('diplo-highlight');

        for (const element of diploElements) {

            element.addEventListener("mouseenter", (event) => {
                this.mouseEnterDiploElement(event, element.getAttribute("data"));
            });

            element.addEventListener("mouseleave", (event) => {
                this.mouseLeaveDiploElement();
            });

            element.addEventListener("click", (event) => {
                event.preventDefault();
                this.diploElementClick(element);
            });

        }
    }

    mouseEnterDiploElement = (event, jsonData) => {

        let data = JSON.parse(decodeURIComponent(jsonData));;

        this.setState({
            popupData: {
                "type": data["type"],
                "recommendation": data["recommendation"],
                "description": data["recommendation"],
                "positionX": event.pageX,
                "positionY": event.pageY,
                "display": "block",
            }
        });
    };

    mouseLeaveDiploElement = () => {
        this.setState({
            popupData: {
                "display": "none",
            }
        });
    };

    diploElementClick = (element) => {
        // alert("kliknuto " + element.id);
        this.props.setInFocusElement(HemingwayService.extractNumberFromDiploHighlight(element.id));
    };

    handleChange = event => {
        let html = event.target.value;
        // html = this.wrapSubstringInSpan(html, 'The app highlights lengthy');


        let plainText = this.getPlainText(html);

        this.setState({ html: html, plainText: plainText });


        // console.log(plainText);
        // console.log(plainText.substring(15, 29));
    };

    sanitizeConf = {
        allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "h2", "h3", "h4", "h5", "h6", "ul", "ol", "li", "span", "diplo-highlight"],
        allowedAttributes: { a: ["href"], "diplo-highlight": ["id", "type", "data"] }
    };

    sanitize = () => {
        this.setState({ html: sanitizeHtml(this.state.html, this.sanitizeConf) });
    };

    toggleEditable = () => {
        this.setState({ editable: !this.state.editable });
    };

    getPlainText = (html) => {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }

    analyse = () => {

        HemingwayService.getHemingwayAnalysis(this.state.plainText)

        console.log("analysing");
        console.log("=== text content ===");
        let plainText = this.getPlainText(this.state.html);

        console.log("=== html ===");
        console.log(HemingwayService.getHemingwayAnalysis(this.state.html));

        let hemingwayAnalysis = HemingwayService.getHemingwayAnalysisHARDCODEDTEST(this.state.html);
        this.setState({ html: HemingwayService.addRecommendationDataToText(plainText, hemingwayAnalysis) });

        // HemingwayService.getHemingwayAnalysis(plainText).then((response) => {
        //     alert("stigao odgovor");
        //     console.log(response);
        //     // this.setState({ html: HemingwayService.addRecommendationDataToText(this.state.html) });
        // })
        // .catch((error) => {
        //     console.log(error);
        // });


        // plainText.replace("The app highlights lengthy", `<span class="highlight" change="App highlights lengthy">The app highlights lengthy</span>`)

        // console.log(plainText);

    }

    render = () => {
        return (
            <>

                {/* Popup */}
                <HemingwayPopup data={this.state.popupData} />
                {/* <div
                    style={{
                        top: this.state.popup.positionY,
                        left: this.state.popup.positionX,
                        display: this.state.popup.display
                    }}
                    className="hemingway-editor-popup"
                >
                    {
                        "Promeni: " + this.state.popup.message + " " + this.state.popup.alternative
                    }
                </div> */}

                {
                    this.state.testOriginal
                }

                <hr />

                {
                    this.state.testIzmena
                }

                <div className="hemingway-editor-container">
                    <ContentEditable
                        className="hemingway-editor"
                        tagName="div"
                        html={this.state.html} // innerHTML of the editable div
                        // html={this.state.testIzmena}
                        disabled={!this.state.editable} // use true to disable edition
                        onChange={this.handleChange} // handle innerHTML change
                        onBlur={this.sanitize}
                        
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                    />
                </div>


                <div className="hemingway-editor-controls">
                    <h3>Actions</h3>
                    <EditButton cmd="italic" />
                    <EditButton cmd="bold" />
                    <EditButton cmd="formatBlock" arg="h1" name="heading" />
                    <EditButton
                        cmd="createLink"
                        arg="https://github.com/lovasoa/react-contenteditable"
                        name="hyperlink"
                    />
                    <button onClick={this.toggleEditable}>
                        Make {this.state.editable ? "readonly" : "editable"}
                    </button>
                </div>

                <h3>source</h3>
                <textarea
                    className="editable"
                    value={this.state.html}
                    onChange={this.handleChange}
                    onBlur={this.sanitize}
                    style={{ width: "100%", height: "200px" }}
                />

                <button onClick={this.analyse}>TEST</button>

            </>
        );
    };
}

export default HemingwayEditor;


function EditButton(props) {
    return (
        <button
            key={props.cmd}
            onMouseDown={evt => {
                evt.preventDefault(); // Avoids loosing focus from the editable area
                document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
            }}
        >
            {props.name || props.cmd}
        </button>
    );
}

