// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container-wrap,
.main-container {
    width: 100%;
    height: 100%;
}

#ai-writer-home-page-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.ai-writer-home-page-header{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ai-writer-home-page-header h1{
    text-align: center;
}

.ai-writer-home-page-body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

#ai-writer-home-page-container .button-card {
    cursor: pointer;
    border-radius: 15px;
    display: flex;
    padding: 15px 15px;
    flex-direction: column;
    align-items: center;
    aspect-ratio: 1;
}

#ai-writer-home-page-container .button-card h2{
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/apps/ai-writer/assets/css/ai-writer-home-page.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".main-container-wrap,\n.main-container {\n    width: 100%;\n    height: 100%;\n}\n\n#ai-writer-home-page-container {\n    display: grid;\n    width: 100%;\n    height: 100%;\n    grid-template-columns: 1fr;\n    align-items: center;\n    justify-content: center;\n    align-content: center;\n}\n\n.ai-writer-home-page-header{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.ai-writer-home-page-header h1{\n    text-align: center;\n}\n\n.ai-writer-home-page-body {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 30px;\n    flex-wrap: wrap;\n}\n\n#ai-writer-home-page-container .button-card {\n    cursor: pointer;\n    border-radius: 15px;\n    display: flex;\n    padding: 15px 15px;\n    flex-direction: column;\n    align-items: center;\n    aspect-ratio: 1;\n}\n\n#ai-writer-home-page-container .button-card h2{\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
