import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Alert, Checkbox, CircularProgress, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField } from '@mui/material';
import ImagesService from '../services/ImagesService';

import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ImageSearcher from '../../image-searcher/components/ImageSearcher';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ImagesModal(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    // Selected Images
    const [selectedImages, setSelectedImages] = React.useState(props.selectedImages);

    React.useEffect(() => {
    }, []);


    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
    };

    const submitSelectedImages = () => {
        props.setSelectedImages(selectedImages);
        setOpen(false);
    };

    const addSelectedImage = (image) => {
        let selectedImagesPom = [...selectedImages];
        selectedImagesPom.push(image);
        setSelectedImages(selectedImagesPom);
    };

    const removeSelectedImage = (image) => {
        setSelectedImages(selectedImages.filter((item) => item !== image));
    };


    return (
        <div>
            <Button variant="outlined" onClick={handleModalOpen}>
                Find images
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleModalClose}
                TransitionComponent={Transition}
                className='images-modal'
                id="image-search-modal"
            >

                <AppBar sx={{ position: 'relative', backgroundColor: "#00879a" }} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleModalClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Images
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitSelectedImages}>
                            Select
                        </Button>
                    </Toolbar>
                </AppBar>

                <Alert severity="info">Here you can find images for your update. After searching and selecting desired images please click select in top right corner of the page.</Alert>

                <div style={{ padding: "25px" }}>
                    <ImageSearcher
                        selectedImages={selectedImages}
                        addSelectedImage={addSelectedImage}
                        removeSelectedImage={removeSelectedImage}
                    />
                </div>

            </Dialog>
        </div>
    );
}

export default ImagesModal;