import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import DiploWebsitesService from '../services/DiploWebsitesService';
import SendIcon from '@mui/icons-material/Send';
import { Alert } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80vw",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SendToWebsiteModalSTARI extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            websitesData: null,
            availableTopics: [],
            selectedWebsite: "",
            selectedPostType: "",
            selectedPostStatus: "",
            selectedTopics: [],
        }
    }

    componentDidMount() {
        this.setState({
            websitesData: {
                "websites": DiploWebsitesService.getAvailableWebsites(),
                "postTypes": DiploWebsitesService.getPostTypes(),
                "availablePostStatuses": DiploWebsitesService.getAvailablePostStatuses(),
            }
        });
    }

    handleOpen = () => this.setState({ open: true });
    handleClose = () => this.setState({ open: false });

    handleWebsiteSelect = (event) => {
        this.setState({
            selectedTopics: [],
            selectedWebsite: event.target.value,
        });

        DiploWebsitesService.getTopics(event.target.value)
        .then((res) => {
            if (res.status != 200) {
                alert("Error getting website's topics");
            }

            let topics = JSON.parse(res.data);

            this.setState({
                availableTopics: topics,
            });
        })
        .catch((error) => {
            console.error(error);
            alert("Error getting topics from : " + event.target.value);
        });
    };

    handleTopicSelect = (event) => {
        const {
            target: { value },
        } = event;
        this.setState({
            selectedTopics: typeof value === 'string' ? value.split(',') : value,
        })
    };

    handlePostStatusSelect = (event) => {
        this.setState({
            selectedPostStatus: event.target.value
        });
    };

    handlePostTypeSelect = (event) => {
        this.setState({
            selectedPostType: event.target.value
        });
    };

    sendToWebSite = () => {

        let data = {
            "title": this.props.getGeneratedTitle(),
            "summary": this.props.getGeneratedSummary(),
            "excerpt": this.props.getGeneratedExcerpt(),
            "topics": this.state.selectedTopics,
            "postStatus": this.state.selectedPostStatus,
            "featuredImage": 
            {
                "url": this.props.getFeaturedImage(),
                "title": "TEST - Snapchat image",
                "alt": "TEST - Snapchat image - alt"
            }
        }

        DiploWebsitesService.sendPostToWebsite(this.state.selectedWebsite, this.state.selectedPostType, data)
        .then((res) => {
            alert("Successfully sent to website!");
            window.open(res.data.url, '_blank');
        })
        .catch((error) => {
            console.error(error);
            alert("Error sending to website!");
        });
    }

    render() {
        return (
            <>
                {/* <Button variant="contained" onClick={this.handleOpen}>Send to websites</Button> */}
                <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={this.handleOpen}
                    style={{ backgroundColor: "#00879a", float: "right" }}
                >
                    Send to websites
                </Button>

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='send-to-website-modal'
                >
                    <Box sx={style}>

                        <div className="post-preview">

                        </div>

                        <div className="post-meta">

                        </div>

                        <div className="post-options" style={{ maxWidth: "450px" }}>

                            <div className='card-container'>
                                <div className="card-container-header">
                                    <h2>
                                        Select website:
                                    </h2>
                                </div>
                                <hr />
                                <div className="results-generated-keypoints-body">

                                    <FormControl variant="standard" style={{ width: "300px" }}>
                                        <InputLabel id="post-status-select-label">Select destination website</InputLabel>
                                        <Select
                                            labelId="post-status-select-label"
                                            id="post-status-select"
                                            value={this.state.selectedWebsite}
                                            onChange={(event) => this.handleWebsiteSelect(event)}
                                        >
                                            {
                                                this.state.websitesData != null
                                                    ? this.state.websitesData.websites.map(
                                                        element => <MenuItem key={element} value={element}>{element}</MenuItem>
                                                    )
                                                    : <></>
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" style={{ width: "300px" }}>
                                        <InputLabel id="post-status-select-label">Status</InputLabel>
                                        <Select
                                            labelId="post-status-select-label"
                                            id="post-status-select"
                                            value={this.state.publishStatus}
                                            onChange={this.handlePostStatusSelect}
                                        >
                                            {
                                                this.state.websitesData != null
                                                    ? this.state.websitesData.availablePostStatuses.map(
                                                        element => <MenuItem key={element} value={element.value}>{element.display}</MenuItem>
                                                    )
                                                    : <></>
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" style={{ width: "300px" }}>
                                        <InputLabel id="demo-customized-select-label">Post Type</InputLabel>
                                        <Select
                                            labelId="post-type-select-label"
                                            id="post-type-select"
                                            value={this.state.postType}
                                            onChange={this.handlePostTypeSelect}
                                        >
                                            {
                                                this.state.websitesData != null
                                                    ? this.state.websitesData.postTypes.map(
                                                        element => <MenuItem value={element.value}>{element.display}</MenuItem>
                                                    )
                                                    : <></>
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className='card-container'>
                                <div className="card-container-header">
                                    <h2>
                                        Recomended topics:
                                    </h2>
                                </div>
                                <hr />
                                <div className="results-generated-keypoints-body">
                                    <ul>
                                        {
                                            this.props.getRecomendedTopics() != undefined &&
                                            this.props.getRecomendedTopics().map(topic => <li>{topic}</li>)
                                        }
                                    </ul>
                                </div>
                                <div className="results-generated-keypoints-body">
                                    <div style={{ display: "flex", flexWrap: "wrap", marginBlock: "20px" }}>
                                    </div>
                                    {
                                        this.state.availableTopics.length == 0
                                            ? <FormControl sx={{ m: 1, width: 300 }} disabled>
                                                <InputLabel id="topic-select-label">Topics</InputLabel>
                                                <Select
                                                    labelId="topic-select-label"
                                                    id="topic-select"
                                                    multiple
                                                >
                                                </Select>
                                            </FormControl>
                                            : <FormControl sx={{ m: 1, width: 300 }} >
                                                <InputLabel id="topic-select-label">Topics</InputLabel>
                                                <Select
                                                    labelId="topic-select-label"
                                                    id="topic-select"
                                                    multiple
                                                    value={this.state.selectedTopics}
                                                    onChange={this.handleTopicSelect}
                                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                    // renderValue={(selected) => (
                                                    //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    //         {selected.map((value) => (
                                                    //             <Chip key={value} label={value} />
                                                    //         ))}
                                                    //     </Box>
                                                    // )}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip
                                                                    key={value}
                                                                    label={this.state.availableTopics.find(item => item.term_id == value).name}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {
                                                        this.state.availableTopics != null && this.state.availableTopics.length != 0
                                                            ? this.state.availableTopics.map(
                                                                element => <MenuItem
                                                                    key={element.term_id}
                                                                    value={element.term_id}
                                                                >
                                                                    {element.name}
                                                                </MenuItem>
                                                            )
                                                            : <></>
                                                    }
                                                </Select>
                                            </FormControl>
                                    }
                                </div>
                            </div>

                        </div>

                        <Button variant="contained" endIcon={<SendIcon />} style={{ backgroundColor: "#00879a" }} onClick={this.sendToWebSite}>
                            Send
                        </Button>

                    </Box>
                </Modal>
            </>
        );
    }

}

export default SendToWebsiteModalSTARI;