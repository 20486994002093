import React,{useEffect,useRef} from 'react'
function Btn({Name,icon,link, click,...props}) {
  const ref=useRef(0)

  
  return (
    <a className="btn-grid-button" key={Math.random() *14} href={link} onClick={()=>click(props.id)}>
      
        <img src={`/img/${icon}`} alt="Ikonica1"/>
        <span className="btn-grid-button_text">{Name}</span>
    </a>
  )
}

export default Btn