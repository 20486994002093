import React, { Component } from 'react';
import Summarizer from '../components/Summarizer';

class SummaryPage extends Component {
    render() {
        return (
            <div className='summary-container'>
                <div className='summary-header'>
                    <h1>
                        Diplo Summarizer
                    </h1>
                    <h4>
                        Prepare content for AI summarisation
                    </h4>
                </div>
                <div className='summary-body'>
                    <Summarizer />
                </div>
            </div>
        );
    }
}

export default SummaryPage;