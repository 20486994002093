import React from 'react'
import Nav from '../Nav';
import { fetchDecodedData } from '../tools/Auth';

function AppWraper(props) {

  const user=fetchDecodedData()

  return (
    <div className='main-container-wrap'>
      {user?<Nav/>:''}
      <div className={props.customCss?'main-container '+props.customCss:'main-container '} >
        {props.layout}
      </div>
    </div>
  );
}

export default AppWraper