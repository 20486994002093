import React,{useState,useEffect,useRef} from 'react'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import NotiSingle from './NotiSingle';
import axios from 'axios';
import {fetchDecodedData} from '../tools/Auth'
function Notifications({list,setList}) {
    
    const [hasNot,setHasNot]=useState(false)
    const [opens, setOpens] = useState(false);
    

    const notWindow=useRef('')

    const onClick=()=>{
        setOpens(!opens);
        setHasNot(false)
        
    }

    const clearNoti=()=>{
        setHasNot(false)
        axios.get(process.env.REACT_APP_BACKEND_URL+'/notify/update/'+fetchDecodedData().email)
        setList([])
    }

    function handleClickOutside(event) {
        const btn=document.getElementById('noti')
       
        if (notWindow.current && !notWindow.current.contains(event.target)&&!btn.contains(event.target)) {

            setOpens(false);
            setHasNot(false)
        }
      }
    
    const checkNotification=()=>{
        axios.get(process.env.REACT_APP_BACKEND_URL+'/notify/'+fetchDecodedData().email).then(res=>{
            var layout=[]
            const response=res.data;

            for(var i=0;i<response.length;i++){
                let date = new Date(response[i]['created']);
                layout.push(<NotiSingle title={response[i]['title']} text={response[i]['text']} time={date.toLocaleString()} />)
            }
            if(layout.length>0){
                setHasNot(true)

            }
            setList(layout)
        }).catch(err=>console.log(err))
    }
    
    useEffect(() => {
      
        checkNotification()
     
    
      return () => {}
    }, [])
    
    useEffect(() => {

        const interval = setInterval(() => {

            checkNotification()

        }, 8000);
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            clearInterval(interval);
          };
    })
    
  return (
    <div id='noti'>
        <button className='btn-invisible' onClick={()=>onClick()}>
            {hasNot?(<NotificationImportantIcon style={{ color: '#01818c' }}/>):(<NotificationsNoneOutlinedIcon/>)}
        </button>
        {opens?(
            <div className="notiList">
                {list.length>0?(list):(<div className='no-noti' ref={notWindow}><SmartToyIcon style={{ color: 'lightgray',fontSize: 50}} />No notifications</div>)}
                <button className='noti-btn' onClick={()=>clearNoti()}>clear notifications</button>
            </div>
        ):null}
    </div>
  )
}

export default Notifications;