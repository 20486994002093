import axios from "axios"

class UserService {
  getUser(email){
    if(email.length>0){
      console.log(email);
      axios.get(process.env.REACT_APP_BACKEND_URL+'/users/'+email).then(data=>{
        return data.data
      }).catch(error=>{
        console.log(error);
      })
    }
    
  }
  setNotifyUser(email,app_id,data,title){

    axios.post(process.env.REACT_APP_BACKEND_URL+'/notify',{
      'email':email,
      'app_id':app_id,
      'data':data,
      'title':title
    }

    ).then(data=>console.log(data))
    .catch(error=>alert("Error sending notification to user:" + email + "!") )
  }

  getNotifications(){

    
  }
}

export default new UserService