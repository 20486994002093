import axios from 'axios';
import { fetchDecodedData } from '../../../tools/Auth';


function wrapSubstringInSpan(htmlContent, substring) {
    const regex = new RegExp(`(${substring})`, 'gi');
    return htmlContent.replace(regex, '<span>$1</span>');
}

/**
 * HemingwayService
 * 
 * This service is used to communicate with the Hemingway API.
 * 
 * @version 1.0.0
 * 
 * Posible types of recommendations:
 * passive_voice - 
 * adverb - 
 * complex - 
 * very hard - 
 * hard - 
 */

class HemingwayService {

    getContent() {
        let pom = `  
        <div> 
        <p>Businesses around the world are embracing digital transformation to improve profitability, revenue growth, and resilience Massive amounts of data have reshaped the relationship between consumers and businesses, and data breaches have become alarmingly common By prioritising cybersecurity and data privacy, businesses can navigate the evolving data landscape while complying with regulations and preserving customer trust.</p>
<p>In this era of rapid economic digitisation, businesses around the world are embracing digital transformation to improve profitability, revenue growth, and resilience. This transformation has resulted in a massive accumulation of data and the integration of networked endpoints into the physical world.</p>
<p>However, organisations are now facing new challenges in the form of cybersecurity threats and data privacy concerns. As the value of data continues to grow, it becomes increasingly critical for organisations to address these challenges and mitigate the risks associated with unauthorised access.</p>
<p>Fortifying Data Protection.</p>
<p>The availability of massive amounts of data has reshaped the relationship between consumers and businesses, prompting regulatory interventions and changes in privacy protection behaviour. It is projected that by 2025, an estimated 200 data privacy regulations will be in effect worldwide.</p>
<p>Unfortunately, data breaches have become alarmingly common, with billions of online customer accounts compromised. These circumstances highlight the urgency of addressing cybersecurity and data privacy issues.</p>
<p>As cybersecurity threats continue to evolve, organisations are becoming increasingly vulnerable. Hackers employ sophisticated tactics to breach security systems, often targeting weaker organisations as gateways to more secure entities.</p>
<p>This interconnected data ecosystem poses inherent challenges, as the security of an individual or company relies on the least secure entity interacting with their data. As organisations undergo digital transformation, they rely on technology that enables seamless connectivity.</p>
<p>Cloud platforms, mobile devices, and the Internet of Things (IoT) offer innovative work methodologies and enable remote operations. However, these technologies operate within a complex network of interconnected endpoints and servers. Safeguarding data across this expansive landscape requires employing security tools specifically designed for this extended ecosystem.</p>
<p>Some essential tools for securing digital transformation technologies include:</p>
<p>Security Information and Event Management (SIEM): SIEM systems provide real-time monitoring, analysis, and correlation of security events across an organisation's digital infrastructure. By aggregating and analysing logs from various sources, SIEM helps detect and respond to security incidents, identifies patterns of malicious activity, and enables proactive threat management.</p>
<p>Encryption: Implementing data protection measures during transit and at rest, especially when data is shared, safeguards its confidentiality and integrity.</p>
<p>Authentication: Robust access controls are crucial to ensure secure operations. Utilising multi-factor authentication or applying risk-based rules to adjust credential requirements based on specific conditions enhances security.</p>
<p>Endpoint Security: Employing endpoint detection and response (EDR) tools on endpoint devices helps identify signs of cyber-attacks, enabling prompt mitigation and response.</p>
<p>Identity and Access Management: Controlling access to data and IT resources is fundamental for securing any system. In distributed services interconnected via APIs, a robust identity approach is vital to maintaining effective security controls.</p>
<p>Managing Third-Party Risks &amp; Data Privacy.</p>
<p>Modern businesses rely on intricate ecosystems of interconnected entities, including third parties. This expanding utilisation of third-party relationships introduces additional risks that demand a sophisticated approach to third-party risk management.</p>
<p>When an organisation grants access to its internal assets and resources to third parties, the security of its data can depend, to some extent, on the practices employed by fourth-party entities. If hackers breach an organisation within the vendor network of one of the third parties, the data that the compromised company can access becomes vulnerable. Consequently, your organisation may be held responsible for a breach that occurred at the vendor level.</p>
<p>Ensuring robust data security requires a thorough understanding of how your third parties protect your data and recognising the potential vulnerabilities introduced by their own networks of third-party relationships. By proactively assessing and managing these risks, organisations can minimise the impact of data breaches and protect the confidentiality and integrity of their sensitive information.</p>
<p>The interconnected nature of third-party relationships highlights the importance of integrating robust third-party risk management practices with Privacy by design principles to ensure comprehensive data security and privacy in the era of digital transformation.</p>
<p>Privacy by design plays a crucial role in ensuring that digitally transformed architectures align with an organisation's regulatory requirements. Conducting a Privacy Impact Assessment (PIA) or a Data Protection Impact Assessment (DPIA) allows organisations to test their transformed infrastructures and verify their compliance with regulatory standards.</p>
<p>Leaders in digital transformation are adopting new approaches to privacy management by incorporating privacy into the design of digital solutions. These principles prioritise building privacy-preserving aspects into technology from the start. Techniques such as pseudonymisation, encryption, data minimisation, transparency, and consent-based approaches empower users and reduce the risk of privacy violations.
        </p>
<p>In the digital era, safeguarding data through cybersecurity measures and data privacy practices is paramount. Organisations must establish well-architected programmes to manage third-party cyber risks, ensuring effective supervision, proactive analysis, and secure cloud services.</p>
<p>Simultaneously, privacy must be integrated into the design of digital solutions, embracing techniques such as pseudonymization, encryption, data minimization, transparency, and consent-based data collection. By prioritising cybersecurity and data privacy, businesses can navigate the evolving data landscape while complying with regulations and preserving customer trust.</p>
</div>
`

        return pom;
    }

    getHemingwayAnalysis(text) {
        return axios.post(process.env.REACT_APP_NIMANI_HEMINGWAY_ANALYSIS,
            {
                "p": text
            }
        );
    }

    getHemingwayAnalysisHARDCODEDTEST(text) {
        let hemingwayAnalysisData = {
            "output_data": [
                {
                    "part": "alarmingly",
                    "start": 241,
                    "end": 251,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "Businesses around the world are embracing digital transformation to improve profitability, revenue growth, and resilience Massive amounts of data have reshaped the relationship between consumers and businesses, and data breaches have become alarmingly common By prioritising cybersecurity and data privacy, businesses can navigate the evolving data landscape while complying with regulations and preserving customer trust.",
                    "start": 0,
                    "end": 422,
                    "type": "very hard",
                    "recommendation": "Businesses around the world are adapting to digital transformation in order to increase their profits, revenue and resilience. There is now a strong connection between customers and businesses, which is based on data. However, data breaches have become increasingly frequent. Companies can protect themselves and meet regulatory requirements by prioritising cybersecurity and data privacy. This ensures customer trust is maintained."
                },
                {
                    "part": "In this era of rapid economic digitisation, businesses around the world are embracing digital transformation to improve profitability, revenue growth, and resilience.",
                    "start": 423,
                    "end": 589,
                    "type": "very hard",
                    "recommendation": "In this digital age, businesses around the globe are taking advantage of digital transformation to increase their profits, revenue, and sustainability. This transformation can help them stay competitive and profitable in the long-term."
                },
                {
                    "part": "This transformation has resulted in a massive accumulation of data and the integration of networked endpoints into the physical world.",
                    "start": 590,
                    "end": 724,
                    "type": "very hard",
                    "recommendation": "The result of this transformation is an enormous amount of data and the connection of multiple endpoints to the physical world."
                },
                {
                    "part": "however",
                    "start": 725,
                    "end": 732,
                    "type": "complex",
                    "recommendation": [
                        "but",
                        "yet"
                    ]
                },
                {
                    "part": "However, organisations are now facing new challenges in the form of cybersecurity threats and data privacy concerns.",
                    "start": 725,
                    "end": 841,
                    "type": "very hard",
                    "recommendation": "Organisations are now facing new challenges such as cybersecurity threats and data privacy concerns. These issues are forcing organisations to adjust their approach to ensure the safety and security of their data."
                },
                {
                    "part": "increasingly",
                    "start": 893,
                    "end": 905,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "As the value of data continues to grow, it becomes increasingly critical for organisations to address these challenges and mitigate the risks associated with unauthorised access.",
                    "start": 842,
                    "end": 1020,
                    "type": "very hard",
                    "recommendation": "As data becomes increasingly valuable, organizations must take steps to reduce the risks of unauthorized access. It is essential to address these issues and protect against potential threats. The value of data is growing, and organizations must do their best to prevent unauthorized access."
                },
                {
                    "part": "The availability of massive amounts of data has reshaped the relationship between consumers and businesses, prompting regulatory interventions and changes in privacy protection behaviour.",
                    "start": 1049,
                    "end": 1236,
                    "type": "very hard",
                    "recommendation": "The abundance of data has altered the connection between consumers and businesses, leading to regulatory steps and changes in privacy protection practices."
                },
                {
                    "part": "is projected",
                    "start": 1240,
                    "end": 1252,
                    "type": "passive_voice",
                    "recommendation": null,
                    "description": "Use active voice"
                },
                {
                    "part": "It is projected that by 2025, an estimated 200 data privacy regulations will be in effect worldwide.",
                    "start": 1237,
                    "end": 1337,
                    "type": "hard",
                    "recommendation": "It is projected that by 2025, there will be an estimated 200 data privacy regulations in effect across the world. This is a significant increase from current levels, and highlights the importance of data privacy regulations in the modern world."
                },
                {
                    "part": "alarmingly",
                    "start": 241,
                    "end": 251,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "Unfortunately, data breaches have become alarmingly common, with billions of online customer accounts compromised.",
                    "start": 1338,
                    "end": 1452,
                    "type": "very hard",
                    "recommendation": "Data breaches have become increasingly frequent, leading to billions of online customer accounts being compromised. This is a worrying trend that needs to be addressed."
                },
                {
                    "part": "increasingly",
                    "start": 893,
                    "end": 905,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "employ",
                    "start": 1653,
                    "end": 1659,
                    "type": "complex",
                    "recommendation": [
                        "use"
                    ]
                },
                {
                    "part": "Hackers employ sophisticated tactics to breach security systems, often targeting weaker organisations as gateways to more secure entities.",
                    "start": 1645,
                    "end": 1783,
                    "type": "very hard",
                    "recommendation": "Hackers often target weaker organisations as a way to gain access to more secure entities. They use sophisticated tactics to breach security systems."
                },
                {
                    "part": "This interconnected data ecosystem poses inherent challenges, as the security of an individual or company relies on the least secure entity interacting with their data.",
                    "start": 1784,
                    "end": 1952,
                    "type": "very hard",
                    "recommendation": "Interconnected data ecosystems present their own unique challenges. Security of individuals and companies depends on the security of the weakest partner in their data interactions."
                },
                {
                    "part": "Cloud platforms, mobile devices, and the Internet of Things (IoT) offer innovative work methodologies and enable remote operations.",
                    "start": 2062,
                    "end": 2193,
                    "type": "very hard",
                    "recommendation": "Cloud platforms, mobile devices, and the Internet of Things (IoT) can revolutionize the way we work. They allow us to work remotely and utilize innovative methods."
                },
                {
                    "part": "however",
                    "start": 725,
                    "end": 732,
                    "type": "complex",
                    "recommendation": [
                        "but",
                        "yet"
                    ]
                },
                {
                    "part": "specifically",
                    "start": 2380,
                    "end": 2392,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "employ",
                    "start": 1653,
                    "end": 1659,
                    "type": "complex",
                    "recommendation": [
                        "use"
                    ]
                },
                {
                    "part": "require",
                    "start": 2346,
                    "end": 2353,
                    "type": "complex",
                    "recommendation": [
                        "must",
                        "need"
                    ]
                },
                {
                    "part": "Safeguarding data across this expansive landscape requires employing security tools specifically designed for this extended ecosystem.",
                    "start": 2296,
                    "end": 2430,
                    "type": "very hard",
                    "recommendation": "Protecting data in this vast area requires the use of security tools that are specifically designed for this extended environment. These tools provide an extra layer of protection for data across the landscape."
                },
                {
                    "part": "monitor",
                    "start": 2591,
                    "end": 2598,
                    "type": "complex",
                    "recommendation": [
                        "check",
                        "watch",
                        "track"
                    ]
                },
                {
                    "part": "Some essential tools for securing digital transformation technologies include: Security Information and Event Management (SIEM): SIEM systems provide real-time monitoring, analysis, and correlation of security events across an organisation’s digital infrastructure.",
                    "start": 2431,
                    "end": 2696,
                    "type": "very hard",
                    "recommendation": "Security Information and Event Management (SIEM) is an essential tool for securing digital transformation technologies. It provides real-time monitoring, analysis, and correlation of security events across an organisation's digital infrastructure. This helps to protect digital transformation technologies from potential threats."
                },
                {
                    "part": "By aggregating and analysing logs from various sources, SIEM helps detect and respond to security incidents, identifies patterns of malicious activity, and enables proactive threat management.",
                    "start": 2697,
                    "end": 2889,
                    "type": "very hard",
                    "recommendation": "Security Information and Event Management (SIEM) helps to detect and respond to security incidents. It does this by aggregating and analysing logs from different sources. SIEM also identifies patterns of malicious activity and can be used for proactive threat management."
                },
                {
                    "part": "implement",
                    "start": 2902,
                    "end": 2911,
                    "type": "complex",
                    "recommendation": [
                        "install",
                        "put in place",
                        "tool"
                    ]
                },
                {
                    "part": "is shared",
                    "start": 2989,
                    "end": 2998,
                    "type": "passive_voice",
                    "recommendation": null,
                    "description": "Use active voice"
                },
                {
                    "part": "Encryption: Implementing data protection measures during transit and at rest, especially when data is shared, safeguards its confidentiality and integrity.",
                    "start": 2890,
                    "end": 3045,
                    "type": "very hard",
                    "recommendation": "Data protection is important to maintain the confidentiality and integrity of information. Encryption is one way to do this. It helps secure data when it is in transit or at rest, especially if it is being shared with others."
                },
                {
                    "part": "require",
                    "start": 2346,
                    "end": 2353,
                    "type": "complex",
                    "recommendation": [
                        "must",
                        "need"
                    ]
                },
                {
                    "part": "requirement",
                    "start": 3214,
                    "end": 3225,
                    "type": "complex",
                    "recommendation": [
                        "need",
                        "rule"
                    ]
                },
                {
                    "part": "just",
                    "start": 3198,
                    "end": 3202,
                    "type": "qualifier"
                },
                {
                    "part": "Utilising multi-factor authentication or applying risk-based rules to adjust credential requirements based on specific conditions enhances security.",
                    "start": 3126,
                    "end": 3274,
                    "type": "very hard",
                    "recommendation": "Utilizing multi-factor authentication or applying risk-based rules can enhance security. These rules can adjust credential requirements based on specific conditions."
                },
                {
                    "part": "employ",
                    "start": 1653,
                    "end": 1659,
                    "type": "complex",
                    "recommendation": [
                        "use"
                    ]
                },
                {
                    "part": "Endpoint Security: Employing endpoint detection and response (EDR) tools on endpoint devices helps identify signs of cyber-attacks, enabling prompt mitigation and response.",
                    "start": 3275,
                    "end": 3447,
                    "type": "very hard",
                    "recommendation": "Endpoint security can help identify and mitigate cyber-attacks. This is achieved through the use of endpoint detection and response (EDR) tools on endpoint devices. These tools enable quick response times for any signs of attack."
                },
                {
                    "part": "Identity and Access Management: Controlling access to data and IT resources is fundamental for securing any system.",
                    "start": 3448,
                    "end": 3563,
                    "type": "very hard",
                    "recommendation": "Identity and Access Management is an essential part of any system security. It involves controlling access to data and IT resources. This ensures that only authorized users have access to sensitive information."
                },
                {
                    "part": "In distributed services interconnected via APIs, a robust identity approach is vital to maintaining effective security controls.",
                    "start": 3564,
                    "end": 3692,
                    "type": "very hard",
                    "recommendation": "Distributed services that are connected with Application Programming Interfaces (APIs) require a reliable identity system to ensure secure control. This is vital for maintaining an effective security system."
                },
                {
                    "part": "additional",
                    "start": 3903,
                    "end": 3913,
                    "type": "complex",
                    "recommendation": [
                        "more",
                        "extra"
                    ]
                },
                {
                    "part": "This expanding utilisation of third-party relationships introduces additional risks that demand a sophisticated approach to third-party risk management.",
                    "start": 3836,
                    "end": 3988,
                    "type": "very hard",
                    "recommendation": "The increasing use of third-party relationships brings with it extra risks. A sophisticated approach to managing these risks is necessary."
                },
                {
                    "part": "employ",
                    "start": 1653,
                    "end": 1659,
                    "type": "complex",
                    "recommendation": [
                        "use"
                    ]
                },
                {
                    "part": "When an organisation grants access to its internal assets and resources to third parties, the security of its data can depend, to some extent, on the practices employed by fourth-party entities.",
                    "start": 3989,
                    "end": 4183,
                    "type": "very hard",
                    "recommendation": "The security of an organisation's data can depend on the practices of fourth-party entities when it grants access to its internal assets and resources to third parties. Breaking this into simpler terms, when organisations allow outside parties to access their data, the security of this data can be affected by the practices of others."
                },
                {
                    "part": "If hackers breach an organisation within the vendor network of one of the third parties, the data that the compromised company can access becomes vulnerable.",
                    "start": 4184,
                    "end": 4341,
                    "type": "very hard",
                    "recommendation": "Hackers can pose a danger to data security if they manage to breach an organisation within the vendor network of a third party. If this happens, the data that the compromised company can access becomes exposed and vulnerable."
                },
                {
                    "part": "consequently",
                    "start": 4342,
                    "end": 4354,
                    "type": "complex",
                    "recommendation": [
                        "so"
                    ]
                },
                {
                    "part": "be held",
                    "start": 4378,
                    "end": 4385,
                    "type": "passive_voice",
                    "recommendation": null,
                    "description": "Use active voice"
                },
                {
                    "part": "Consequently, your organisation may be held responsible for a breach that occurred at the vendor level.",
                    "start": 4342,
                    "end": 4445,
                    "type": "hard",
                    "recommendation": "Your organization may be held accountable for any breach that happens at the vendor's level. This means that any breach resulting from the vendor's actions could be your organization's responsibility."
                },
                {
                    "part": "require",
                    "start": 2346,
                    "end": 2353,
                    "type": "complex",
                    "recommendation": [
                        "must",
                        "need"
                    ]
                },
                {
                    "part": "Ensuring robust data security requires a thorough understanding of how your third parties protect your data and recognising the potential vulnerabilities introduced by their own networks of third-party relationships.",
                    "start": 4446,
                    "end": 4662,
                    "type": "very hard",
                    "recommendation": "To ensure robust data security, it is necessary to understand how your third parties protect your data. Additionally, you must identify any potential risks that arise from their network of third-party relationships."
                },
                {
                    "part": "proactively",
                    "start": 4666,
                    "end": 4677,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "By proactively assessing and managing these risks, organisations can minimise the impact of data breaches and protect the confidentiality and integrity of their sensitive information.",
                    "start": 4663,
                    "end": 4846,
                    "type": "very hard",
                    "recommendation": "Organisations can reduce the potential damage of data breaches by proactively assessing and managing risks. This helps to ensure the confidentiality and integrity of sensitive information."
                },
                {
                    "part": "The interconnected nature of third-party relationships highlights the importance of integrating robust third-party risk management practices with Privacy by design principles to ensure comprehensive data security and privacy in the era of digital transformation.",
                    "start": 4847,
                    "end": 5109,
                    "type": "very hard",
                    "recommendation": "The interconnectivity of third-party associations emphasizes the need for incorporating effective third-party risk management practices with Privacy by design principles. This is to guarantee total data security and privacy in the digital transformation era."
                },
                {
                    "part": "digitally",
                    "start": 5166,
                    "end": 5175,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "require",
                    "start": 2346,
                    "end": 2353,
                    "type": "complex",
                    "recommendation": [
                        "must",
                        "need"
                    ]
                },
                {
                    "part": "requirement",
                    "start": 3214,
                    "end": 3225,
                    "type": "complex",
                    "recommendation": [
                        "need",
                        "rule"
                    ]
                },
                {
                    "part": "Privacy by design plays a crucial role in ensuring that digitally transformed architectures align with an organisation’s regulatory requirements.",
                    "start": 5110,
                    "end": 5255,
                    "type": "very hard",
                    "recommendation": "Privacy by design is essential for digital transformations to comply with an organization's regulatory requirements. It is essential to ensure that the architecture aligns with the organization's regulations. This is key for successful digital transformation."
                },
                {
                    "part": "Conducting a Privacy Impact Assessment (PIA) or a Data Protection Impact Assessment (DPIA) allows organisations to test their transformed infrastructures and verify their compliance with regulatory standards.",
                    "start": 5256,
                    "end": 5464,
                    "type": "very hard",
                    "recommendation": "Conducting a Privacy Impact Assessment (PIA) or a Data Protection Impact Assessment (DPIA) can help organisations ensure that their updated infrastructure is compliant with regulatory standards. Testing the infrastructure in this way can verify that the organisation is adhering to the proper regulations."
                },
                {
                    "part": "Leaders in digital transformation are adopting new approaches to privacy management by incorporating privacy into the design of digital solutions.",
                    "start": 5465,
                    "end": 5611,
                    "type": "very hard",
                    "recommendation": "Leaders in digital transformation are taking new steps to manage privacy. They are including privacy as a part of the design process for digital solutions. This helps ensure that privacy is considered at every stage of the development process."
                },
                {
                    "part": "Techniques such as pseudonymisation, encryption, data minimisation, transparency, and consent-based approaches empower users and reduce the risk of privacy violations.",
                    "start": 5708,
                    "end": 5875,
                    "type": "very hard",
                    "recommendation": "Techniques such as pseudonymisation, encryption, data minimisation, transparency and consent-based approaches can help protect user privacy and reduce the risk of violations. These techniques give users more control over their data and empower them to make informed decisions."
                },
                {
                    "part": "In the digital era, safeguarding data through cybersecurity measures and data privacy practices is paramount.",
                    "start": 5876,
                    "end": 5985,
                    "type": "very hard",
                    "recommendation": "Protecting data in the digital age is essential. Cybersecurity measures and data privacy practices are essential for ensuring data safety."
                },
                {
                    "part": "Organisations must establish well-architected programmes to manage third-party cyber risks, ensuring effective supervision, proactive analysis, and secure cloud services.",
                    "start": 5986,
                    "end": 6156,
                    "type": "very hard",
                    "recommendation": "Organisations should create a well-structured program to manage cyber risks from third parties. This should include effective oversight, proactive analysis, and secure cloud services."
                },
                {
                    "part": "Simultaneously",
                    "start": 6157,
                    "end": 6171,
                    "type": "adverb",
                    "recommendation": null,
                    "description": "Use a forceful verb"
                },
                {
                    "part": "be integrated",
                    "start": 6186,
                    "end": 6199,
                    "type": "passive_voice",
                    "recommendation": null,
                    "description": "Use active voice"
                },
                {
                    "part": "Simultaneously, privacy must be integrated into the design of digital solutions, embracing techniques such as pseudonymization, encryption, data minimization, transparency, and consent-based data collection.",
                    "start": 6157,
                    "end": 6364,
                    "type": "very hard",
                    "recommendation": "Privacy must be included in the design of digital solutions. This can be achieved through the use of pseudonymization, encryption, data minimization, transparency and consent-based data collection. All of these techniques must be used together to ensure effective privacy protection."
                },
                {
                    "part": "By prioritising cybersecurity and data privacy, businesses can navigate the evolving data landscape while complying with regulations and preserving customer trust.",
                    "start": 259,
                    "end": 422,
                    "type": "very hard",
                    "recommendation": "By focusing on cybersecurity and data privacy, businesses can stay up-to-date on the ever-changing data landscape. This will help them to stay compliant with regulations and also keep customers' trust."
                }
            ],
            "data": {
                "paragraphs": 26,
                "sentences": 43,
                "words": 848,
                "hardSentences": 2,
                "veryHardSentences": 32,
                "adverbs": 9,
                "passiveVoice": 4,
                "complex": 16
            }
        }

        return this.addIdsToHemingwayAnalysisData(hemingwayAnalysisData);
    }

    addIdsToHemingwayAnalysisData(hemingwayAnalysisData) {
        let i = 0;
        hemingwayAnalysisData["output_data"].forEach(element => {
            element["id"] = i;
            i++;
        });

        return hemingwayAnalysisData;
    }

    // addRecommendationDataToText(text, hemingwayAnalysis) {

    //     console.log("<===== pocetak =====>")
    //     let i = 0;

    //     hemingwayAnalysis["output_data"].forEach(element => {
    //         if(i == 1){
    //             text = this.addDiploTag(text, element)
    //         }
    //         i++;
    //     });

    //     console.log("<===== kraj =====>")
    //     return text;
    // }

    // addDiploTag(text, element) {

    //     console.log(element)

    //     // split the string at the point(s) where the search term is present.
    //     // let split = text.toLowerCase().split(element["part"].toLowerCase());
    //     let split = text.split(element["part"]);

    //     // create a placeholder string.
    //     let ttt = "";
    //     // loop through the splited string and put in the search term after each one and wrap it in a span with a class 'highlight' unless it is the last one.
    //     for (let i = 0; i < split.length; i++) {
    //         if (i === split.length - 1) {
    //             ttt += split[i];
    //         } else {

    //             let data = encodeURIComponent(JSON.stringify(element));

    //             // ttt += `${split[i]}${element["part"]}1`;
    //             ttt += `${split[i]} <diplo-highlight data="${data}">${element["part"]}</diplo-highlight>`;

    //             // ttt += `${split[i]} <span class="highlight">${element["part"]}</span>`;
    //         }
    //     }
    //     //return the string as HTML.
    //     return ttt;

    // }

    addRecommendationDataToText(text, hemingwayAnalysis) {

        hemingwayAnalysis["output_data"].forEach(element => {
            text = this.addDiploTag(text, element)
        });

        return text;
    }

    addDiploTag(htmlText, surroundingData) {
        // function highlightText(htmlText, surroundingData) {
        const { part, start, end } = surroundingData;

        let currentIndex = 0; // Current index within the HTML string
        let textIndex = 0; // Current index within the text (excluding HTML tags)
        let textStartPosition = -1;
        let textEndPosition = -1;
        let withinTag = false;

        // Traverse the HTML string and identify the start and end positions of the text
        while (currentIndex < htmlText.length) {
            if (htmlText[currentIndex] === "<") {
                withinTag = true;
            } else if (htmlText[currentIndex] === ">") {
                withinTag = false;
            } else if (!withinTag) {
                if (textIndex === start) {
                    textStartPosition = currentIndex;
                }

                if (textIndex === end) {
                    textEndPosition = currentIndex;
                    break;
                }

                textIndex++;
            }

            currentIndex++;
        }

        if (textStartPosition !== -1 && textEndPosition !== -1 && textStartPosition < textEndPosition) {

            let data = encodeURIComponent(JSON.stringify(surroundingData));
            let type = surroundingData["type"] == "very hard" ? "very-hard" : surroundingData["type"];

            // Surround the matched text with <diplo-highlight> tags
            const highlightedText = htmlText.substring(0, textStartPosition) +
                `<diplo-highlight id="diplo-highlight-${surroundingData["id"]}" type="${type}" data="${data}">${htmlText.substring(textStartPosition, textEndPosition)}</diplo-highlight>` +
                htmlText.substring(textEndPosition);

            return highlightedText;
        }

        return htmlText; // If the surrounding data is invalid, return the original HTML string
    }

    createPopupText = (data) => {
        switch (data["type"]) {
            case "very hard":
                return <>
                    <h5>
                        Very hard
                    </h5>
                    <p>
                        This sentence is very hard to read. Try our recommendation:
                    </p>
                </>
            case "hard":
                return <>
                    <h5>
                        Hard
                    </h5>
                    <p>
                        This sentence is hard to read. Try our recommendation:
                    </p>
                </>
            case "passive_voice":
                return <>
                    <h5>
                        Passive Voice
                    </h5>
                    <p>
                        Passive Voice
                    </p>
                </>
            case "adverb":
                return <>
                    <h5>
                        Adverb
                    </h5>
                    <p>
                        adverb
                    </p>
                </>
            default:
                return "TEST"
        }
    }

    getGroupsOfSuggestionsIncluded = () => {
        return ["very-hard", "hard", "passive_voice", "adverb", "complex"];
    }

    extractNumberFromDiploHighlight = (text) => {
        const matches = text.match(/^diplo-highlight-(\d+)$/); // Matches the pattern "diplo-highlight-number"
      
        if (matches) {
          return parseInt(matches[1]);
        }
      
        return null; // If no match is found, return null or any other default value you prefer
      }

}

export default new HemingwayService;