import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DiploWebsitesService from '../../../services/DiploWebsitesService';
import { isDisabled } from '@testing-library/user-event/dist/utils';

function AddWebsiteData(props) {

    // Available websites, post types and post statuses    
    const availableWebsites = DiploWebsitesService.getAvailableWebsites();
    const availablePostTypes = DiploWebsitesService.getPostTypes();
    const availablePostStatuses = DiploWebsitesService.getAvailablePostStatuses();

    // Selected website, post type and post status
    const [selectedWebsite, setSelectedWebsite] = React.useState(props.websiteData != null ? props.websiteData.selectedWebsite : "");
    const [selectedPostStatus, setSelectedPostStatus] = React.useState(props.websiteData != null ? props.websiteData.selectedPostStatus : "");
    const [selectedPostType, setSelectedPostType] = React.useState(props.websiteData != null ? props.websiteData.selectedPostType : "");
    
    
    const isDisabledNextButton = () => {
        if(selectedWebsite != "" && selectedPostStatus != "" && selectedPostType != "") return false;
        return true;
    }

    // Disable next button if not all fields are filled
    const [disableNextButton, setDisableNextButton] = React.useState(isDisabledNextButton());


    const handleNext = () => {
        props.setWebsiteData({
            selectedWebsite: selectedWebsite,
            selectedPostStatus: selectedPostStatus,
            selectedPostType: selectedPostType
        })
        props.handleNext();
    };

    const handleInputChange = (event, inputName) => {
        switch (inputName) {
            case "selectedWebsite":
                if(selectedPostStatus != "" && selectedPostType != "") setDisableNextButton(false);
                setSelectedWebsite(event.target.value);
                break;
            case "selectedPostStatus":
                if(selectedWebsite != "" && selectedPostType != "") setDisableNextButton(false);
                setSelectedPostStatus(event.target.value);
                break;
            case "selectedPostType":
                if(selectedWebsite != "" && selectedPostStatus != "") setDisableNextButton(false);
                setSelectedPostType(event.target.value);
                break;
            default:
                break;
        }
    }

    return (
        <>

            <div className="send-to-website-container website-data">
                <div className="send-to-website-header">
                    <h2>
                        Select destination website:
                    </h2>
                </div>
                <hr className="send-to-website-subheading" />
                <div className="send-to-website-body">

                    <FormControl>
                        <InputLabel id="select-website-label">Website</InputLabel>
                        <Select
                            labelId="select-website-label"
                            id="select-website"
                            label="Website"
                            value={selectedWebsite}
                            onChange={(event) => handleInputChange(event, "selectedWebsite")}
                        >
                            {
                                availableWebsites != null &&
                                availableWebsites.map(
                                    element => <MenuItem key={element} value={element}>{element}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id="post-status-select-label">Status</InputLabel>
                        <Select
                            labelId="post-status-select-label"
                            id="post-status-select"
                            label="Status"
                            value={selectedPostStatus}
                            onChange={(event) => handleInputChange(event, "selectedPostStatus")}
                        >
                            {
                                availablePostStatuses != null &&
                                availablePostStatuses.map(
                                    element => <MenuItem key={element.value} value={element.value}>{element.display}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id="post-type-select-label">Post Type</InputLabel>
                        <Select
                            labelId="post-type-select-label"
                            id="post-type-select"
                            label="Post Type"
                            value={selectedPostType}
                            onChange={(event) => handleInputChange(event, "selectedPostType")}
                        >
                            {
                                availablePostTypes != null &&
                                availablePostTypes.map(
                                    element => <MenuItem key={element.value} value={element.value}>{element.display}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>

                </div>
            </div>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button onClick={handleNext} disabled={disableNextButton}>
                    Next
                </Button>
            </Box>

        </>
    );
}

export default AddWebsiteData;