import React,{ useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function MapModal({list,open,handleClose,country}) {
  
    

  return (
   
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='student-number-count'>
            {Object.keys(list).length>0&&(list.country)}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 440,overflow:'auto' }} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>

                    {Object.keys(list).length>0&&(list.students.map((row) => (
                        <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.Name +" "+row.LastName }
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {row.Email}
                        </TableCell>
                        </TableRow>
                    )))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Paper>
          </Typography>
        </Box>
      </Modal>
  
  );
}