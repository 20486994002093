import React from 'react'
import ListSingle from './ListSingle'
import { useEffect,useState } from 'react'
import axios from 'axios'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function List(props) {
    const [lists,setLists]=useState([])
    useEffect(() => {
        const li=[]
      for (let index = 0; index < 10; index++) {
        
        li.push(<ListSingle name={index} place={index} sector={index}/>)
        setLists(li)
      }
    
    }, [])
    const handleChange = (event, value) => {
      props.setPage(value);
    };
  return (
    <>
    {/* <div className='box list-stat-wrap'> */}
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name:</TableCell>
            <TableCell >Location</TableCell>
            <TableCell >Country of origin</TableCell>
            <TableCell >Sector</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(props.data['email']).map((e,val) => (
            <TableRow
              key={val}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell >{props.data['name'][e]+' '+props.data['lastName'][e]}</TableCell>
              <TableCell >{props.data['location'][e]}</TableCell>
              <TableCell >{props.data['nationality'][e]}</TableCell>
              <TableCell >{props.data['sector'][e]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      <div className='list-controls'>
      <Stack spacing={2}>
        <Pagination count={props.count} color='primary' className='list-pag'  onChange={handleChange}/>
      </Stack>
      </div>
    {/* </div> */}
    </>
  )
}

export default List