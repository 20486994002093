import React from 'react'
import Filter from '../components/Filter'
import List from '../components/List'
import '../assets/css/stat_style.css'

import { useState,useEffect } from 'react'
import axios from 'axios'
function MainStat() {
  const [data,setData]=useState(false)
  const [hideDownload,sethideDownload]=useState(false)
  const [page,setPage]=useState(0)
  const [page_count,setPageCount]=useState(1)
  const [size,setSize]=useState('')

  const [name,setName]=useState('')
  const [lastname,setlastname]=useState('')
  const [course,setCourse]=useState('All')

  const [year,setYear]=useState('All')
  const [loc,setLoc]=useState('All')
  const [nat,setNat]=useState('All')
  const [gender,setGender]=useState('Any')
  const [alumni,setAlumni]=useState('')
  const [sect,setSect]=useState('All')
  
  const send=(...data)=>{
    console.log(data[0]);

    axios.post(process.env.REACT_APP_STUDENT_API+'/students/list',data[0]).then(data=>{
      setData(data.data['students']);
      setPageCount(data.data['count'])
      console.log(data.data['size']);
      setSize(data.data['size'])
    })
  }

  const download=()=>{

    var name_new=name
    var lastname_new=lastname
    if(name_new.length<3){
      name_new=''
      
    }

    if(lastname.length<3){
      lastname_new=''
      
    }

    const payload={
      'name':name_new,
      'lastName':lastname_new,
      'course':course!='All'?course:'',
      'location':loc!='All'?loc:'',
      'nationality':nat!='All'?nat:'',
      'sector':sect!='All'?sect:'',
      'gender':gender!='Any'?gender:'',
      'year':year!='All'?year:'',
      'alumni':alumni,
      'page':0,
      'offset':0
    }
    axios({
      url: process.env.REACT_APP_STUDENT_API+'/students/download', //your url
      method: 'POST',
      responseType: 'blob', // important
      data:payload
    }).then((res) => {
        // create file link in browser's memory
        const href = URL.createObjectURL("\ufeff"+res.data);
        console.log(href);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
  }

  function search(formData=null) {
    let offset=0
    
    if(formData){

      formData.preventDefault();
    }else{
      if(page>1){
        for (let index = 1; index < page; index++) {
          
          offset+=15;
        }

      }
    }
    var name_new=name
    var lastname_new=lastname
    if(name_new.length<3){
      name_new=''
      
    }

    if(lastname.length<3){
      lastname_new=''
      
    }


    
    const payload={
      'name':name_new,
      'lastName':lastname_new,
      'course':course!='All'?course:'',
      'location':loc!='All'?loc:'',
      'nationality':nat!='All'?nat:'',
      'sector':sect!='All'?sect:'',
      'gender':gender!='Any'?gender:'',
      'year':year!='All'?year:'',
      'alumni':alumni,
      'page':page,
      'offset':offset
    }

    send(payload)
  }

  useEffect(() => {
    if (page)
      search()
  
  }, [page])
  
  useEffect(() => {
    var name_new=name
    var lastname_new=lastname
    if(name_new.length<3){
      name_new=''
      
    }

    if(lastname.length<3){
      lastname_new=''
      
    }
    if (course=='All'&&loc=='All'&&nat=='All'&&sect=='All'&&year=='All'&&gender=='Any'&&name_new==''&&lastname_new==''){
      sethideDownload(false)

    }else{
      console.log(course, loc ,nat,sect,year,gender,name_new,lastname_new);

      sethideDownload(true)

    }
  
  }, [data])

  return (
    <div id='main-stat'>
        <div className="map-title">
          <a href='/student-statistics'>Back</a>
          <div>
            <h1>Student Table Statistics View</h1>
          </div>
        </div>
        <Filter search={search} download={download} hideDownload={hideDownload} setName={setName} setlastname={setlastname} setSect={setSect} setYear={setYear} setCourse={setCourse} setGender={setGender} setNat={setNat} setLoc={setLoc} setAlumni={setAlumni}/>
        
        {data?
        <>
        <span>Search result: <span className='student-number-count'> {size} </span>user(s) found.</span>
        <List data={data} count={page_count} setPage={setPage}/>
        </>
        :''

        }
    </div>
  )
}

export default MainStat