import { BrowserRouter as Router, Route, Link, Routes, useLocation } from "react-router-dom";
import Login from './components/admin/Login'
import Dashboard from "./components/admin/Dashboard";
import { useNavigate } from "react-router";
import AppWraper from "./components/admin/AppWraper";
import { RequireToken, CheckIfToken, checkTokenTime, removeToken, ViaVali } from "./components/tools/Auth";
import UserDash from "./components/admin/UserDash";
import AllApps from "./components/admin/AllApps";
import { useState, useEffect } from "react";
import BriefingPage from "./components/apps/ai-writer/pages/BriefingPage";
import Summarizer from "./components/apps/ai-writer/components/Summarizer";
import SummaryPage from "./components/apps/ai-writer/pages/SummaryPage";
import AppDetails from "./components/admin/AppDetails"
import NewsPage from "./components/apps/ai-writer/pages/NewsPage";
import ImageSearcherPage from "./components/apps/image-searcher/pages/ImageSearcherPage";
import HemingwayPage from "./components/apps/hemingway/pages/HemingwayPage";
import Settings from "./components/admin/Settings";
import Inbox from "./components/admin/Inbox";
import SocialMediaWriterPage from "./components/apps/ai-writer/pages/SocialMediaWriterPage";
import AIWriterHomePage from "./components/apps/ai-writer/pages/AIWriterHomePage";
import MainStat from "./components/apps/student-stat/pages/MainStat";
import IndexMap from "./components/apps/student-stat/pages/Index";
import Maps from "./components/apps/student-stat/pages/Maps";

import { BLANK_WORKSPACE_PAGE_ROUTE, BRIEFING_PAGE_ROUTE, HOME_PAGE_ROUTE, STAT } from "./components/apps/utils/routes";

function App() {
  const [error, setError] = useState()
  const [auth, setAuth] = useState()

  return (
    <Router>
      <Routes>

        {/* <Route path="testiranje" element={<Dashboard layout={<IndexMap />} page={'/test'} />} /> */}

        <Route path="/login/:id?" element={<CheckIfToken getError={error} error={setError} setAuth={setAuth} session_auth={auth}><Login error={error} setEr={setError} /></CheckIfToken>} />
        <Route
          path="/"
          element={
            <RequireToken>
              <Dashboard layout={<UserDash />} page={'/'} />
            </RequireToken>
          }
        />
        <Route
          path="/apps"
          element={
            <RequireToken>
              <Dashboard layout={<AllApps />} page={'/apps'} />
            </RequireToken>
          }
        />
        {/* INBOX */}

        <Route
          path="/inbox"
          element={
            <RequireToken>
              <Dashboard layout={<Inbox />} page={'/inbox'} />
            </RequireToken>
          }
        />
        {/* SETTINGS */}
        <Route
          path="/settings"
          element={
            <RequireToken>
              <AppWraper layout={<Settings />} page={'/image-searcher'} />
            </RequireToken>
          }
        />
        {/* AI WRITER */}
        <Route
          path={HOME_PAGE_ROUTE}
          element={
            <RequireToken>
              <AppWraper layout={<AIWriterHomePage />} page={'ai-writer-home'} />
            </RequireToken>
          }
        />

        <Route
          path={BRIEFING_PAGE_ROUTE}
          element={
            <RequireToken>
              <AppWraper layout={<BriefingPage />} page={'ai-writer'} />
            </RequireToken>
          }
        />

        <Route
          path={BLANK_WORKSPACE_PAGE_ROUTE}
          element={
            <RequireToken>
              <AppWraper layout={<BriefingPage />} page={'ai-writer'} />
            </RequireToken>
          }
        />

        <Route
          path="/ai-writer/summarizer"
          element={
            <RequireToken>
              <AppWraper layout={<SummaryPage />} page={'summarizer'} />
            </RequireToken>
          }
        />

        <Route
          path="/ai-writer/news"
          element={
            <RequireToken>
              <AppWraper layout={<NewsPage />} page={'/news'} />
            </RequireToken>

          }
        />

        <Route
          path="/ai-writer/social-media-writer"
          element={

            <AppWraper layout={<SocialMediaWriterPage />} page={'/social-media-writer'} />

          }
        />

        {/* IMAGE SEARCHER */}
        <Route
          path="/image-searcher"
          element={
            <RequireToken>
              <AppWraper layout={<ImageSearcherPage />} page={'/image-searcher'} />
            </RequireToken>
          }
        />

        {/* HEMINGWAY */}
        <Route
          path="/hemingway"
          element={
            // <RequireToken>
            <AppWraper layout={<HemingwayPage />} page={'/hemingway'} />
            // </RequireToken>
          }
        />
        {/* STATISTICS */}

        <Route
          path={STAT}
          element={

            <AppWraper layout={<IndexMap />} customCss={'no_height'} page={'/stat'} />

          }
        />
        <Route
          path={'/table-statistics'}
          element={

            <AppWraper layout={<MainStat />} customCss={'no_height'} page={'/stat'} />

          }
        />
        <Route
          path={'/map-statistics'}
          element={

            <AppWraper layout={<Maps />} customCss={'no_height'} page={'/stat'} />

          }
        />



        <Route
          path="/info/:id?"
          element={

            <Dashboard layout={<AppDetails />} page={'/info'} />

          }
        />
      </Routes>
    </Router>
  );
}

export default App;
