import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import { margin, padding } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button, CardActionArea, CardActions, Checkbox, Link, Tooltip } from '@mui/material';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LaunchIcon from '@mui/icons-material/Launch';
import NewsService from '../services/NewsService';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { fetchDecodedData, fetchToken } from '../../../tools/Auth';
import AssignToModal from './AssignToModal';
import DiploWebsitesService from '../services/DiploWebsitesService';
import axios from 'axios';
import NewsActionModal from './NewsPreviewModal';

import iconSet from "../assets/icon-set/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";


class News extends Component {

    constructor(props) {
        super(props);

        let liked = false;
        let disliked = false;

        if (props.news.user_feedback != undefined) {
            liked = props.news.user_feedback == 'up';
            disliked = props.news.user_feedback == 'down';
        }

        this.state = {
            news: props.news,
            addNewsToSelected: props.addNewsToSelected,
            checked: false,
            diploUser: null,
            feedbacks: { liked: liked, disliked: disliked },
            assignToModalOpen: false,
            diploPeople: props.diploPeople
        };
    }

    componentDidMount() {
        //get USERS from sql for the assign_to list
        if (this.props.news?.assigned_to && this.props.news.assigned_to.length > 0) {
            axios.get(process.env.REACT_APP_BACKEND_URL + '/users/' + this.props.news.assigned_to).then(data => {

                this.setState({
                    diploUser: data.data
                })

            })
        }

    }

    handleSelect = () => {
        if (this.state.checked) {
            this.setState({
                checked: false
            });
            this.state.addNewsToSelected(this.state.news);
        }
        else {
            this.setState({
                checked: true
            });
            this.state.addNewsToSelected(this.state.news);
        }
    }

    openModal = () => {
        this.setState({
            assignToModalOpen: !this.state.assignToModalOpen
        });
    }

    closeModal = () => {
        this.setState({
            assignToModalOpen: !this.state.assignToModalOpen,
        });
    }

    changeAssignedUser = (user) => {
        this.setState({
            diploUser: user
        });
        console.log("Assigned user changed:");
        console.log(user);
    }

    render() {
        return (
            <div className='news-element' key={this.state.news.id}>

                {
                    this.state.news.assigned_to != undefined &&
                    <div className='assigned-people'>
                        <AvatarGroup max={6}>
                            {
                                this.state.diploUser != undefined &&
                                <Tooltip title={this.state.diploUser.name + " " + this.state.diploUser.lastName}>
                                    <Avatar key={this.state.diploUser.email} alt={this.state.diploUser.name + this.state.diploUser.lastName} src={this.state.diploUser.image} />
                                </Tooltip>
                            }
                        </AvatarGroup>
                    </div>
                }

                <div className="news-card">

                    <div className="news-card-img"
                        onClick={
                            () => this.props.openNewsPreviewModal(this.props.news.link)
                        }>
                        {
                            this.state.news.thumbnail == ""
                                ? <img src={require('../assets/img/update-image-100x80.webp')} />
                                : <img
                                    src={this.state.news.thumbnail}
                                    alt={this.state.news.title}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = require('../assets/img/update-image-100x80.webp');
                                    }}
                                />
                        }
                    </div>

                    <div className="news-card-body" onClick={
                        () => this.props.openNewsPreviewModal(this.props.news.link)
                    }>
                        <div className="news-card-content">
                            <p className='publisher'>
                                {this.state.news.source}, {new Date(this.state.news.date).toLocaleDateString("uk-Uk")}
                            </p>
                            <h3 className='title'>
                                {this.state.news.title}
                            </h3>
                            <p className='news-excerpt'>
                                {this.state.news.snippet}
                            </p>
                        </div>
                    </div>

                    <div className='news-card-actions'>
                        <Checkbox
                            className='news-select'
                            label="news-select"
                            checked={this.state.checked}
                            onChange={this.handleSelect}
                            icon={<CheckBoxOutlineBlankIcon sx={{ height: 30, width: 30 }} />}
                            checkedIcon={<CheckBoxIcon sx={{ height: 30, width: 30 }} />}
                        />
                    </div>

                </div>

                <hr className='news-devider' />

                <AssignToModal
                    changeAssignedUser={this.changeAssignedUser}
                    open={this.state.assignToModalOpen}
                    onClose={this.closeModal}
                    newsLink={this.state.news.link}
                    diploPeople={this.state.diploPeople}
                    news={this.state.news}
                />

            </div>
        );
    }
}

export default News;