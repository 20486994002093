import axios from 'axios';
import { fetchDecodedData } from '../../../tools/Auth';

class SummarizerService {

    getSummary(text) {
        
        return axios.post(process.env.REACT_APP_NIMANI_V3_GET_SUMMARY,
            {
                "user_id": fetchDecodedData().email,
                "text": text
            },
            {
                timeout: 300000,
                maxContentLength: Infinity,
                maxBodyLength: Infinity
            }
        );
        // return axios.post(process.env.REACT_APP_NIMANI_GET_SUMMARY_NEW,
        //     {
        //         "user_id": fetchDecodedData().email,
        //         "text": text
        //     },
        //     {
        //         timeout: 300000,
        //         maxContentLength: Infinity,
        //         maxBodyLength: Infinity
        //     }
        // );
    }
    getTextAnalysis(text){
        return axios.post(process.env.REACT_APP_NIMANI_GET_RECOMMEND,
            {
                "user_id": fetchDecodedData().email,
                "text": text
            },
            {
                timeout: 300000,
                maxContentLength: Infinity,
                maxBodyLength: Infinity
            }
        );
    }
    sendToWebsite(data) {
        return axios.post(process.env.REACT_APP_NIMANI_FETCH_SINGLE,
            {
                "author": fetchDecodedData().email,
                "title": data.title,
                "content": data.generatedSummary,
                "excerpt": data.generatedExcerpt,
                "source-url": data.source,
            },
            {
                headers: {
                    "Diplo-api-key": "73273BD816106AA8063FBD8588745B2A31757BADE9C3B46D11E0B66D59A37C64"
                }
            }
        );
    }

    formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
      
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      
        return `${formattedMinutes}:${formattedSeconds}`;
      }

}

export default new SummarizerService;