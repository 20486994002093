import { fetchDecodedData } from "../../../tools/Auth";
import newsImage from "../assets/img/update-image.webp"
import axios from 'axios';

class NewsService {

    getUpdateFromDigWatch(updateURL) {
        return axios.post("https://dig.watch/wp-json/diplo/v1/ai-apps/get-update-by-url",
            {
                "url": updateURL
            }
        );
    }

    createSocialMediaPost(text) {
        return axios.post(process.env.REACT_APP_NIMANI_SOCIAL_MEDIA,
        {
            "user_id": "",
            "text": text
          }
        );
    }

    getNews(paginationPage, search, daysFilter) {

        let data = {
            "user_id": fetchDecodedData().email
        };

        if (daysFilter != '') {
            data["n_days"] = daysFilter;
        }
        else{
            data["n_days"] = null;
        }
        if (search != '') {
            data["search"] = search;
        }
        else{
            data["search"] = null;
        }
        return axios.post(process.env.REACT_APP_NEWS_API + "?page=" + paginationPage + "&" + "size=15", data);
    }

    getNewsSingleNoEmail(link) {
        return axios.get(process.env.REACT_APP_NIMANI_FETCH_SINGLE,
            {
                params: {
                    "link": link
                }
            }
        );
    }

    getNewsSingle(link) {
        return axios.post(process.env.REACT_APP_NIMANI_FETCH_SINGLE,
            {
                "user_id": fetchDecodedData().email,
                "link": link
            }
        );
    }

    getRelatedNews(text) {
        return axios.post(process.env.REACT_APP_NIMANI_GET_RECOMMEND,
            {
                "user_id": fetchDecodedData().email,
                "text": text
            }
        );
    }

    saveNewsContent(link, text) {
        return axios.post(process.env.REACT_APP_NIMANI_SAVE_SINGLE,
            {
                "user_id": fetchDecodedData().email,
                "link": link,
                "text": text
            }
        );
    }

    sendFeedback(link, feedback) {
        return axios.post(process.env.REACT_APP_NIMANI_SEND_FEEDBACK,
            {
                "user_id": fetchDecodedData().email,
                "link": link,
                "user_feedback": feedback
            }
        );
    }

    assignTo(link, email) {
        return axios.post(process.env.REACT_APP_NIMANI_ASSIGN_TO,
            {
                "user_id": fetchDecodedData().email,
                "link": link,
                "assigned_to": [email]
            }
        );
    }

    removeViaFromLink(link) {
        if (link.includes("via.diplomacy.edu")) {
            let params = new URL(link).searchParams;
            return params.get("original");
        }
        return link;
    }

    getNewsContent(link) {
        return axios.post(process.env.REACT_APP_NIMANI_FETCH_SINGLE,
            {
                "user_id": fetchDecodedData().email,
                "link": link
            }
        );
    }
}

export default new NewsService;