import React, { Component } from 'react';
import HemingwayService from '../services/HemingwayService';

class HemingwayPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div
                style={{
                    top: this.props.data.positionY,
                    left: this.props.data.positionX,
                    display: this.props.data.display
                }}
                className="hemingway-editor-popup"
            >
                {
                    HemingwayService.createPopupText(this.props.data)
                }
            </div>
        );
    }
}

export default HemingwayPopup;
