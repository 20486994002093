import axios from 'axios';
import { fetchDecodedData } from '../../../tools/Auth';

// !!!!!
// OVO  IZBACI U NEKOM TRENUTKU TREBA DA SVE PREDJE U IMAGE SEARCHER APP
// !!!!!

class ImagesService {

    searchExternalImages(query) {
        return axios.post(process.env.REACT_APP_NIMANI_SEARCH_EXTERNAL_IMAGES,
            {
                "user_id": fetchDecodedData().email,
                "text": query
            }
        );
    }

    searchDiploImages(query) {
        return axios.post(process.env.REACT_APP_NIMANI_SEARCH_DIPLO_IMAGES,
            {
                "user_id": fetchDecodedData().email,
                "text": query
            }
        );
    }

    generateImages(data) {
        return axios.post(process.env.REACT_APP_NIMANI_GENERATE_IMAGES,
            {
                "user_id": fetchDecodedData().email,
                "text": data.subject,
                "style": data.style,
                "vibe": data.vibe,
                "perspective": data.perspective,
            });
    }

    // Zapier opcije

    getVibesFromZapier() {
        return [
            "none",
            "Whimsical",
            "Vibrant",
            "Pastel",
            "Magical",
            "Neon",
            "Retro",
            "Storybook",
            "Festive",
            "Expressive",
            "Cyberpunk",
        ]
    }

    getStylesFromZapier() {
        return [
            "none",
            "photo",
            "digital painting",
            "illustration",
            "screenshot",
            "3d render",
            "houdini 3d",
            "octane 3d",
            "acrylic on canva",
            "oil painting",
            "watercolour",
            "collage",
            "vector art",
            "low poly",
            "claymation",
            "layered paper",
            "felt pieces",
            "fabric pattern",
            "studio ghibli"
        ]
    }

    getPerspectivesFromZapier() {
        return [
            "none",
            "wideshot",
            "titled frame",
            "overhead view",
            "aerial view",
            "soft focus",
            "close up",
            "medium shot",
            "long shot",
            "extreme long shot",
            "bokeh",
            "tilt shift",
            "motion blur",
            "telephoto lens",
            "macro lens",
            "wide angle lens",
            "fish eye lens",
            "depth of field",
            "fast shutter speed",
            "slow shutter speed",
            "landscape"
        ]
    }
}

export default new ImagesService;