import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import { margin, padding } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button, CardActionArea, CardActions, Checkbox, Link, Tooltip } from '@mui/material';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LaunchIcon from '@mui/icons-material/Launch';
import NewsService from '../services/NewsService';

class SideNews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            news: props.news,
            addNewsToSelected: props.addNewsToSelected,
            checked: false
        };
    }

    openUsingVia = (link) => {
        NewsService.getNewsSingle(link);
    }

    handleLike = () => {
        let el = this.state.news;

        if (el.liked == false && el.disliked == true) {
            el.disliked = false;
        }
        el.liked = !el.liked;


        this.setState({
            news: el
        })
    }

    handleDislike = () => {
        let el = this.state.news;

        if (el.disliked == false && el.liked == true) {
            el.liked = false;
        }
        el.disliked = !el.disliked;

        this.setState({
            news: el
        })
    }

    handleSelect = () => {
        if (this.state.checked) {
            this.setState({
                checked: false
            });
            this.state.addNewsToSelected(this.state.news);
        }
        else {
            this.setState({
                checked: true
            });
            this.state.addNewsToSelected(this.state.news);
        }
    }

    render() {
        return (
            <div className='news-element' key={this.state.news.id}>

                <div className="news-card">
                    
                    <div className="news-card-body">
                        <div className="news-card-content">
                            <p className='publisher'>
                                {this.state.news.source}
                            </p>
                            <a href={this.state.news.link} target="_blank">
                                <h3 className='title'>
                                    {this.state.news.title}
                                </h3>
                            </a>
                            <p className='news-excerpt'>
                                {this.state.news.snippet}
                            </p>
                        </div>
                        {/* <div className='news-card-actions'>
                            <Tooltip title="Like recommendation">
                                <IconButton aria-label="select" onClick={this.handleLike}>
                                    <ThumbUpRoundedIcon sx={{ height: 20, width: 20 }} style={this.state.news.liked ? { color: "green" } : {}} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Dislike recommendation">
                                <IconButton aria-label="select" onClick={this.handleDislike}>
                                    <ThumbDownAltRoundedIcon sx={{ height: 20, width: 20 }} style={this.state.news.disliked ? { color: "red" } : {}} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Open using via">
                                <a href={process.env.REACT_APP_URL + "/news?original=" + this.state.news.link} target="_blank">
                                    <IconButton aria-label="select" onClick={() => this.openUsingVia(this.state.news.link)}>
                                        <LaunchIcon sx={{ height: 30, width: 30 }} />
                                    </IconButton>
                                </a>
                            </Tooltip>
                            <Checkbox
                                className='news-select'
                                label="news-select"
                                checked={this.state.checked}
                                onChange={this.handleSelect}
                                icon={<CheckBoxOutlineBlankIcon sx={{ height: 30, width: 30 }} />}
                                checkedIcon={<CheckBoxIcon sx={{ height: 30, width: 30 }} />}
                            />
                        </div> */}
                    </div>


                </div>
                <hr className='news-devider' />
            </div>
        );
    }
}

export default SideNews;